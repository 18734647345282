import React from "react";
import { useNavigate } from "react-router-dom";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import { Heading } from "../../../atoms/Heading";
import Search from "../../../atoms/Search";
import style from "./index.module.scss";
import { memo, useCallback, useEffect, useState } from "react";
import NoContentCard from "../../../atoms/NoContentCard";
import { useCookies } from "react-cookie";
import Pagination from "../../../atoms/Pagination";
import CustomButton from "../../../atoms/CustomButton";
import moment from "moment/moment";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/components/toast";
import FilterComponent from "../../../molecules/filters/FilterComponent";
import MyDropdown from "../../../atoms/MyDropdown";
import { DateSelector } from "../../../atoms/MonthYearSelector";
import {
  counterPartyType,
  GICSectorData,
  statusData,
  transactionType,
} from "../../../../utils/staticData";
import Upload from "../../../icons/Upload";
import { downloadReport } from "../../../../services/downloadService";
import { useSelector } from "react-redux";
import { getData } from "../../../../services";
import { formatNumberInternational } from "../../../../helpers/capatalizeLetters";

const ReportsBroker = () => {
  const [cookies] = useCookies(["t", "roleGroup"]);
  const navigate = useNavigate();
  const accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.Counterparties;
  const [searchData, setSearchData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [counterpartyData, setCounterPartData] = useState([]);
  const [investorData, setInvestorData] = useState([]);
  const [formData, setFormData] = useState({
    fund: "",
    date: "",
  });
  const [filters, setFilters] = useState({});
  const [loader, setLoader] = useState(true);
  const handleConfirmSelection = async () => {
    setLoader(true);
    try {
      const res = await getData({
        endpoint: "funding/getAllFundingsByBrokers",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          status: ["Funded"],
          transactionType: filters?.["Transaction Type"],
          dueStartDate: filters?.startDate0,
          dueEndDate: filters?.endDate0,
          fundingStartDate: filters?.startDate1,
          fundingEndDate: filters?.endDate1,
          investorID: formData.fund,
          as_of_date: formData.date ? formData.date : new Date(),
          group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
        },
      });
      setCounterPartData(res?.data);
    } catch (err) {
      console.log("errr", err);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (formData.fund || formData.date) {
      handleConfirmSelection();
    }
  }, [itemsPerPage, currentPage, searchData, filters]);

  const getInvestorData = async () => {
    try {
      const res = await getData({
        endpoint: "investor/getInvestorList",
        token: cookies.t,
      });
      setInvestorData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    setLoader(false);
    getInvestorData();
  }, []);

  const columns = [
    {
      field: "client_name",
      headerName: "Client Name",
      flex: 1,
      sortable: true,
      cellRenderer: (params) => {
        return <div className="line-truncate">{params?.data?.client_name}</div>;
      },
    },
    {
      field: "obligor_name",
      headerName: "Obligor Name",
      flex: 1,
      sortable: true,
      cellRenderer: (params) => {
        return (
          <div className="line-truncate">
            {params?.data?.client_id === params?.data?.obligor_id
              ? params?.data?.client_name
              : params?.data?.obligor_name
              ? params?.data?.obligor_name
              : params?.data?.client_name
              ? params?.data?.client_name
              : ""}
          </div>
        );
      },
    },
    {
      field: "supplier_name",
      headerName: "Supplier Name",
      flex: 1,
      sortable: true,
      cellRenderer: (params) => {
        return (
          <div className="line-truncate">
            {params?.data?.client_id === params?.data?.supplier_id
              ? params?.data?.client_name
              : params?.data?.supplier_name
              ? params?.data?.supplier_name
              : params?.data?.client_name
              ? params?.data?.client_name
              : ""}
          </div>
        );
      },
    },
    {
      field: "funded_date",
      headerName: "Fund Date",
      flex: 1,
      sortable: true,
      valueGetter: (params) => params?.data?.funded_date || null,
      valueFormatter: (params) =>
        params.value ? moment.utc(params.value).format("YYYY-MM-DD") : "",
      cellRenderer: (params) => (
        <div className={style.valueStyle}>
          {params.value ? moment.utc(params.value).format("YYYY-MM-DD") : ""}
        </div>
      ),
    },
    {
      field: "Due Date",
      headerName: "Due Date",
      flex: 1,
      sortable: true,
      valueGetter: (params) => params?.data?.due_date || null,
      valueFormatter: (params) =>
        params.value ? moment.utc(params.value).format("YYYY-MM-DD") : "",
      cellRenderer: (params) => (
        <div className={style.valueStyle}>
          {params.value ? moment.utc(params.value).format("YYYY-MM-DD") : ""}
        </div>
      ),
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 1,
      sortable: true,
    },
    {
      field: "cost_value",
      headerName: "Cost Value",
      type: "number",
      sortable: true,
      flex: 1,
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => Number(params?.data?.cost_value) || 0,
      valueFormatter: (params) =>
        params.value ? formatNumberInternational(params.value) : "0",
      cellRenderer: (params) => (
        <div className={style.valueStyle}>{params.value}</div>
      ),
    },
    {
      field: "Fund",
      headerName: "Fund",
      flex: 1,
      sortable: true,
      valueGetter: (param, row) => {
        return row?.funder;
      },
      cellRenderer: (params) => {
        return <div className={style.valueStyle}>{params?.data?.funder}</div>;
      },
    },
    {
      field: "Date",
      headerName: "Date",
      flex: 1,
      sortable: true,
      valueGetter: (params) => params?.data?.date || new Date(),
      cellRenderer: (params) => {
        const date = moment.utc(params.value).format("YYYY-MM-DD");
        return <span>{date}</span>;
      },
    },
    {
      field: "Principal Remaining",
      headerName: "Principal Remaining",
      flex: 1,
      sortable: true,
      type: "number",
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => {
        if (
          params?.data?.cost_value &&
          params?.data?.paymentHistory &&
          params?.data?.paymentHistory?.length > 0
        ) {
          const principlePayments = params?.data?.paymentHistory.reduce(
            (total, item) => {
              if (item.paymentType === "principle") {
                return total + parseFloat(item.paymentAmount);
              }
              return total;
            },
            0,
          );

          const interestPayments = params?.data?.paymentHistory.reduce(
            (total, item) => {
              if (item.paymentType === "Interest") {
                return total + parseFloat(item.paymentAmount);
              }
              return total;
            },
            0,
          );

          return (
            params?.data?.cost_value - principlePayments - interestPayments
          );
        } else if (
          params?.data?.cost_value &&
          (!params?.data?.paymentHistory ||
            params?.data?.paymentHistory?.length === 0)
        ) {
          return params?.data?.cost_value;
        }
        return 0;
      },
      cellRenderer: (params) => {
        return <span>{params.value || 0}</span>;
      },
    },
    {
      field: "Principal Paid",
      headerName: "Principal Paid",
      sortable: true,
      type: "number",
      flex: 1,
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => {
        if (params?.data?.paymentHistory) {
          return params?.data?.paymentHistory.reduce((total, item) => {
            if (item.paymentType === "principle") {
              return total + parseFloat(item.paymentAmount);
            }
            return total;
          }, 0);
        }
        return 0;
      },
      cellRenderer: (params) => {
        return <span>{params.value || 0}</span>;
      },
    },
    {
      field: "Interest Paid",
      headerName: "Interest Paid",
      sortable: true,
      type: "number",
      flex: 1,
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => {
        if (params?.data?.paymentHistory) {
          return params?.data?.paymentHistory.reduce((total, item) => {
            if (item.paymentType === "Interest") {
              return total + parseFloat(item.paymentAmount);
            }
            return total;
          }, 0);
        }
        return 0;
      },
      cellRenderer: (params) => {
        return <span>{params.value || 0}</span>;
      },
    },
    {
      field: "Interest Accrued",
      headerName: "Interest Accrued",
      sortable: true,
      type: "number",
      flex: 1,
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => {
        return Number(params?.data?.interest) || 0;
      },
      cellRenderer: (params) => {
        return <span>{params.value || "0"}</span>;
      },
    },
    {
      field: "Broker Name",
      headerName: "Broker Name",
      flex: 1,
      sortable: true,
      valueGetter: (params) => {
        return params?.data?.brokerInfo?.[0]?.broker_name || ""; // Return an empty string if broker_name is not available
      },
      cellRenderer: (params) => {
        return (
          <div>
            {params?.data?.brokerInfo?.[0]?.broker_name || ""}{" "}
            {/* Display the broker name or an empty string */}
          </div>
        );
      },
    },
    {
      field: "Broker Rate",
      headerName: "Broker Rate",
      sortable: true,
      type: "number",
      flex: 1,
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => {
        return params?.data?.brokerInfo?.[0]?.fee_rate || 0; // Default to 0 if fee_rate is not available
      },
      cellRenderer: (params) => {
        return (
          <div>
            {params?.data?.brokerInfo?.[0]?.fee_rate || 0}{" "}
            {/* Display the fee_rate or 0 if not available */}
          </div>
        );
      },
    },

    {
      field: "Fee Earnt",
      headerName: "Fee Earnt",
      flex: 1,
      sortable: true,
      cellRenderer: (params) => {
        return (
          <>
            <span>{0}</span>
          </>
        );
      },
    },
    {
      field: "Fee Accrued",
      headerName: "Fee Accrued",
      flex: 1,
      sortable: true,
      type: "number",
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => {
        const value = params?.data?.interest
          ? Number(params?.data?.interest)
          : 0;
        const finalAmount =
          value > 0
            ? value *
              (params?.data?.brokerInfo?.[0]?.fee_rate
                ? params?.data?.brokerInfo?.[0]?.fee_rate
                : 0)
            : 0;

        // Return the numeric value for sorting
        return finalAmount;
      },
      cellRenderer: (params) => {
        const amount = params?.data?.interest
          ? Number(params?.data?.interest)
          : 0;
        return (
          <>
            {params?.data?.interest ? (
              <span>
                {amount > 0
                  ? amount *
                    (params?.data?.brokerInfo?.[0]?.fee_rate
                      ? params?.data?.brokerInfo?.[0]?.fee_rate
                      : 0)
                  : 0}
              </span>
            ) : (
              0
            )}
          </>
        );
      },
    },
  ];

  const CustomNoRowsOverlay = memo(() => {
    return (
      !loader && (
        <>
          <NoContentCard
            title="No Brokers created yet!"
            tag="Add Counterparties"
            type="Counterparty"
            classes="d-flex align-items-center"
          />
        </>
      )
    );
  });

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const handleDownload = async () => {
    const res = await downloadReport({
      endpoint: "funding/exportBrokers",
      token: cookies.t,
      fileName: "Brokers",
      params: {
        search: searchData,
        status: ["Funded"],
        transactionType: filters?.["Transaction Type"],
        dueStartDate: filters?.startDate0,
        dueEndDate: filters?.endDate0,
        fundingStartDate: filters?.startDate1,
        fundingEndDate: filters?.endDate1,
        investorID: formData.fund,
        as_of_date: formData.date ? formData.date : new Date(),
        group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
      },
    });

    if (res) {
      toast.success("Brokers Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  return (
    <>
      <div className="mb-3">
        <Heading className="HeadingStyle">Brokers</Heading>
      </div>
      <div className="mb-4 d-flex gap-3">
        <div className="d-flex gap-3 w-100">
          <div
            style={{
              width: "30%",
              position: "relative",
            }}
          >
            <MyDropdown
              data={investorData}
              placeholder="Select Fund"
              onChange={(option) => {
                setFormData({
                  ...formData,
                  fund: option,
                });
              }}
              selectedValue={formData.fund}
            />
          </div>
          <div
            style={{
              width: "30%",
              position: "relative",
            }}
          >
            <DateSelector
              placeholder="As of Date"
              onChange={(option) => {
                setFormData({
                  ...formData,
                  date: option,
                });
              }}
              selectedDate={formData.date}
            />
          </div>
        </div>
        <div>
          <CustomButton
            text="Confirm Selection"
            type="btn-outline-primary"
            size="btn-lg"
            style={{ minWidth: "max-content" }}
            handleClick={() => handleConfirmSelection()}
          />
        </div>
      </div>
      <div className={style.headingSearchWrap}>
        <div>
          <Heading className="HeadingStyle">Brokers List</Heading>
        </div>
        <div className={style.searchButton}>
          <Search
            placeholder="Search by name"
            onSearchEmit={(data) => {
              setSearchData(data);
            }}
          />

          {roleData && roleData?.export && (
            <CustomButton
              text="Export"
              handleClick={() => handleDownload()}
              classes={"filter-contain-btn"}
              iconRequired
              icon={<Upload />}
            />
          )}
          <FilterComponent
            // filterData={filtersData}
            isDateRange
            dateRangeNames={["Due Date", "Funding Date"]}
            onFilterChange={(filters) => {
              setFilters(filters);
            }}
          />
        </div>
      </div>

      <CustomDatagrid
        rows={counterpartyData?.[0]?.fundingData || []}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        loader={loader}
        isRowsVisible
        isValueVisible
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={counterpartyData?.[0]?.pageDetails?.count}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
    </>
  );
};

export default ReportsBroker;
