import { Box } from "@mui/system";
import ReactEcharts from "echarts-for-react";
import moment from "moment";
import { memo, useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { confimationStyles } from "../../../../../../assets/styles/components/toast";
import { formatNumberInternational } from "../../../../../../helpers/capatalizeLetters";
import { getData, patchData } from "../../../../../../services";
import { getPandaApi } from "../../../../../../services/pandaDocs";
import CustomButton from "../../../../../atoms/CustomButton";
import MyDropdown from "../../../../../atoms/MyDropdown";
import NoContentCard from "../../../../../atoms/NoContentCard";
import Pagination from "../../../../../atoms/Pagination";
import TabStrip from "../../../../../atoms/tabstrip";
import CustomDatagrid from "../../../../../molecules/CustomDatagrid";
import style from "./index.module.scss";

const ApprovedModal = ({
  modalStateApproved,
  setModalStateApproved,
  getAllFundingStatus,
}) => {
  const [cookies] = useCookies(["t", "roleGroup"]);
  const [fundingData, setFundingData] = useState({});
  const [clientData, setClientData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [fundingDueData, setFundingDueData] = useState([]);
  const [investorData, setInvestorData] = useState([]);
  const [selectedInvestor, setSelectedInvestor] = useState();
  const [totalCredit, setTotalCredit] = useState();
  const [pandaDocsStatus, setPandaDocStatus] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [activeTab, setActiveTab] = useState("Credit info");

  const onTabChange = (event, value) => {
    setActiveTab(value);
  };

  const _getComponent = (tabs) => {
    const item = data.find((item) => item.label === tabs);
    return item ? item.view : null;
  };

  const getInvestorData = async () => {
    try {
      const res = await getData({
        endpoint: "investor/getInvestorList",
        token: cookies.t,
      });
      setInvestorData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    getInvestorData();
  }, []);

  const data = [
    {
      id: 1,
      label: "Credit info",
      view: (
        <CreditInfo
          fundingData={fundingData}
          clientData={clientData}
          totalCredit={totalCredit}
          pandaDocsStatus={pandaDocsStatus}
        />
      ),
    },
    {
      id: 2,
      label: "Existing Allocation ",
      view: (
        <ExitingAllocation
          fundingDueData={fundingDueData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
        />
      ),
    },
    {
      id: 3,
      label: "Allocation History",
      view: (
        <AllocationHistory
          fundingDueData={fundingDueData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
        />
      ),
    },
  ];

  const getFundingById = async () => {
    try {
      const res = await getData({
        endpoint: "funding/getFundingById",
        token: cookies.t,
        params: {
          id: modalStateApproved.modalId,
        },
      });
      setFundingData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  console.log("funding-data", fundingData);

  const getClientById = async (id) => {
    try {
      const res = await getData({
        endpoint: "couterParty/getCounterPartyById",
        token: cookies.t,
        params: {
          id: id,
        },
      });
      setClientData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (
      fundingData?.transaction_type === "Supplier Credit" ||
      fundingData?.transaction_type === "Supplier Credit-1"
    ) {
      getClientById(fundingData?.supplier_id);
    } else if (
      fundingData?.transaction_type === "Note" ||
      fundingData?.transaction_type === "Revolver"
    ) {
      getClientById(fundingData?.client_id);
    } else {
      getClientById(fundingData?.obligor_id);
    }
  }, [fundingData?.client_id]);

  useEffect(() => {
    if (modalStateApproved?.modalId) {
      getFundingById();
    }
  }, [modalStateApproved?.modalId]);

  const getFundingDues = async (fundingData) => {
    try {
      const res = await getData({
        endpoint: "funding/getFundingClientIdWithPagination",
        params: {
          id: fundingData.client_id,
          status: "Funded",
          trans_type: "Client",
          page: currentPage,
          limit: itemsPerPage,
        },
        token: cookies.t,
      });
      setFundingDueData(res?.data);
      console.log("res?.data", res?.data);
      const totalCostValue = res?.data?.fundedData?.reduce((sum, item) => {
        const faceValue = parseFloat(item?.face_value) || 0;
        return sum + faceValue; // Add parsed value to the sum
      }, 0);
      setTotalCredit(totalCostValue);
    } catch (err) {
      console.log("err", err);
    }
  };

  const handlePandaDocsStatus = async () => {
    const response = await getPandaApi({
      endpoint: `https://api.pandadoc.com/public/v1/documents/${fundingData?.documentsInvoice?.docInvoiceId}`,
      params: {},
      token: process.env.REACT_APP_PANDADOC_ID,
    });
    setPandaDocStatus(response);
  };

  useEffect(() => {
    if (fundingData?.documentsInvoice?.docInvoiceId) {
      handlePandaDocsStatus();
    }
  }, [fundingData?.documentsInvoice?.docInvoiceId]);

  useEffect(() => {
    if (fundingData?.client_id) {
      getFundingDues(fundingData);
    }
  }, [fundingData]);

  const handleApproveFunding = async () => {
    try {
      setIsLoading(true);
      const res = await patchData({
        endpoint: "funding/UpdateFundingStatus",
        token: cookies.t,
        params: {
          id: modalStateApproved.modalId,
          group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
        },
        data: {
          status: "Allocated",
          investor_id: selectedInvestor?.value,
          funder: selectedInvestor.investorTag,
        },
      });
      if (res) {
        toast.success("Funding Allocated Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
      }
      setIsLoading(false);
      setModalStateApproved(() => ({
        isModalOpen: false,
      }));
      getAllFundingStatus();
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <>
      <TabStrip activeTab={activeTab} onTabChange={onTabChange} data={data} />
      <Box sx={{ width: "100%" }}>
        <Box>{_getComponent(activeTab)}</Box>
      </Box>
      <div className="row">
        <label className={style.terms}>Select Investor</label>
        <div className="col-6">
          <div className="mt-1 mb-1">
            <MyDropdown
              data={investorData}
              placeholder="Please select Investors"
              onChange={(option) => {
                setSelectedInvestor(option);
              }}
              format="all"
              menuPlacement={"auto"}
            />
          </div>
        </div>
      </div>
      <div className={style.buttonStyle}>
        {/* <CustomButton
          text="Confirm Allocation"
          type="btn-primary"
          size="btn-lg"
          handleClick={() => {
            handleApproveFunding();
          }}
        /> */}
        <button
          className="btn-primary btn-lg"
          type="submit"
          style={{ width: "25%" }}
          onClick={() => {
            handleApproveFunding();
          }}
        >
          Confirm Allocation
          {isLoading && (
            <span
              className="spinner-border spinner-border-sm ms-2 text-light"
              role="status"
              aria-hidden="true"
            ></span>
          )}
        </button>
        <CustomButton
          text="Cancel"
          type="btn-outline-dark"
          size="btn-lg"
          handleClick={() => {
            setModalStateApproved(() => ({
              isModalOpen: false,
            }));
          }}
        />
      </div>
    </>
  );
};
export default ApprovedModal;

const CreditInfo = ({
  fundingData,
  clientData,
  totalCredit,
  pandaDocsStatus,
}) => {
  console.log("client-data", clientData);
  return (
    <>
      <div>
        <div className="row">
          <label className={style.terms}>Asset Info</label>
          <div className="col-6">
            <div className={style.label}>Cost</div>
          </div>
          <div className="col-6">
            <div className={style.value}>
              {formatNumberInternational(fundingData?.cost_value)}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className={style.label}>Face Value</div>
          </div>
          <div className="col-6">
            <div className={style.value}>
              {formatNumberInternational(parseFloat(fundingData.face_value))}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className={style.label}>Currency</div>
          </div>
          <div className="col-6">
            <div className={style.value}>{fundingData?.currency}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className={style.label}>Schedule status</div>
          </div>
          <div className="col-6">
            <div className={style.value}>{pandaDocsStatus?.status}</div>
          </div>
        </div>
        <div className="row">
          <label className={style.terms}>Obligor Information</label>
          <div className="col-6">
            <div className={style.label}>Name</div>
          </div>
          <div className="col-6">
            <div className={style.value}>{clientData?.counter_name}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className={style.label}>Internal Credit Limit</div>
          </div>
          <div className="col-6">
            <div className={style.value}>
              {formatNumberInternational(clientData?.credit_limit)}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className={style.label}>Credit Used</div>
          </div>
          <div className="col-6">
            <div className={style.value}>
              {formatNumberInternational(parseFloat(totalCredit))}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className={style.label}>Credit Remaining</div>
          </div>
          <div className="col-6">
            <div className={style.value}>
              {isNaN(
                parseFloat(clientData?.credit_limit) -
                  parseFloat(fundingData?.cost_value),
              )
                ? null
                : formatNumberInternational(
                    parseFloat(clientData?.credit_limit) -
                      parseFloat(totalCredit),
                  )}
            </div>
          </div>
        </div>

        <div className="row">
          <label className={style.terms}>Insurance Limit Information</label>
          <div className="col-6">
            <div className={style.label}>Limit</div>
          </div>
          <div className="col-6">
            <div className={style.value}>
              {formatNumberInternational(
                clientData?.insuranceDetails?.[0]?.limit,
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className={style.label}>End Date</div>
          </div>
          <div className="col-6">
            <div className={style.value}>
              {clientData?.insuranceDetails?.[0]?.end_date
                ? moment
                    .utc(clientData?.insuranceDetails?.[0]?.end_date)
                    .format("YYYY-MM-DD")
                : ""}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className={style.label}>Type</div>
          </div>
          <div className="col-6">
            <div className={style.value}>
              {clientData?.insuranceDetails?.[0]?.limit_type}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ExitingAllocation = ({ fundingDueData }) => {
  const groupAndSumCostValue = (data) => {
    const grouped = data?.reduce((acc, current) => {
      const key = `${current.funder}-${current.currency}`;
      if (!acc[key]) {
        acc[key] = {
          funder: current.funder,
          currency: current.currency,
          totalCostValue: 0,
        };
      }
      acc[key].totalCostValue += parseFloat(current.cost_value || 0); // Ensure valid value

      return acc;
    }, {});
    return Object.values(grouped);
  };

  const groupedData = groupAndSumCostValue(fundingDueData?.fundedData);

  const options = {
    tooltip: {
      trigger: "item", // Show tooltip when hovering over each pie slice
      formatter: "{b}: {c}", // Display 'name: value' in tooltip
    },
    legend: {
      orient: "vertical",
      left: "left",
      top: "center",
    },
    series: [
      {
        name: "Cost Value", // Tooltip title
        type: "pie",
        radius: ["40%", "70%"], // Donut chart
        center: ["60%", "45%"],
        data: groupedData?.map((item) => ({
          name: `${item.funder} - ${item.currency}`, // Name for the tooltip
          value: item.totalCostValue, // Value for the tooltip
        })),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  return (
    <>
      <ReactEcharts option={options} style={{ height: "330px" }} />
    </>
  );
};

const AllocationHistory = ({
  fundingDueData,
  currentPage,
  setCurrentPage,
  itemsPerPage,
  setItemsPerPage,
}) => {
  const navigate = useNavigate();
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);
  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        <NoContentCard
          title={"No Content"}
          subtitle={"No Content"}
          type="Allocation History"
          classes="d-flex align-items-center"
          height={"7rem"}
          fontSize={"18px"}
        />
      </>
    );
  });
  const columns = [
    {
      field: "client_name",
      headerName: "Client",
      flex: 0.8,
      cellRenderer: (params) => {
        return (
          <div title={params?.data?.client_name} className="line-truncate">
            {params?.data?.client_name}
          </div>
        );
      },
    },
    {
      field: "obligor",
      headerName: "Obligor",
      flex: 0.8,
      cellRenderer: (params) => {
        return (
          <div title={params?.data?.obligor_name} className="line-truncate">
            {params?.data?.obligor_name
              ? params?.data?.obligor_name
              : params?.data?.client_name}
          </div>
        );
      },
    },
    {
      field: "supplier",
      headerName: "Supplier",
      flex: 0.8,
      cellRenderer: (params) => {
        return (
          <div title={params?.data?.supplier_name} className="line-truncate">
            {params?.data?.supplier_name
              ? params?.data?.supplier_name
              : params?.data?.client_name}
          </div>
        );
      },
    },
    {
      field: "funder",
      headerName: "Funder",
      flex: 0.8,
      cellRenderer: (params) => {
        return <div title={params?.data?.funder}>{params?.data?.funder}</div>;
      },
    },
    {
      field: "cost_value",
      headerName: "Cost Value",
      flex: 0.8,
      cellRenderer: (params) => {
        return (
          <div title={params?.data?.cost_value}>
            {formatNumberInternational(parseFloat(params?.data?.cost_value))}
          </div>
        );
      },
    },
    {
      field: "face_value",
      headerName: "Face Value",
      flex: 0.8,
      cellRenderer: (params) => {
        return (
          <div title={params?.data?.face_value}>
            {formatNumberInternational(parseFloat(params?.data?.face_value))}
          </div>
        );
      },
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 0.8,
      cellRenderer: (params) => {
        return <div>{params?.data?.currency}</div>;
      },
    },
    {
      field: "transaction_type",
      headerName: "Transaction Type",
      flex: 0.8,
      cellRenderer: (params) => {
        return (
          <div title={params?.data?.transaction_type}>
            {params?.data?.transaction_type}
          </div>
        );
      },
    },
  ];
  return (
    <>
      <CustomDatagrid
        tHeight={"20.25rem"}
        rows={fundingDueData?.fundedData || []}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        CustomNoRowsOverlay={CustomNoRowsOverlay}
        onCellClick={(data) => {
          navigate(`/dashboard/funding/view/${data?.data?._id}`);
        }}
      />
      {fundingDueData?.pageDetails?.count > 10 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={fundingDueData?.pageDetails?.count}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}
    </>
  );
};
