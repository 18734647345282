import React, { useEffect, useState } from "react";
import { Heading } from "../../../../atoms/Heading";
import ArrowDown from "../../../../icons/ArrowDown";
import PieChart from "./Chart";
import "./styles.scss";
import { getData } from "../../../../../services/index";
import { useCookies } from "react-cookie";

const Charts = ({ conversionRate, title, type, date }) => {
  const [selected, setSelected] = useState("Client");
  const options = ["Client", "Obligor", "Supplier"];
  const [chartData, setChartData] = useState([]);
  const [cookies] = useCookies(["t"]);

  const fetchInvestmentData = async () => {
    const res = await getData({
      endpoint: "dashboard/getInvestorSummary",
      token: cookies.t,
      params: {
        group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
        start_date: date?.start_date?.replace(".000", ""),
        end_date: date?.end_date?.replace(".000", ""),
      },
    });

    console.log("res", res);
    if (res && res?.data) {
      setChartData(res?.data);
    } else {
      setChartData({});
    }
  };

  const fetchData = async () => {
    const res = await getData({
      endpoint: "dashboard/getChartsData",
      token: cookies.t,
      params: {
        type: selected,
        group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
        start_date: date?.start_date?.replace(".000", ""),
        end_date: date?.end_date?.replace(".000", ""),
      },
    });

    setChartData(res?.data);
  };

  useEffect(() => {
    if (type === "exposure") {
      fetchData();
    }
  }, [selected, date]);

  useEffect(() => {
    if (type === "investment") {
      fetchInvestmentData();
    }
  }, [date]);
  return (
    <div className="dashboard-charts">
      <div className="d-flex justify-content-between">
        <Heading className={"HeadingSubStyle"}>{title}</Heading>

        {type !== "investment" && (
          <div className="dropdown">
            <div
              className="dropdown-box"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {selected}
              {/* <i className="ms-1 d-flex"> */}
              <ArrowDown />
              {/* </i> */}
            </div>
            <ul className="dropdown-menu">
              {options?.map((option) => (
                <li key={option}>
                  <button
                    className="dropdown-item"
                    onClick={() => setSelected(option)}
                  >
                    <span>{option}</span>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <PieChart data={chartData} conversionRate={conversionRate} type={type} />
    </div>
  );
};

export default Charts;
