import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import { getData } from "../../../../../services";
import { Heading } from "../../../../atoms/Heading";
import PageCard from "../../../../atoms/PageCard";
import style from "../index.module.scss";
import { fontSize, width } from "@mui/system";
import Breadcrumb from "../../../../molecules/Breadcrumb";
import moment from "moment";
import ViewMore from "../../../../atoms/ViewMore";
import { formatNumberInternational } from "../../../../../helpers/capatalizeLetters";
import CounterPartyBasicInfo, {
  CounterPartyInternalInfo,
  PriceInformation,
  RelatedPatiesInfo,
} from "./counterpartyBasicInfo";

export const LabelDisplay = ({
  label,
  value,
  className,
  modalStyles = false,
  valueStyles,
  divStyles,
  labelStyles,
}) => {
  return (
    <div
      className={`d-flex ${className}`}
      style={{ width: modalStyles ? "90%" : "50%", ...divStyles }}
    >
      {label && (
        <label
          className={style.label_view}
          style={{ width: "35%", ...labelStyles }}
        >
          {label}
        </label>
      )}
      <div
        className={style.label_description}
        style={{ width: "48%", ...valueStyles }}
      >
        {value}
      </div>
    </div>
  );
};

const ViewCounterparty = ({ type = "", clientData, creditInfo }) => {
  const { id } = useParams();
  const [counterData, setCounterData] = useState([]);
  const [SONames, setSONames] = useState();
  const [cookies] = useCookies(["t"]);
  const [duesData, setDuesData] = useState([]);
  const [priceInfo, setPriceInfo] = useState();

  const getCounterDataById = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getCounterPartyById",
        token: cookies.t,
        params: {
          id: id,
        },
      });
      setCounterData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  const getSupplierAndObligorNames = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getAllNamesSupplierAndObligorById",
        token: cookies.t,
        params: {
          id: id,
        },
      });
      setSONames(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  const getDueData = async (fundingData) => {
    try {
      const res = await getData({
        endpoint: "funding/getFundingByClientId",
        params: {
          id: fundingData._id,
          status: "Funded",
          trans_type: fundingData?.counter_type,
        },
        token: cookies.t,
      });
      const totalCostValue = res?.data?.reduce((sum, item) => {
        return parseFloat(sum) + parseFloat(item.face_value);
      }, 0);
      // console.log("totalFace_value", totalCostValue);
      setDuesData(totalCostValue);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (counterData) {
      getDueData(counterData);
    }
  }, [counterData]);

  useEffect(() => {
    if (id) {
      getSupplierAndObligorNames();
      getCounterDataById();
    }
  }, [id]);

  useEffect(() => {
    const getRateInfo = async () => {
      const priceRes = await getData({
        endpoint: "price/getPriceById",
        token: cookies.t,
        params: { id: counterData?.pricing_id },
      });
      setPriceInfo(priceRes);
      // console.log("priceRes", priceRes);
    };
    if (counterData?.pricing_id) {
      getRateInfo();
    }
  }, [counterData?.pricing_id]);

  // console.log("cli", creditInfo);
  return (
    <>
      <div className={style.duesWrap}>
        <div className={style.clientBasicInfo}>
          <CounterPartyBasicInfo
            clientData={clientData}
            type={type === "Internal Info" ? "" : "related"}
            creditInfo={creditInfo}
            duesData={duesData}
          />
        </div>
        <div className={style.tableInfoWrap}>
          <div>
            {type === "Internal Info" ? (
              <CounterPartyInternalInfo
                counterData={counterData}
                creditInfo={creditInfo}
                duesData={duesData}
              />
            ) : (
              <RelatedPatiesInfo
                supplierObligordata={SONames}
                counterData={counterData}
              />
            )}
          </div>
          {priceInfo?.data && (
            <div>
              <PriceInformation priceInformation={priceInfo?.data} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default ViewCounterparty;
