import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { getData } from "../../../../../../services";
import { Heading } from "../../../../../atoms/Heading";
import VerticalBarChart from "./VerticalBarChart";
import NoContentCard from "../../../../../atoms/NoContentCard";
import PrimitiveDropdown from "../../../../../atoms/PrimitiveDropdown";

const ExposureBasedOnGraphs = ({ title, basedOn }) => {
  const [graphData, setGraphData] = useState(null);
    const [currency, setCurrency] = useState(null);
    const [filteredGraphData, setFilteredGraphData] = useState(null);

  const [cookies] = useCookies(["t"]);

  const getGraphData = async () => {
    try {
      const res = await getData({
        endpoint: "/funding/funderGraphDataBasedOnDetail",
        token: cookies.t,
        params: {
          id: id,
          basedOn: basedOn,
        },
      });

      console.log("graph", res?.data);
      setGraphData(res?.data);

      // Set default currency and filter data
      const uniqueCurrencies = [
        ...new Set(res?.data.map((item) => item.currency)),
      ];
      if (uniqueCurrencies.length > 0) {
        setCurrency(uniqueCurrencies[0]);
        setFilteredGraphData(
          res?.data.filter((item) => item.currency === uniqueCurrencies[0]),
        );
      } else {
        setFilteredGraphData(res?.data);
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  useEffect(() => {
    getGraphData();
  }, [basedOn]);

  const handleCurrencyChange = (selectedCurrency) => {
    setFilteredGraphData(
      graphData.filter((item) => item.currency === selectedCurrency),
    );
  };

  const { id } = useParams();
  return (
    <div className="mt-2">
      <div>
      <div className="headingSearchWrap my-2">
        <Heading className="HeadingSubStyle mb-0 ">{title}</Heading>
        {/* Show dropdown only if multiple currencies exist */}
        {graphData &&
          [...new Set(graphData.map((item) => item.currency))].length > 1 && (
            <div className="me-2">
              <PrimitiveDropdown
                selected={currency}
                options={[...new Set(graphData.map((item) => item.currency))]} // Unique currencies
                onSelect={(value) => {
                  setCurrency(value);
                  handleCurrencyChange(value);
                }}
              />
            </div>
          )}
      </div>
        <div>
          {graphData?.length > 0 ? (
            <VerticalBarChart data={filteredGraphData} />
          ) : (
            <div className="mt-3">
              <NoContentCard title="No Data Found" type={"Pricing"} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExposureBasedOnGraphs;
