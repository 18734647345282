import { useState, useEffect } from "react";
import { Heading } from "../../../../../atoms/Heading";
import CounterDropDown from "../../../../../molecules/CounterDataDropdown";
import MyDropdown from "../../../../../atoms/MyDropdown";
import { useNavigate } from "react-router-dom";
import { DateSelector } from "../../../../../atoms/MonthYearSelector";
import CustomButton from "../../../../../atoms/CustomButton";
import moment from "moment/moment";
// import moment from "moment";
// import CustomButton from "../../../../../atoms/CustomButton";

const Analysis = ({ type = "" }) => {
  const navigate = useNavigate();
  const [clientId, setClientId] = useState(null);
  const [reportingDate, setReportingDate] = useState(null);

  const dateData = [
    { value: "2022-11-30", label: "2022-11-30" },
    { value: "2023-01-31", label: "2023-01-31" },
  ];

  const handleNavigation = () => {
    if (clientId?.value && reportingDate) {
      if (type === "Review") {
        const date = moment(reportingDate).format("YYYY-MM-DD");
        navigate(`${clientId.value}/${date}`, {
          state: { name: clientId.label },
        });
      } else {
        const date = moment(reportingDate).format("YYYY-MM-DD");
        navigate(`details/${clientId.value}/${date}`, {
          state: { name: clientId.label },
        });
      }
    }
  };

  return (
    <>
      <div className={"headingSearchWrap"}>
        <div>
          <Heading className="HeadingStyle">Analysis</Heading>
        </div>
      </div>
      <div className="d-flex gap-3 mt-2">
        <div style={{ width: "32.426%" }}>
          <CounterDropDown
            placeholder="Client Name"
            onChange={(option) => {
              setClientId(option);
            }}
            selectedValue={clientId?.value}
            onlyGroup
          />
        </div>
        <div style={{ position: "relative", width: "32.426%" }}>
          <DateSelector
            placeholder={"Select Reporting As of Date"}
            onChange={(option) => {
              console.log("op", option);
              setReportingDate(option);
            }}
            selectedDate={reportingDate}
          />
        </div>
      </div>
    </>
  );
};

export default Analysis;
