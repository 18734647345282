import React from 'react';
import ReactEcharts from 'echarts-for-react';

const VerticalBarChart = ({ data }) => {
  // Extract obligor names and total face values for the chart
  const obligorNames = data.map(item => item.obligor_name);
  const totalCostValues = data.map(item => item.totalCostValue?.toFixed(2));

  // Chart configuration options
  const getOption = () => {
    return {
      grid: {
        top: '10%', // Adjust grid margins
        left: '5%',
        right: '5%',
        bottom: '15%',
        containLabel: true,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        // Custom tooltip formatter to display obligor info
        formatter: (params) => {
          const index = params[0].dataIndex; // Get the index of the hovered bar
          const selectedData = data[index];  // Get the data corresponding to the hovered bar
          return `
            <strong>${selectedData.obligor_name}</strong>  <br/>
            <strong>Cost Value:</strong> ${selectedData.totalCostValue} <br/>
            <strong>Currency:</strong> ${selectedData.currency} <br/>
          `;
        },
      },
      xAxis: {
        type: 'category',
        data: obligorNames, // X-axis shows obligor names
        axisLabel: {
          rotate: 0, // Rotate labels if they are long
          interval: 0,
          fontSize: 8,
          color: '#333', // Darker font color for better readability
        },
        axisLine: {
          lineStyle: {
            color: '#aaa', // Light gray axis line
          },
        },
      },
      yAxis: {
        type: 'value',
        name: 'Cost Value',
        axisLabel: {
          fontSize: 12,
          color: '#333', // Darker font color for better readability
        },
        axisLine: {
          lineStyle: {
            color: '#aaa', // Light gray axis line
          },
        },
        splitLine: {
          lineStyle: {
            type: 'dashed',
            color: '#ddd', // Light dashed lines for Y-axis grid
          },
        },
      },
      series: [
        {
          type: 'bar',
          data: totalCostValues, // Data for the bars
          itemStyle: {
            color: '#146ef5', // Custom bar color
          },
          barWidth: data?.length < 5 ? '100px' : 'auto', // Make bars thicker for better visibility
          label: {
            show: true, // Show values on top of bars
            position: 'top',
            color: '#333', // Label text color
          },
        },
      ],
    };
  };

  return (
 
      <ReactEcharts
        option={getOption()}
        className='responsive-graph-height'
      />
  );
};

export default VerticalBarChart;
