import React from "react";
import { useNavigate } from "react-router-dom";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import { Heading } from "../../../atoms/Heading";
import Search from "../../../atoms/Search";
import style from "./index.module.scss";
import { memo, useCallback, useEffect, useState } from "react";
import NoContentCard from "../../../atoms/NoContentCard";
import { deleteData, getData } from "../../../../services";
import { useCookies } from "react-cookie";
import View from "../../../icons/View";
import EditBlue from "../../../icons/EditBlue";
import Pagination from "../../../atoms/Pagination";
import CustomButton from "../../../atoms/CustomButton";
import moment from "moment/moment";
import Modal from "../../../atoms/modals/DeleteModal";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/components/toast";
import Delete from "../../../icons/Delete";
import FilterComponent from "../../../molecules/filters/FilterComponent";
import {
  counterPartyType,
  GICSectorData,
  statusData,
  transactionType,
} from "../../../../utils/staticData";
import HorizontalMenu from "../../../icons/HorizontalMenu";
import CustomMenu from "../../../molecules/CustomMenu";
import Edit from "../../../icons/EditBlue";
import Upload from "../../../icons/Upload";
import { downloadReport } from "../../../../services/downloadService";
import { useSelector } from "react-redux";

const CounterParty = () => {
  const [cookies] = useCookies(["t", "roleGroup"]);
  const navigate = useNavigate();
  const accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.Counterparties;
  const [searchData, setSearchData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [counterpartyData, setCounterPartData] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalStateDelete, setModalStateDelete] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false, // Set initial state to false
  });
  const [filters, setFilters] = useState({});
  const [loader, setLoader] = useState(true);

  const getCounterPartyData = async () => {
    setLoader(true);
    try {
      const res = await getData({
        endpoint: "couterParty/getAllCounters",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          counterType: filters?.["Counterparty Type"],
          status: filters?.["Status"],
          transactionType: filters?.["Transaction Type"],
          sector: filters?.["Sector"],
          group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
        },
      });
      setCounterPartData(res?.data);
    } catch (err) {
      console.log("errr", err);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    setLoader(false);
    getCounterPartyData();
  }, [itemsPerPage, currentPage, searchData, filters]);

  const handleEdit = (data) => {
    navigate(
      `/dashboard/counterparty/add/${data?._id}/${
        data.status === "Draft" ? "123" : ""
      }`,
    );
  };

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  console.log("accesData", roleData);

  const menu = [
    {
      key: "view",
      icon: <View height="20" width="20" />,
      label: "View",
      onClick: () => {
        navigate(`/dashboard/counterparty/view/${selectedRow?._id}`);
      },
    },
    {
      key: "edit",
      icon: <Edit height="20" width="20" />,
      label: "Edit",
      onClick: () => {
        navigate(
          `/dashboard/counterparty/add/${selectedRow?._id}/${
            selectedRow.status === "Draft" ? "123" : ""
          }`,
          {
            state: {
              edit:
                selectedRow.counter_type === "Supplier" ||
                selectedRow.counter_type === "Supplier Credit" ||
                selectedRow.counter_type === "Supplier Credit-1"
                  ? "Supplier"
                  : selectedRow.counter_type === "Factorial/AR" ||
                    selectedRow?.counter_type === "Obligor"
                  ? "Obligor"
                  : "Client",
            },
          },
        );
      },
    },
    {
      key: "delete",
      icon: <Delete />,
      label: "Delete",
      onClick: () => {
        setModalStateDelete({
          isModalOpen: true,
          name: selectedRow.counter_name,
          modalId: selectedRow._id,
        });
        setAnchorEl(null);
      },
    },
  ];

  const columns = [
    {
      field: "counter_name",
      headerName: "Counterparty name",
      flex: 0.8,
      sortable: true,
      cellRenderer: (params) => {
        return (
          <div className="line-truncate" title={params?.data?.counter_name}>
            {params?.data?.counter_name}
          </div>
        );
      },
    },
    {
      field: "counter_type",
      headerName: "Counterparty Type",
      flex: 0.6,
      sortable: true,
      cellRenderer: (params) => {
        return (
          <div title={params?.data?.counter_type}>
            {params?.data?.counter_type}
          </div>
        );
      },
    },
    {
      field: "trans_type",
      headerName: "Funding Type",
      flex: 0.6,
      sortable: true,
      cellRenderer: (params) => {
        return (
          <div title={params?.data?.trans_type}>{params?.data?.trans_type}</div>
        );
      },
    },
    {
      field: "agreement_counter",
      headerName: "Agreement CounterParty",
      flex: 1.1,
      cellRenderer: (params) => {
        const info =
          params?.data?.counter_type !== "Client"
            ? params?.data?.clientInfo?.agreement_counter
            : params?.data?.agreement_counter;
        return (
          <div title={info} style={{ whiteSpace: "pre" }}>
            {info}
          </div>
        );
      },
    },
    {
      field: "gics_sector",
      headerName: "Sector",
      flex: 0.8,
      sortable: true,
      cellRenderer: (params) => {
        return <div className="line-truncate">{params?.data?.gics_sector}</div>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.4,
      sortable: true,
      valueGetter: (params) => {
        return params?.data?.status || "";
      },
      cellRenderer: (params) => {
        return (
          <>
            {params?.data?.status === "Active" ? (
              <span className="text-success fw-500">Active</span>
            ) : (
              <span className="text-error fw-500">{params?.data.status}</span>
            )}
          </>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 0.5,
      sortable: true,
      cellRenderer: (params) => {
        return (
          <>
            <span>
              {moment.utc(params?.data?.createdAt).format("YYYY-MM-DD")}
            </span>
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 0.4,
      cellRenderer: (params, index) => {
        return (
          <>
            <span
              type="button"
              onClick={(event) => handleStatusMenu(event, params?.data)}
            >
              <HorizontalMenu />
            </span>
          </>
        );
      },
    },
  ];

  const getMenuList = () => {
    if (
      roleData &&
      roleData?.["add/edit"] &&
      roleData?.view &&
      roleData?.delete
    ) {
      return menu;
    } else if (roleData && roleData?.["add/edit"] && roleData?.view) {
      return [menu[0], menu[1]];
    } else if (roleData && roleData?.["add/edit"] && roleData?.delete) {
      return [menu[1], menu[2]];
    } else if (roleData && roleData?.view && roleData?.delete) {
      return [menu[0], menu[2]];
    } else if (roleData && roleData?.view) {
      return [menu[0]];
    } else if (roleData && roleData?.delete) {
      return [menu[2]];
    } else if (roleData && roleData?.["add/edit"]) {
      return [menu[1]];
    } else return [];
  };

  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        {!!(searchData || Object.keys(filters).length > 0) ? (
          <>
            <NoContentCard
              title="No Counterparty Found"
              tag="Create Bill"
              type="Pricing"
            />
          </>
        ) : (
          !loader && (
            <>
              <NoContentCard
                title="No Counterparties created yet!"
                tag="Create Counterparties"
                type="Counterparty"
                classes="d-flex align-items-center"
                {...(roleData && roleData?.["add/edit"]
                  ? {
                      handleClick: () => {
                        navigate("/dashboard/counterparty/add");
                      },
                      subtitle:
                        "No Counterparties have been created yet, click on create counterparties to create a new counterparty.",
                    }
                  : {})}
              />
            </>
          )
        )}
      </>
    );
  });
  const handleCloseDelete = () => {
    setModalStateDelete(() => ({
      isModalOpen: false,
    }));
  };
  const handleDelete = async () => {
    try {
      const res = await deleteData({
        endpoint: "couterParty/deleteCounter",
        token: cookies.t,
        params: { id: modalStateDelete?.modalId },
      });
      if (res) {
        toast.success("CounterParty Deleted Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        handleCloseDelete();
        getCounterPartyData();
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  // Handle page change
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  const filtersData = {
    "Counterparty Type": counterPartyType,
    "Transaction Type": transactionType,
    Status: statusData,
    Sector: GICSectorData,
  };

  const handleDownload = async () => {
    const res = await downloadReport({
      endpoint: "couterParty/export",
      token: cookies.t,
      fileName: "Counterparties",
      params: {
        search: searchData,
        counterType: filters?.["Counterparty Type"],
        status: filters?.["Status"],
        transactionType: filters?.["Transaction Type"],
        group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
      },
    });

    if (res) {
      toast.success("Counterparties Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  return (
    <>
      <CustomMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        items={getMenuList()}
        // items={menu}
      />
      <div className={style.headingSearchWrap}>
        <div>
          <Heading className="HeadingStyle">Counterparties</Heading>
        </div>
        <div className={style.searchButton}>
          <Search
            placeholder="Search by name"
            onSearchEmit={(data) => {
              setSearchData(data);
            }}
          />

          {roleData && roleData?.export && (
            <CustomButton
              text="Export"
              handleClick={() => handleDownload()}
              classes={"filter-contain-btn"}
              iconRequired
              icon={<Upload />}
            />
          )}
          <FilterComponent
            filterData={filtersData}
            onFilterChange={(filters) => {
              setFilters(filters);
            }}
          />

          {roleData && roleData?.["add/edit"] && (
            <CustomButton
              text="Create Counterparty"
              type="btn-primary"
              handleClick={() => {
                navigate("/dashboard/counterparty/add");
              }}
            />
          )}
        </div>
      </div>
      {/* {true ? (
        <div
          className="w-100  d-flex align-items-center justify-content-center"
          style={{ height: "70vh" }}
        >
          <span
            className="spinner-border spinner-border-lr ms-2"
            style={{ color: "#0d6efd", width: "50px", height: "50px" }}
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      ) : (
        <> */}
      <CustomDatagrid
        rows={counterpartyData?.[0]?.roleData || []}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        isRowsVisible
        CustomNoRowsOverlay={CustomNoRowsOverlay}
        loader={loader}
        onCellClick={(params) => {
          if (params?.colDef?.field !== "actions") {
            navigate(`/dashboard/counterparty/view/${params?.data?._id}`);
          }
        }}
      />
      {/* {counterpartyData?.[0]?.pageDetails?.count > 10 && ( */}
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={counterpartyData?.[0]?.pageDetails?.count}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
      {/* </>
      )} */}
      {/* )} */}
      <Modal
        isActive={modalStateDelete.isModalOpen}
        onClose={handleCloseDelete}
        onClick={handleDelete}
        title="Counterparty"
        name={modalStateDelete.name}
      />
    </>
  );
};

export default CounterParty;
