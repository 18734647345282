import FileViewer from "react-file-viewer";
import CustomButton from "../../../../../atoms/CustomButton";
import { Heading } from "../../../../../atoms/Heading";
import { DateSelector } from "../../../../../atoms/MonthYearSelector";
import style from "../index.module.scss";
import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { DocViewerRenderers, PDFRenderer } from "react-doc-viewer";
import { getData } from "../../../../../../services";
import { useCookies } from "react-cookie";
import moment from "moment";
import {
  calculateFinalBaseRate,
  findRateForMonth,
  getApplicableBaseRates,
} from "../../../../../../helpers/finalDateCalculate";
import { formatNumberInternational } from "../../../../../../helpers/capatalizeLetters";
import Downlaod from "../../../../../icons/Downlaod";
import { extractFileName } from "../../../../../../helpers/extractFileName";

const SummaryInterest = ({ pricingData, fundedDataById }) => {
  const [cookies] = useCookies(["t"]);
  const [fundingData, setFundingData] = useState();
  const [excelData, setExcelData] = useState(null);
  const [excelTypeData, setExcelTypeData] = useState({
    fileType: "",
    file: "",
  });
  const [currentIndex, setCurrentIndex] = useState({
    docIndex: 0,
    fileType: "",
    imagesType: "", // Add imagesType to the state
  });
  const [paymentDetails, setPaymentDetails] = useState();
  const [accruedDate, setAccuredDate] = useState();
  const [interestData, setInterestData] = useState();
  const [interestRateFloating, setInterestRateFloating] = useState();
  const [interestRateFlat, setInterestFlat] = useState();

  const getInterestRate = async (rateID) => {
    try {
      const res = await getData({
        endpoint: "rates/getRateById",
        token: cookies.t,
        params: { id: rateID },
      });
      setInterestRateFloating(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (pricingData?.rate_type === "Floating") {
      getInterestRate(pricingData?.base_rate);
    } else if (pricingData?.rate_type === "Flat") {
      setInterestFlat(pricingData?.top_rate);
    }
  }, [pricingData]);

  useEffect(() => {
    if (fundedDataById?.documents) {
      setFundingData(fundedDataById);
      setPaymentDetails(fundedDataById?.paymentHistory);
    }
    if (fundedDataById?.documents) {
      // const groupedDocuments = fundedDataById?.documents.reduce((acc, doc) => {
      //   if (!acc[doc?.imagesType]) {
      //     acc[doc?.imagesType] = [];
      //   }
      //   acc[doc?.imagesType].push(doc);
      //   return acc;
      // }, {});
      // setDocumentsGroupItem(groupedDocuments);
      // const firstImagesType = Object.keys(groupedDocuments)[0];
      // if (firstImagesType) {
      //   setCurrentIndex({
      //     docIndex: 0, // Set to the first document
      //     fileType: groupedDocuments[firstImagesType][0]?.imagesType,
      //     imagesType: firstImagesType, // Set to the first imagesType
      //   });
      // }
      setCurrentIndex({
        docIndex: 0, // Set to the first document
        fileType: fundedDataById?.documents?.[0]?.file,
      });
    }
  }, [fundedDataById]);

  const handleIndexFile = (index, doc) => {
    setCurrentIndex(() => ({
      docIndex: index,
      fileType: doc?.file,
      // imagesType: imagesType, // Set the imagesType when a file is clicked
    }));

    const type = getFileType(doc?.file);
    if (type.toLowerCase() === "xls" || type.toLowerCase() === "xlsx") {
      setExcelTypeData(() => ({
        fileType: type,
        file: doc?.file,
      }));
    }
  };
  const xlsxFileDownload = async (fileUrl) => {
    try {
      const response = await fetch(fileUrl);
      const arrayBuffer = await response.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer, { type: "array" });

      const sheetName = workbook.SheetNames[0];
      const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
        header: 1,
      });
      setExcelData(sheetData);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (excelTypeData?.file) {
      xlsxFileDownload(excelTypeData.file);
    }
  }, [excelTypeData]);

  useEffect(() => {
    if (fundingData?.documents?.[0]) {
      const fileTypeAtIndex0 = getFileType(fundingData.documents[0]?.file);
      if (fileTypeAtIndex0 === "xls" || fileTypeAtIndex0 === "xlsx") {
        setExcelTypeData(() => ({
          fileType: fileTypeAtIndex0,
          file: fundingData.documents[0]?.file,
        }));
      }
    }
  }, [fundingData?.documents, currentIndex]); // Run this effect when fundingData changes

  const getFileType = (url) => {
    const extension = url?.split(".")?.pop();
    return extension?.toLowerCase();
  };

  useEffect(() => {
    if (accruedDate && pricingData?.rate_type === "Floating") {
      const tempTotalInterest = findRateForMonth(
        interestRateFloating?.rates,
        new Date(fundedDataById?.funded_date),
      );
      console.log("tempTotalInterest", tempTotalInterest);
      const tempDailyInterest = calculateDailyCompoundInterest({
        principal: parseFloat(fundedDataById?.cost_value),
        annualRate:
          parseFloat(tempTotalInterest?.base_rate) +
          parseFloat(pricingData?.top_rate),
        startDate: moment
          .utc(fundedDataById?.funded_date)
          .add(1, "day")
          .toISOString(),
        endDate: accruedDate,
      });
      setInterestData(tempDailyInterest);
    } else {
      const tempDailyInterest = calculateDailyCompoundInterest({
        principal: parseFloat(fundedDataById?.cost_value),
        annualRate: pricingData?.top_rate,
        startDate: moment(fundedDataById?.funded_date)
          .add(1, "day")
          .toISOString(),
        endDate: accruedDate,
      });
      setInterestData(tempDailyInterest);
    }
  }, [accruedDate]);

  const calculateDailyCompoundInterest = ({
    principal,
    annualRate,
    startDate,
    endDate,
  }) => {
    const start = new Date(
      Date.UTC(
        new Date(startDate).getUTCFullYear(),
        new Date(startDate).getUTCMonth(),
        new Date(startDate).getUTCDate(),
      ),
    );

    const end = new Date(
      Date.UTC(
        new Date(endDate).getUTCFullYear(),
        new Date(endDate).getUTCMonth(),
        new Date(endDate).getUTCDate(),
      ),
    );
    console.log("end", end);
    const dailyInterestRate = annualRate / 36000;

    let currentDate = new Date(start);
    const dailyInterest = principal * dailyInterestRate;
    let interest = 0;
    while (currentDate <= end) {
      interest += dailyInterest;
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return {
      endDate: end.toLocaleDateString("en-GB"), // End date in DD/MM/YYYY format
      interest: interest?.toFixed(2), // Total interest accrued
      totalAmount: (principal + interest)?.toFixed(2), // Principal + Interest
    };
  };

  const handleDownloadFileURL = (file) => {
    const link = document.createElement("a");
    link.href = file;
    link.download = file.name || "download";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className={style.bothsidewrap}>
      <div className={style.fundedViewleftWrap}>
        <div>
          <table className="w-100 border-collapse">
            <thead>
              <tr className="bg-gray">
                <th colSpan="4" className="border p-2 text-left">
                  Payment
                </th>
              </tr>
              <tr className="bg-gray">
                <th className="border p-2 text-left">Payment Amount</th>
                <th className="border p-2 text-left">Currency</th>
                <th className="border p-2 text-left">Date</th>
              </tr>
            </thead>
            <tbody>
              {paymentDetails?.map((item) => {
                return (
                  <tr>
                    <td className="border p-2">{item?.paymentAmount}</td>
                    <td className="border p-2">{item?.currency}</td>
                    <td className="border p-2">
                      {moment.utc(item?.paydate).format("YYYY-MM-DD")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div>
          <Heading className="HeadingSubStyle mt-3">Accrued Date</Heading>
          <label>As of Date</label>
          <div style={{ position: "relative", width: "100%" }}>
            <DateSelector
              placeholder="Select Date"
              onChange={(option) => {
                setAccuredDate(option);
              }}
              selectedDate={accruedDate}
              minDateData={moment
                .utc(fundedDataById?.funded_date)
                .add(1, "day")}
            />
          </div>
          {accruedDate && (
            <div>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Principal and Interest</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={style.label}>Cost</td>
                    <td className={style.value}>
                      {formatNumberInternational(fundingData?.cost_value)}
                    </td>
                    <td className={style.value}>{fundingData?.currency}</td>
                  </tr>
                  <tr>
                    <td className={style.label}>Principal Paid</td>
                    <td className={style.value}>
                      {formatNumberInternational(
                        paymentDetails
                          ?.filter((item) => item?.paymentType === "Principle")
                          .reduce(
                            (total, item) =>
                              total + parseFloat(item.paymentAmount),
                            0,
                          ),
                      )}
                    </td>
                    <td className={style.value}>{fundingData?.currency}</td>
                  </tr>
                  <tr>
                    <td className={style.label}>Principal Remaining</td>
                    <td className={style.value}>
                      {formatNumberInternational(
                        parseFloat(fundingData?.cost_value) -
                          parseFloat(
                            paymentDetails
                              ?.filter(
                                (item) => item?.paymentType === "Principle",
                              )
                              .reduce(
                                (total, item) =>
                                  total + parseFloat(item.paymentAmount),
                                0,
                              ),
                          ),
                      )}
                    </td>
                    <td className={style.value}>{fundingData?.currency}</td>
                  </tr>
                  <tr>
                    <td className={style.label}>Interest Paid</td>
                    <td className={style.value}>
                      {formatNumberInternational(
                        paymentDetails
                          ?.filter((item) => item?.paymentType === "Interest")
                          .reduce(
                            (total, item) =>
                              total + parseFloat(item.paymentAmount),
                            0,
                          ),
                      )}
                    </td>
                    <td className={style.value}>{fundingData?.currency}</td>
                  </tr>
                  <tr>
                    <td className={style.label}>Interest Accrued</td>
                    <td className={style.value}>
                      {formatNumberInternational(interestData?.interest)}
                    </td>
                    <td className={style.value}>{fundingData?.currency}</td>
                  </tr>
                  <tr>
                    <td className={style.label}>Amount to Close</td>
                    <td className={style.value}>
                      {fundingData?.transaction_type === "Obligor" ||
                      fundingData?.transaction_type === "Factoring/AR" ||
                      fundingData?.transaction_type === "Supplier Credit-1"
                        ? formatNumberInternational(
                            parseFloat(fundingData?.cost_value) +
                              parseFloat(interestData?.interest),
                          )
                        : formatNumberInternational(fundingData?.face_value)}
                    </td>
                    <td className={style.value}>{fundingData?.currency}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
        <div>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Pricing Information</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={style.label}>Name</td>
                <td className={style.value}>{pricingData?.name}</td>
              </tr>
              <tr>
                <td className={style.label}>Description</td>
                <td className={style.value}>{pricingData?.description}</td>
              </tr>
              <tr>
                <td className={style.label}>Top Rate</td>
                <td className={style.value}>{pricingData?.top_rate}</td>
              </tr>
              <tr>
                <td className={style.label}>Base Rate</td>
                <td className={style.value}>
                  {pricingData?.base_rate_name || " "}
                </td>
              </tr>
              <tr>
                <td className={style.label}>Days</td>
                <td className={style.value}>{pricingData?.days}</td>
              </tr>
              <tr>
                <td className={style.label}>Advance Rate</td>
                <td className={style.value}>{pricingData?.advance_rate}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className={style.fundedViewRightWrap}>
        <div className={style.iframeStyle}>
          <div className={style.documentsWrap}>
            {fundedDataById?.documents?.map((item, index) => {
              return (
                <>
                  <button
                    className={`${
                      currentIndex?.docIndex === index
                        ? "btn-primary"
                        : "btn-outline-dark"
                    } btn-sm ${style.button_file}`}
                    style={{
                      position: "relative",
                      height: "36px",
                      minWidth: "35px",
                      padding: "8px 0",
                    }}
                    onClick={() => {
                      handleIndexFile(index, item);
                      setExcelData();
                    }}
                  >
                    {index + 1}
                  </button>
                </>
              );
            })}
          </div>

          {currentIndex.fileType && (
            <>
              <div className="mt-2 d-flex justify-content-between align-items-center">
                <div>
                  <strong>Selected File:</strong>{" "}
                  {extractFileName(currentIndex.fileType)}
                </div>
                <button
                  className="btn-primary p-2"
                  onClick={() => handleDownloadFileURL(currentIndex?.fileType)}
                  style={{ borderRadius: "4px" }}
                >
                  Download
                </button>
              </div>
            </>
          )}

          <div className="mt-0">
            {currentIndex.fileType && (
              <>
                {(getFileType(currentIndex?.fileType) === "png" ||
                  getFileType(currentIndex?.fileType) === "jpeg") && (
                  <img
                    src={currentIndex?.fileType}
                    alt="images"
                    width={"100%"}
                    height={"auto"}
                  />
                )}
                {getFileType(currentIndex?.fileType) === "docx" && (
                  <FileViewer
                    fileType="docx"
                    filePath={currentIndex?.fileType}
                    pluginRenderers={DocViewerRenderers}
                  />
                )}
                {getFileType(currentIndex?.fileType) === "pdf" && (
                  <FileViewer
                    fileType="pdf"
                    filePath={currentIndex?.fileType}
                    pluginRenderers={PDFRenderer}
                  />
                )}
                {excelData && (
                  <div className={style.excelBoadyWrap}>
                    <table border="1">
                      <thead>
                        <tr>
                          {excelData[0].map((header, i) => (
                            <th className={style.headerStyleCell} key={i}>
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {excelData.slice(1).map((row, i) => (
                          <tr key={i}>
                            {row.map((cell, j) => (
                              <td className={style.columnCellStyle} key={j}>
                                {cell}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SummaryInterest;
