import { useNavigate } from "react-router-dom";
import CustomButton from "../../../atoms/CustomButton";
import { Heading } from "../../../atoms/Heading";
import Search from "../../../atoms/Search";
import style from "./index.module.scss";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import { memo, useCallback, useEffect, useState } from "react";
import NoContentCard from "../../../atoms/NoContentCard";
import { deleteData, getData, patchData } from "../../../../services";
import { useCookies } from "react-cookie";
import Pagination from "../../../atoms/Pagination";
import Delete from "../../../icons/Delete";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../assets/styles/components/toast";
import Modal from "../../../atoms/modals/DeleteModal";
import Edit from "../../../icons/EditBlue";
import CustomMenu from "../../../molecules/CustomMenu";
import HorizontalMenu from "../../../icons/HorizontalMenu";
import moment from "moment";
import View from "../../../icons/View";
import { useSelector } from "react-redux";
import Refresh from "../../../icons/Refresh";
import { getPandaApi } from "../../../../services/pandaDocs";

const Tools = () => {
  const [cookies] = useCookies(["t"]);
  const navigate = useNavigate();
  const accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.Tools;
  const [documentsData, setDocumentsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [searchData, setSearchData] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalStateDelete, setModalStateDelete] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false, // Set initial state to false
  });
  const [loader, setLoader] = useState(true);
  const [pandaDocStatus, setPandaDocStatus] = useState([]);

  const GetDocumentsData = async () => {
    setLoader(true);
    try {
      const res = await getData({
        endpoint: "/documents",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
        },
      });
      setDocumentsData(res?.data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    setLoader(false);
    GetDocumentsData();
  }, [currentPage, itemsPerPage, searchData]);

  const handleUpdateDocStatus = async ({ data }) => {
    if (!data?._id) {
      console.error("Invalid data: Missing id");
      return;
    }
    const documentStatus = await handlePandaDocsStatus(data);

    if (!documentStatus?.status) {
      console.error("Invalid data: Missing Status");
      return;
    }
    console.log("Updating document:", {
      id: data?._id,
      status: documentStatus?.status,
    });

    try {
      const res = await patchData({
        endpoint: "/documents/update",
        token: cookies.t,
        data: { status: documentStatus.status },
        params: { id: data._id },
      });

      if (res?.data) {
        GetDocumentsData();
      } else {
        console.error("Failed to update document: No response data");
      }
    } catch (error) {
      console.error("Error updating document:", error);
    } finally {
      setLoader(false);
    }
  };

  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        {!!searchData ? (
          <>
            <NoContentCard
              title="No Document Found"
              tag="Create Bill"
              type="Pricing"
            />
          </>
        ) : (
          !loader && (
            <>
              <NoContentCard
                title="No Documents created yet!"
                tag="Create Documents"
                type="Pricing"
                classes="d-flex align-items-center"
                {...(roleData && roleData?.["add/edit"]
                  ? {
                      handleClick: () => {
                        navigate("/dashboard/tools/documents");
                      },
                      subtitle:
                        "No Documents have been created yet. Click on Create Documents button to add new Document.",
                    }
                  : {})}
              />
            </>
          )
        )}
      </>
    );
  });

  const menu = [
    {
      icon: <View height="20" width="20" />,
      label: "View",
      onClick: () => {
        navigate(`view/${selectedRow?._id}`);
      },
    },
    {
      icon: <Delete />,
      label: "Delete",
      onClick: () => {
        setModalStateDelete({
          isModalOpen: true,
          name: selectedRow.documentSubType,
          modalId: selectedRow._id,
        });
        setAnchorEl(null);
      },
    },
  ];

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePandaDocsStatus = async (data) => {
    console.log("data", data);
    const response = await getPandaApi({
      endpoint: `https://api.pandadoc.com/public/v1/documents/${data?.pandaDocs}`,
      params: {},
      token: process.env.REACT_APP_PANDADOC_ID,
    });

    if (response) {
      toast.success("Document Status Refreshed", {
        duration: 1000,
        style: confimationStyles,
      });
    }
    setPandaDocStatus((prev) => {
      const id = data?._id;
      const status = response.status;

      // Check if the ID already exists in the state
      const exists = prev.some((entry) => entry[id] !== undefined);

      if (exists) {
        // Update the existing entry
        return prev.map((entry) =>
          entry[id] !== undefined ? { [id]: status } : entry,
        );
      } else {
        // Add a new entry
        return [...prev, { [id]: status }];
      }
    });

    return response;
  };

  const columns = [
    {
      field: "documentSubType",
      headerName: "Document Name",
      flex: 0.6,
      sortable: true,
      cellRenderer: (params) => {
        return (
          <div
            className={"line-truncate"}
            title={params?.data?.documentSubType}
          >
            {params?.data?.documentSubType}
            {/* check checjvhugfjidksjfhugv cfvhgjfmdcfj cfhgfcjndi gcxhnjsueg gdfurdhejsncx */}
          </div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 0.3,
      sortable: true,
      cellRenderer: (params) => {
        return (
          <div className={style.valueStyle}>
            {moment.utc(params?.data?.createdAt).format("YYYY-MM-DD")}
          </div>
        );
      },
    },
    {
      field: "external_entity_email",
      headerName: "External Email(sent to)",
      flex: 0.4,
      valueGetter: (params) => {
        return params?.data?.DocumentsDetails?.external_entity_email || "";
      },
      cellRenderer: (params) => {
        return (
          <div
            className={style.valueStyle}
            title={params?.data?.DocumentsDetails?.external_entity_email}
          >
            {params?.data?.DocumentsDetails?.external_entity_email
              ? params?.data?.DocumentsDetails?.external_entity_email
              : ""}
          </div>
        );
      },
    },
    {
      field: "external_entity_first_name",
      headerName: "External name (Sent to)",
      flex: 0.42,
      valueGetter: (params) => {
        const firstName =
          params?.data?.DocumentsDetails?.external_entity_first_name || "";
        const lastName =
          params?.data?.DocumentsDetails?.external_entity_last_name || "";
        return firstName + " " + lastName;
      },
      cellRenderer: (params) => {
        return (
          <div
            className={style.valueStyle}
            title={
              params?.data?.DocumentsDetails?.external_entity_first_name +
              " " +
              params?.data?.DocumentsDetails?.external_entity_last_name
            }
          >
            {params?.data?.DocumentsDetails?.external_entity_first_name
              ? params?.data?.DocumentsDetails?.external_entity_first_name +
                " " +
                params?.data?.DocumentsDetails?.external_entity_last_name
              : ""}
          </div>
        );
      },
    },

    {
      field: "external_entity",
      headerName: "External Entity",
      flex: 0.42,
      valueGetter: (params) => {
        return params?.data?.DocumentsDetails?.external_entity_email || "";
      },
      cellRenderer: (params) => {
        return (
          <div
            className={style.valueStyle}
            title={params?.data?.DocumentsDetails?.external_entity}
          >
            {params?.data?.DocumentsDetails?.external_entity
              ? params?.data?.DocumentsDetails?.external_entity
              : ""}
          </div>
        );
      },
    },

    {
      field: "status",
      headerName: "Status",
      flex: 0.4,
      sortable: true,
      cellRenderer: (params) => {
        const rowId = params?.data?._id; // Adjust this to match your row ID field\
        // console.log("params?.data?", params?.data?);
        const documentStatus = pandaDocStatus.find(
          (entry) => entry[rowId] !== undefined,
        );
        return (
          <div className={style.valueStyle} title={params?.data?.status}>
            <div className="d-flex align-items-center gap-3">
              <div
                title={
                  params?.data?.status === "document.completed"
                    ? "Document Completed"
                    : params?.data?.status === "document.viewed"
                    ? "Document Viewed"
                    : params?.data?.status === "document.uploaded"
                    ? "Document Sent"
                    : params?.data?.status
                    ? params?.data?.status
                    : ""
                }
              >
                {/* {documentStatus ? documentStatus[rowId] : "Documents Sent"} */}
                {params?.data?.status === "document.completed"
                  ? "Document Completed"
                  : params?.data?.status === "document.viewed"
                  ? "Document Viewed"
                  : params?.data?.status === "document.uploaded"
                  ? "Document Uploaded"
                  : params?.data?.status === "document.sent"
                  ? "Document Sent"
                  : params?.data?.status
                  ? params?.data?.status
                  : ""}
              </div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  // handlePandaDocsStatus(params?.row);
                  handleUpdateDocStatus({
                    data: params?.row,
                  });
                }}
              >
                <Refresh size="16" />
              </div>
            </div>
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 0.2,
      cellRenderer: (params, index) => {
        return (
          <>
            <span
              type="button"
              onClick={(event) => handleStatusMenu(event, params?.data)}
            >
              <HorizontalMenu />
            </span>
          </>
        );
      },
    },
  ];
  const getMenuList = () => {
    if (roleData && roleData?.view && roleData?.delete) {
      return menu;
    } else if (roleData && roleData?.delete) {
      return [menu[1]];
    } else if (roleData && roleData?.view) {
      return [menu[0]];
    } else return [];
  };
  const handleCloseDelete = () => {
    setModalStateDelete(() => ({
      isModalOpen: false,
    }));
  };
  const handleDelete = async () => {
    try {
      const res = await deleteData({
        endpoint: "documents/delete",
        token: cookies.t,
        params: { id: modalStateDelete?.modalId },
      });
      if (res) {
        toast.success("Document Deleted Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        handleCloseDelete();
        GetDocumentsData();
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  // Handle page change
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  return (
    <>
      <CustomMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        items={getMenuList()}
      />
      <div
        className={`${style.headingSearchWrap} d-flex w-100 justify-content-between`}
      >
        <div>
          <Heading className="HeadingStyle">Tools</Heading>
        </div>
        <div className="searchButton">
          <Search
            onSearchEmit={(data) => {
              setSearchData(data);
            }}
          />
          {/* <ButtonDropDown menu={ButtonMenu} buttonTitle="Add Tools" /> */}
          {roleData && roleData?.["add/edit"] && (
            <CustomButton
              text="Create Documents"
              type="btn-primary"
              handleClick={() => {
                navigate("/dashboard/tools/documents");
              }}
            />
          )}
        </div>
      </div>
      <CustomDatagrid
        rows={documentsData?.[0]?.documentData || []}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        loader={loader}
        isRowsVisible
        CustomNoRowsOverlay={CustomNoRowsOverlay}
        onCellClick={(params) => {
          if (
            params?.colDef?.field !== "actions" &&
            params?.colDef?.field !== "status"
          ) {
            navigate(`/dashboard/tools/view/${params?.data?._id}`);
          }
        }}
      />
      {/* {documentsData?.[0]?.pageDetails?.count > 10 && ( */}
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={documentsData?.[0]?.pageDetails?.count}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
      {/* )} */}
      <Modal
        isActive={modalStateDelete.isModalOpen}
        onClose={handleCloseDelete}
        onClick={handleDelete}
        title="Document"
        name={modalStateDelete.name}
      />
    </>
  );
};
export default Tools;
