import React, { memo, useCallback, useEffect, useState } from "react";
// import Heading from '../../../../../atoms/FormHeading';
import { Heading } from "../../../../../atoms/Heading";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../../../../molecules/Breadcrumb";
import QuillEditor from "../../../../../atoms/QuillEditor/QuillEditor";
import moment from "moment";
import CustomDatagrid from "../../../../../molecules/CustomDatagrid";
import NoContentCard from "../../../../../atoms/NoContentCard";
import { getData, patchData, postData } from "../../../../../../services";
import { useCookies } from "react-cookie";
import Pagination from "../../../../../atoms/Pagination";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../../assets/styles/components/toast";
import { formatNumberInternational } from "../../../../../../helpers/capatalizeLetters";
import { convertAndAddCurrencies } from "../../../dashboard/helper/currency";
import { getCurrencyRate } from "../../../../../../services/currencyConvertor";
import MyTextArea, { CustomTextArea } from "../../../../../atoms/MyTextArea";

const Valuation = () => {
  const { date } = useParams();
  const [cookies] = useCookies(["t"]);
  const [clientSummary, setClientSummary] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10000);
  const [valuationData, setValuationData] = useState({
    macro: "",
    interest_rates: "",
    credit_spreads: "",
  });
  const [currencyConversion, setCurrencyConversion] = useState(null);
  const [downloadLoader, setDownloadLoader] = useState(false);

  useEffect(() => {
    const fetchCurrencyRate = async () => {
      try {
        const res = await getCurrencyRate({ date: new Date() });
        setCurrencyConversion(res);
      } catch (error) {
        console.error("Error fetching currency rate:", error);
      }
    };

    fetchCurrencyRate(); // Call the async function
  }, []);

  const createDocRecords = (num) => {
    return Array.from({ length: num }, () => ({
      client_id: "",
      priorValuation: "",
      selectMethodology: "",
      commentDiscussion: "",
      nextSteps: "",
    }));
  };

  const [clientValuation, setClientValuation] = useState({});

  // useEffect(() => {

  //   const doc = createDocRecords(1);
  //   setClientValuation(doc)

  // },[])

  const [clientValuationAdd, setClientValuationAdd] = useState();

  const getClientData = async () => {
    try {
      const res = await getData({
        endpoint: "dashboard/getClientSummaryValuation",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
        },
      });

      setClientSummary(res?.data);

      const initialClientValuation = {};
      res?.data?.data?.forEach((item) => {
        initialClientValuation[item._id] = {
          client_id: item._id || "",
          priorValuation: item?.priorValuation || "",
          selectMethodology: item?.selectMethodology || "",
          commentDiscussion: item?.commentDiscussion || "",
          nextSteps: item?.nextSteps || "",
        };
      });

      setClientValuation(initialClientValuation);

      getValuationByDate(initialClientValuation);
    } catch (err) {
      console.log("err", err);
    }
  };

  const getValuationByDate = async (clientVal) => {
    try {
      const res = await getData({
        endpoint: "valuation/valuationGetByDate",
        token: cookies.t,
        params: {
          selected_date: date,
        },
      });
      if (res) {
        const tempdata = {
          credit_spreads: res?.data?.credit_spreads,
          interest_rates: res?.data?.interest_rates,
          macro: res?.data?.macro,
        };
        setValuationData(tempdata);
        setClientValuationAdd(res?.data);

        if (
          res?.data?.client_info &&
          typeof res.data.client_info === "object" &&
          !Array.isArray(res.data.client_info)
        ) {
          setClientValuation(() => {
            const updatedObject = { ...clientVal }; // Use clientVal here instead of prevState

            const clientInfo = res?.data?.client_info; // client_info is an object

            // Iterate over the keys of client_info (which is an object)
            Object.keys(clientInfo).forEach((client_id) => {
              const { client_id: id, ...rest } = clientInfo[client_id]; // Get the data for this client

              // Check if the client_id exists in updatedObject
              if (updatedObject[client_id]) {
                updatedObject[client_id] = {
                  ...updatedObject[client_id], // Spread the existing data
                  ...rest, // Merge the new data
                };
              } else {
                console.warn(`client_id ${client_id} not found in the state`);
              }
            });

            return updatedObject; // Return the updated object to update the state
          });
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  //   useEffect(() => {
  //     if (date) {
  //       getValuationByDate();
  //     }
  //   }, [date]);

  //   useEffect(() => {
  //     if (clientSummary?.length) {
  //       setClientValuation(createDocRecords(clientSummary?.length));
  //     } else {
  //       setClientValuation(createDocRecords(1));
  //     }
  //   }, [clientValuationAdd]);

  useEffect(() => {
    getClientData();
  }, [itemsPerPage, currentPage]);

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        <NoContentCard
          title="No Content Found"
          subtitle=""
          type="See Dues"
          classes="d-flex align-items-center"
        />
      </>
    );
  });
  const columns = [
    {
      field: "client_name",
      headerName: "Client",
      flex: 0.8,
    },
    {
      field: "principal_remaining",
      headerName: "Principal Remaining",
      flex: 0.8,
      valueGetter: (params) => {
        return params?.data?.totalFaceValue &&
          params?.data?.totalPaymentAmountRoot
          ? Number(
              params?.data?.totalFaceValue -
                params?.data?.totalPaymentAmountRoot,
            )
          : "";
      },
      cellRenderer: (params) => {
        return (
          <div>
            {formatNumberInternational(
              params?.data?.totalFaceValue -
                params?.data?.totalPaymentAmountRoot,
            )}
          </div>
        );
      },
    },

    {
      field: "interest_accured",
      headerName: "Intrest Accrued",
      flex: 0.8,
      valueGetter: (params) => {
        return params?.data?.totalInterest
          ? Number(params?.data?.totalInterest)
          : "";
      },
      cellRenderer: (params) => {
        return (
          <div>{formatNumberInternational(params?.data?.totalInterest)}</div>
        );
      },
    },
    {
      field: "amount_to_close",
      headerName: "Amount to Close",
      flex: 0.8,
      valueGetter: (params) => {
        return Number(
          params?.data?.totalFaceValue -
            params?.data?.totalPaymentAmountRoot +
            params?.data?.totalInterest,
        );
      },
      cellRenderer: (params) => {
        return (
          <div>
            {formatNumberInternational(
              params?.data?.totalFaceValue -
                params?.data?.totalPaymentAmountRoot +
                params?.data?.totalInterest,
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    console.log("clientValuation", clientValuation);
  }, [clientValuation]);

  const handleDownload = (s3Link) => {
    const link = document.createElement("a");
    link.href = s3Link; // S3 bucket document link
    link.download = ""; // Specify a default filename if needed
    link.target = "_blank"; // Open in a new tab if necessary
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up
  };

  const handlePrintReport = async () => {
    if (clientValuationAdd?._id) {
      try {
        setDownloadLoader(true);
        const res = await patchData({
          endpoint: "valuation/updateValuation",
          params: {
            id: clientValuationAdd?._id,
          },
          token: cookies.t,
          data: {
            selected_date: date,
            macro: valuationData?.macro,
            interest_rates: valuationData?.interest_rates,
            credit_spreads: valuationData?.credit_spreads,
            client_info: clientValuation,
            currencyConversion: currencyConversion,
          },
        });
        if (res) {
          toast.success("Valuation update Successfully", {
            duration: 1000,
            style: confimationStyles,
          });
          setDownloadLoader(false);
          handleDownload(res?.data);
        }
      } catch (err) {
        setDownloadLoader(false);
        console.log("err", err);
      }
    } else {
      try {
        setDownloadLoader(true);
        const res = await postData({
          endpoint: "valuation/createValuation",
          token: cookies.t,
          data: {
            selected_date: date,
            macro: valuationData?.macro,
            interest_rates: valuationData?.interest_rates,
            credit_spreads: valuationData?.credit_spreads,
            client_info: clientValuation,
            currencyConversion: currencyConversion,
          },
        });
        if (res) {
          setDownloadLoader(false);
          toast.success("Valuation print Successfully", {
            duration: 1000,
            style: confimationStyles,
          });

          handleDownload(res?.data?.data);
        }
      } catch (err) {
        setDownloadLoader(false);
        console.log("err", err);
      }
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <Breadcrumb
          labels={[
            "Valuation Monitoring",
            `Credit Portfolio Valuation Comittee Report`,
          ]}
          stepsToRemove={0}
        />

        <button
          className="btn btn-primary btn-sm py-1 px-4"
          onClick={() => {
            handlePrintReport();
          }}
        >
          Print The Report
          {downloadLoader && (
            <span
              className="spinner-border spinner-border-sm ms-2 text-light"
              role="status"
              aria-hidden="true"
            ></span>
          )}
        </button>
      </div>

      <Heading className="addHeaderStyle">
        Credit Portfolio Valuation Comittee Report
      </Heading>
      <Heading className="HeadingSubStyle">
        As of Date: {moment.utc(date).format("YYYY-MM-DD")}
      </Heading>
      <Heading className="HeadingSubStyle">
        Report Date: {moment(new Date()).format("YYYY-MM-DD")}
      </Heading>

      <div className="policy-content">
        <Heading className="HeadingStyle">Valuation Policy</Heading>

        <div>
          <Heading className="HeadingSubStyle">Background</Heading>
          An adviser's performance and fee calculations are dependent upon the
          prices assigned to assets held in client accounts. Advisory clients
          need to know the fair value of their holdings, and they can be harmed
          if the adviser overcharges its advisory fee based on overvalued
          holdings. Securities that are frequently traded on public exchanges,
          such as large cap domestic equities, are relatively easy to price.
          However, the valuation of assets for which there is no readily
          available pricing information is a highly judgmental process. An
          adviser should adopt and implement policies and procedures that are
          reasonably designed to price investments in a manner that is fair,
          accurate, and consistent with any disclosures. Particular attention
          should be paid when pricing structured products, illiquid securities
          or other difficult-to-price securities.
          <br />
          <p></p>
          <Heading className="HeadingSubStyle">Policies and Procedures</Heading>
          Assets with respect to which market quotations are readily available
          shall be valued at current market value. Other assets shall be valued
          at fair value. The Private Fund's portfolio investments will be valued
          in accordance with the terms agreed to with Investors in the Private
          Fund.
          <br />
          <p></p>
          <Heading className="HeadingSubStyle">
            Fair Valuation of Non-Marketable Assets
          </Heading>
          The valuation of non-marketable investments is a highly judgmental
          process which cannot be reduced to a simple formula. Such valuations
          are determined based upon factors deemed most relevant and appropriate
          by Highmore Group. These factors include, but are not limited to:
          market conditions, purchase price, estimated liquidation value,
          meaningful third-party transactions in the private market and/or third
          party assessments. "Fair value" is generally defined as the price that
          would be received in the sale of an asset or paid to transfer a
          liability in an orderly transaction between market participants under
          current market conditions. The objective is to estimate the exchange
          price at which hypothetical willing marketplace participants would
          agree to transact. Accordingly, while the cost (or "entry price")
          associated with a non-marketable asset held by a Fund may be a
          consideration when assessing the asset's fair value, the purpose of
          the assessment is to determine a fair sale (or "exit") price. The
          Financial Accounting Standard Board's Accounting Standards
          Codification ("ASC") 820-10 provides guidance regarding appropriate
          valuation methodologies for fair valuing assets. ASC 820-10 recommends
          the following levels of inputs that should be considered in descending
          order of importance. Fund assets are assigned a level based upon the
          inputs which are significant to the overall valuation.
          <br />
          <p></p>
          <strong> Level 1: </strong>quoted prices in active markets for
          identical assets (e.g., equity securities, open-end investment
          companies, futures contracts, options contracts);
          <br /> <p></p> <strong>Level 2: </strong> other observable inputs,
          including, but not limited to: quoted prices for similar assetsor
          liabilities in markets that are active, quoted prices for identical or
          similar assets or liabilities in markets that are not active, inputs
          other than quoted prices that are observable for the assets or
          liabilities (such as interest rates, yield curves, volatilities,
          prepayment speeds, loss severities, credit risks and default rates) or
          other market-corroborated inputs (e.g., debt securities, government
          securities, swap contracts, foreign currency exchange contracts,
          foreign securities utilizing international fair value pricing); <br />{" "}
          <p></p>
          <strong>Level 3: </strong> unobservable inputs (including assumptions
          used to determine the fair value of investments) (e.g., broker-quoted
          securities, fair valued securities). Unobservable inputs reflect
          Highmore Group's own assumptions about the assumptions that market
          participants would use in pricing the asset or liability developed
          based on the best information available under the circumstances.
          <p></p>
          No matter which valuation method is used, the fair value of an asset
          should be the price at which it could be acquired or sold in a current
          transaction between willing parties in which the parties each acted
          knowledgeably, prudently, and without compulsion. Fair value should
          not be based on what can be obtained from an immediate "fire sale"
          disposition, nor on what a buyer might pay at some later time or under
          more favorable circumstances.
          <p></p>
          <Heading className="HeadingSubStyle">
            Private Fund Organizational and Offering Documents
          </Heading>
          Valuation determinations will be made, in all cases, in strict
          accordance with pertinent provisions (including provisions regarding
          appraisals of fair value by independent valuation service providers)
          included in Fund organizational and offering documents. Other than as
          provided for by the Fund's organizational and offering documents,
          Highmore Group will monitor the appropriateness of its fair value
          methodologies and periodically determine if any adjustments should be
          made to such methodologies based on the underlying assets.
          <p></p>
          <Heading className="HeadingSubStyle">
            Valuation Process for Non-Marketable Assets
          </Heading>
          Highmore Group's policy is to ensure that all portfolio investments
          are recorded at fair value on a consistent, transparent and reasonable
          basis. The Operating Officer is responsible for overseeing and
          approving all assessments of fair value of the Private Fund's
          portfolio investments in accordance with this Policy. Valuations will
          be conducted dependent on the investment type. However, quarterly
          investments reviews consider the price of existing investments in the
          manner provided in the Private Fund's operating agreement.
          <p></p>Highmore Group may engage independent third parties to provide
          an assessment or appraisal of the market value of certain portfolio
          holdings on the basis of a willing buyer and seller. Use of
          independent third party valuation services may be governed by the
          partnership agreements. The Operating Officer will choose the
          appropriate valuation technique based on the facts and circumstances
          of each particular investment. If a valuation issue arises that is not
          covered by this Policy, the Operating Officer shall use appropriate
          means to obtain relevant information in order to determine a fair
          value and may consult with investment professionals, accountants,
          auditors or other experts as appropriate.<p></p>
          <Heading className="HeadingSubStyle">
            Valuation Inputs for Non-marketable Direct Loans
          </Heading>{" "}
          Highmore shall consider the values for non-marketable direct loans as
          provided by the custodian, administrator, or other relevant third
          party. However, on a quarterly basis, Highmore's Valuation Committee
          shall review various inputs to ensure that the loans are fair valued
          for consistency and reasonableness. In determining the fair value of
          loans, the following inputs are considered:
          <p></p>
          <strong> • </strong>Cost at acquisition
          <p></p>
          <strong> • </strong> Current Interest Rates Compared to the rates at
          the acquisition date
          <p></p>
          <strong> • </strong> If current interest rates have materially fallen
          since the loan was acquired the value may need to be increased, and
          <p></p>
          <strong> • </strong>If current interest rates have materially
          increased since the loan was acquired the value may need to be
          decreased
          <p></p>
          <strong> • </strong> Changes in Foreign Currency compared to
          acquisition date, if applicable (foreign loan) have materially changed
          <p></p>
          <strong> • </strong> Payment risk <p></p>
          <strong> • </strong>The loan is impaired,
          <p></p>
          <strong> • </strong> The loan is in default or at risk of default,
          <p></p>
          <strong> • </strong> Other loans to the same party are impaired, late,
          or in default, or
          <p></p>
          <strong> • </strong> Payments have been missed
          <p></p>
          <strong> • </strong> Collateral coverage has lost value in excess of
          the loan amount
          <p></p>
          <strong> • </strong>Guarantees or other credit enhancements are in
          doubt or compromised in some way (e.g.,a guarantor's financial
          situation is in doubt)<p></p> • Information regarding the credit
          ratings, if any, have materially changed <p></p>
          <strong> • </strong> Political Risk puts the loan at risk
          <p></p> <strong> • </strong> Prepayments shorten the duration of the
          loan effecting the return on the loan. The Valuation Committee shall
          document whether the valuations used for the loans are appropriate or
          if they need to be adjusted given the inputs identified above.
        </div>
      </div>

      <div className="discussion mt-4">
        <Heading className="HeadingStyle">
          Macro And Interest Rate Discussion
        </Heading>
        <div className="mt-4">
          <label htmlFor="" className="valueStyle">
            Macro :{" "}
          </label>

          {/* <QuillEditor
            // label="Questions and Answers"
            placeholderText="Macro : "
            onChange={(content) => {
              setValuationData((prev) => ({
                ...prev,
                macro: content,
              }));
            }}
            value={valuationData?.macro}
            type="faq"
            height="230px"
            hp="80%"
            classes="fixed-position-styles"
            // data={priorData?.details?.QA}
            mode={"Edit"}
            // dataHeading={`${priorData.client_name}-${moment(
            //   priorData.note_date,
            // ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
          /> */}
          <CustomTextArea
            customholder="Macro"
            onchange={(content) => {
              setValuationData((prev) => ({
                ...prev,
                macro: content.target.value,
              }));
            }}
            width="w-100"
            textareaStyles={{ minHeight: "230px" }}
            value={valuationData?.macro}
          />
        </div>{" "}
        <div className="mt-4">
          <label htmlFor="" className="valueStyle">
            Interest Rates :{" "}
          </label>

          {/*<QuillEditor
            // label="Questions and Answers"
            placeholderText="Interest Rates :"
            onChange={(content) => {
              setValuationData((prev) => ({
                ...prev,
                interest_rates: content,
              }));
            }}
            value={valuationData?.interest_rates}
            type="faq"
            height="230px"
            hp="80%"
            classes="fixed-position-styles"
            // data={priorData?.details?.QA}
            mode={"Edit"}
            // dataHeading={`${priorData.client_name}-${moment(
            //   priorData.note_date,
            // ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
          /> */}
          <CustomTextArea
            customholder="Interest Rates"
            onchange={(content) => {
              setValuationData((prev) => ({
                ...prev,
                interest_rates: content.target.value,
              }));
            }}
            width="w-100"
            textareaStyles={{ minHeight: "230px" }}
            value={valuationData?.interest_rates}
          />
        </div>{" "}
        <div className="mt-4">
          <label htmlFor="" className="valueStyle">
            Credit Spreads and Default Rates :{" "}
          </label>
          {/* <QuillEditor
            // label="Questions and Answers"
            placeholderText="Credit Spreads and Default Rates :"
            onChange={(content) => {
              setValuationData((prev) => ({
                ...prev,
                credit_spreads: content,
              }));
            }}
            value={valuationData?.credit_spreads}
            type="faq"
            height="230px"
            hp="80%"
            classes="fixed-position-styles"
            // data={priorData?.details?.QA}
            mode={"Edit"}
            // dataHeading={`${priorData.client_name}-${moment(
            //   priorData.note_date,
            // ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
          /> */}
          <CustomTextArea
            customholder="Credit Spreads and Default Rate"
            onchange={(content) => {
              setValuationData((prev) => ({
                ...prev,
                credit_spreads: content.target.value,
              }));
            }}
            width="w-100"
            textareaStyles={{ minHeight: "230px" }}
            value={valuationData?.credit_spreads}
          />
        </div>
      </div>

      <div className="client-summary mt-3 mb-3">
        <Heading className="HeadingStyle">Client Summary</Heading>
        <CustomDatagrid
          rows={clientSummary?.data || []}
          getRowId={(row) => row?._id}
          columns={columns}
          height={() => 50}
          hoverColor="ffffff"
          CustomNoRowsOverlay={CustomNoRowsOverlay}
        />
        {/* <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={clientSummary?.totalCount}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
          options={["5", "10", "15"]}
        /> */}
      </div>
      <div>
        {clientSummary?.data?.map((item, index) => {
          return (
            <>
              <div key={item?._id}>
                <Heading className="HeadingStyle">{item?.client_name}</Heading>
                <table class="table">
                  <thead>
                    <tr>
                      <th
                        style={{
                          borderLeft: "1px solid #DEE3E7",
                          borderTop: "1px solid #DEE3E7",
                          borderRight: "1px solid #DEE3E7",
                          padding: "8px",
                        }}
                        scope="col-2"
                        colSpan="2"
                      >
                        Counterparty Information
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          border: "1px solid #DEE3E7",
                          padding: "8px",
                          width: "30%",
                        }}
                      >
                        Name:
                      </td>
                      <td
                        style={{
                          border: "1px solid #DEE3E7",
                          padding: "8px",
                        }}
                      >
                        {item?.client_name ? item?.client_name : "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        c
                        style={{
                          border: "1px solid #DEE3E7",
                          padding: "8px",
                        }}
                      >
                        Address:
                      </td>
                      <td
                        style={{
                          border: "1px solid #DEE3E7",
                          padding: "8px",
                        }}
                      >
                        {item?.address ? item?.address : "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          border: "1px solid #DEE3E7",
                          padding: "8px",
                        }}
                      >
                        TaxID:
                      </td>
                      <td
                        style={{
                          border: "1px solid #DEE3E7",
                          padding: "8px",
                        }}
                      >
                        {item?.tax_id ? item?.tax_id : "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          border: "1px solid #DEE3E7",
                          padding: "8px",
                        }}
                      >
                        Type:
                      </td>
                      <td
                        style={{
                          border: "1px solid #DEE3E7",
                          padding: "8px",
                        }}
                      >
                        {item?.counter_type}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table class="table">
                  <thead>
                    <tr>
                      <th
                        style={{
                          borderLeft: "1px solid #DEE3E7",
                          borderTop: "1px solid #DEE3E7",
                          borderRight: "1px solid #DEE3E7",
                          padding: "8px",
                        }}
                        scope="col-2"
                        colSpan="2"
                      >
                        Credit Information
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          border: "1px solid #DEE3E7",
                          padding: "8px",
                          width: "30%",
                        }}
                      >
                        Counterparty Type:
                      </td>
                      <td
                        style={{
                          border: "1px solid #DEE3E7",
                          padding: "8px",
                        }}
                      >
                        {item?.client_name ? item?.counter_type : "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        c
                        style={{
                          border: "1px solid #DEE3E7",
                          padding: "8px",
                        }}
                      >
                        Internal Credit Limit:
                      </td>
                      <td
                        style={{
                          border: "1px solid #DEE3E7",
                          padding: "8px",
                        }}
                      >
                        {item?.client_internal_limit
                          ? formatNumberInternational(
                              item?.client_internal_limit,
                            )
                          : "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          border: "1px solid #DEE3E7",
                          padding: "8px",
                        }}
                      >
                        Credit used:
                      </td>
                      <td
                        style={{
                          border: "1px solid #DEE3E7",
                          padding: "8px",
                        }}
                      >
                        {formatNumberInternational(
                          item?.totalFaceValue,
                          // parseFloat(
                          //   convertAndAddCurrencies(
                          //     item?.costSummary,
                          //     currencyConversion,
                          //     "number",
                          //   ),
                          // ),
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          border: "1px solid #DEE3E7",
                          padding: "8px",
                        }}
                      >
                        Credit Remaining:
                      </td>
                      <td
                        style={{
                          border: "1px solid #DEE3E7",
                          padding: "8px",
                        }}
                      >
                        {formatNumberInternational(
                          parseFloat(
                            item.client_internal_limit - item?.totalFaceValue,
                            // convertAndAddCurrencies(
                            //   item?.costSummary,
                            //   currencyConversion,
                            //   "number",
                            // ),
                          ),
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          border: "1px solid #DEE3E7",
                          padding: "8px",
                        }}
                      >
                        Sector:
                      </td>
                      <td
                        style={{
                          border: "1px solid #DEE3E7",
                          padding: "8px",
                        }}
                      >
                        {item?.gics_sector
                          ? item?.gics_sector
                          : item?.gics_sector}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          border: "1px solid #DEE3E7",
                          padding: "8px",
                        }}
                      >
                        Industry:
                      </td>
                      <td
                        style={{
                          border: "1px solid #DEE3E7",
                          padding: "8px",
                        }}
                      >
                        {item?.gics_industry
                          ? item?.gics_industry
                          : item?.gics_industry}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          border: "1px solid #DEE3E7",
                          padding: "8px",
                        }}
                      >
                        Geography:
                      </td>
                      <td
                        style={{
                          border: "1px solid #DEE3E7",
                          padding: "8px",
                        }}
                      >
                        {item?.geography ? item?.geography : item?.geography}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          border: "1px solid #DEE3E7",
                          padding: "8px",
                        }}
                      >
                        Currency:
                      </td>
                      <td
                        style={{
                          border: "1px solid #DEE3E7",
                          padding: "8px",
                        }}
                      >
                        {item?.primary_currency
                          ? item?.primary_currency
                          : item?.primary_currency}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          border: "1px solid #DEE3E7",
                          padding: "8px",
                        }}
                      >
                        Last Payment Date/Amount:
                      </td>
                      <td
                        style={{
                          border: "1px solid #DEE3E7",
                          padding: "8px",
                        }}
                      >
                        ---
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Heading className="HeadingSubStyle">Covenant</Heading>
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      <th
                        style={{ border: "1px solid #DEE3E7", padding: "8px" }}
                      >
                        Covenant
                      </th>
                      <th
                        style={{ border: "1px solid #DEE3E7", padding: "8px" }}
                      >
                        Covenant (Detail)
                      </th>
                      <th
                        style={{ border: "1px solid #DEE3E7", padding: "8px" }}
                      >
                        Frequency
                      </th>
                      <th
                        style={{ border: "1px solid #DEE3E7", padding: "8px" }}
                      >
                        Shift
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {item?.covenants?.length > 0 ? (
                      <>
                        {item?.covenants?.map((item, index) => {
                          return (
                            <>
                              <tr key={item?._id}>
                                <td
                                  style={{
                                    border: "1px solid #DEE3E7",
                                    padding: "8px",
                                  }}
                                >
                                  {item.name}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #DEE3E7",
                                    padding: "8px",
                                  }}
                                >
                                  {item.long_description}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #DEE3E7",
                                    padding: "8px",
                                  }}
                                >
                                  {item.frequency}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #DEE3E7",
                                    padding: "8px",
                                  }}
                                >
                                  {item.monthly_shift}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <tr>
                        <td
                          colSpan={4}
                          style={{
                            border: "1px solid #DEE3E7",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          No data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <Heading className="HeadingSubStyle mt-3">
                  Reporting Requirement
                </Heading>
                <table
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    marginTop: "10px",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{ border: "1px solid #DEE3E7", padding: "8px" }}
                      >
                        Requirement
                      </th>
                      <th
                        style={{ border: "1px solid #DEE3E7", padding: "8px" }}
                      >
                        Requirement (Detail)
                      </th>
                      <th
                        style={{ border: "1px solid #DEE3E7", padding: "8px" }}
                      >
                        Frequency
                      </th>
                      <th
                        style={{ border: "1px solid #DEE3E7", padding: "8px" }}
                      >
                        Shift
                      </th>
                      <th
                        style={{ border: "1px solid #DEE3E7", padding: "8px" }}
                      >
                        Reporting Days
                      </th>
                      <th
                        style={{ border: "1px solid #DEE3E7", padding: "8px" }}
                      >
                        Next Report As of Date
                      </th>
                      <th
                        style={{ border: "1px solid #DEE3E7", padding: "8px" }}
                      >
                        Next Due Date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {item?.reportingRequirementsData?.length > 0 ? (
                      item?.reportingRequirementsData?.map((item, index) => (
                        <tr key={item?._id}>
                          <td
                            style={{
                              border: "1px solid #DEE3E7",
                              padding: "8px",
                              minWidth: "auto",
                            }}
                          >
                            {item.name}
                          </td>
                          <td
                            style={{
                              border: "1px solid #DEE3E7",
                              padding: "8px",
                              minWidth: "auto",
                            }}
                          >
                            {item.long_description}
                          </td>
                          <td
                            style={{
                              border: "1px solid #DEE3E7",
                              padding: "8px",
                              minWidth: "auto",
                            }}
                          >
                            {item.frequency}
                          </td>
                          <td
                            style={{
                              border: "1px solid #DEE3E7",
                              padding: "8px",
                              minWidth: "auto",
                            }}
                          >
                            {item.monthly_shift}
                          </td>
                          <td
                            style={{
                              border: "1px solid #DEE3E7",
                              padding: "8px",
                              minWidth: "auto",
                            }}
                          >
                            {item.reporting_days}
                          </td>
                          <td
                            style={{
                              border: "1px solid #DEE3E7",
                              padding: "8px",
                              minWidth: "auto",
                            }}
                          >
                            {moment(item.next_reporting_date).format(
                              "Do MMM, YYYY",
                            )}
                          </td>
                          <td
                            style={{
                              border: "1px solid #DEE3E7",
                              padding: "8px",
                              minWidth: "auto",
                            }}
                          >
                            {moment(item.next_due_date).format("Do MMM, YYYY")}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan={7} // Adjust to the total number of columns
                          style={{
                            border: "1px solid #DEE3E7",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          No data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div>
                  <Heading className="HeadingSubStyle mt-3">
                    Obligor Assets Due
                  </Heading>
                  <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead>
                      <tr>
                        <th
                          style={{
                            border: "1px solid #DEE3E7",
                            padding: "8px",
                          }}
                        >
                          Client
                        </th>
                        <th
                          style={{
                            border: "1px solid #DEE3E7",
                            padding: "8px",
                          }}
                        >
                          Obligor
                        </th>
                        <th
                          style={{
                            border: "1px solid #DEE3E7",
                            padding: "8px",
                          }}
                        >
                          Due Date
                        </th>
                        <th
                          style={{
                            border: "1px solid #DEE3E7",
                            padding: "8px",
                          }}
                        >
                          Client Ref
                        </th>
                        <th
                          style={{
                            border: "1px solid #DEE3E7",
                            padding: "8px",
                          }}
                        >
                          Status
                        </th>
                        <th
                          style={{
                            border: "1px solid #DEE3E7",
                            padding: "8px",
                          }}
                        >
                          Currency
                        </th>
                        <th
                          style={{
                            border: "1px solid #DEE3E7",
                            padding: "8px",
                          }}
                        >
                          Cost Value
                        </th>
                        <th
                          style={{
                            border: "1px solid #DEE3E7",
                            padding: "8px",
                          }}
                        >
                          Face Value
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {item?.obligorInfo?.length > 0 ? (
                        <>
                          {item?.obligorInfo?.map((item, index) => {
                            return (
                              <>
                                <tr key={item?._id}>
                                  <td
                                    style={{
                                      border: "1px solid #DEE3E7",
                                      padding: "8px",
                                    }}
                                  >
                                    {item.client_name}
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid #DEE3E7",
                                      padding: "8px",
                                    }}
                                  >
                                    {item.obligor_name
                                      ? item.obligor_name
                                      : item?.client_name}
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid #DEE3E7",
                                      padding: "8px",
                                    }}
                                  >
                                    {moment(item.due_date).format("YYYY-MM-DD")}
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid #DEE3E7",
                                      padding: "8px",
                                    }}
                                  >
                                    {item.invoice_number || item?.referance}
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid #DEE3E7",
                                      padding: "8px",
                                    }}
                                  >
                                    {item.status === "Funded"
                                      ? "Open"
                                      : item.status}
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid #DEE3E7",
                                      padding: "8px",
                                    }}
                                  >
                                    {item.currency}
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid #DEE3E7",
                                      padding: "8px",
                                    }}
                                  >
                                    {formatNumberInternational(item.cost_value)}
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid #DEE3E7",
                                      padding: "8px",
                                    }}
                                  >
                                    {formatNumberInternational(item.face_value)}
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <tr>
                          <td
                            colSpan={7}
                            style={{
                              border: "1px solid #DEE3E7",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="mt-3 mb-3">
                <div className="mt-4">
                  {/* <label htmlFor="" className="valueStyle">
                    Prior valuation :{" "}
                  </label>
                  <QuillEditor
                    placeholderText="Prior valuation :"
                    onChange={(content) => {
                      let updatedValuation = { ...clientValuation };
                      // Use item._id to find the matching client_id in the object
                      if (updatedValuation[item?._id]) {
                        updatedValuation[item?._id].priorValuation = content;
                        setClientValuation(updatedValuation);
                      }
                    }}
                    value={clientValuation[item?._id]?.priorValuation || ""}
                    type="faq"
                    height="230px"
                    hp="80%"
                    classes="fixed-position-styles"
                    mode="Edit"
                  /> */}
                  <CustomTextArea
                    customholder="Prior valuation"
                    onchange={(content) => {
                      let updatedValuation = { ...clientValuation };
                      // Use item._id to find the matching client_id in the object
                      if (updatedValuation[item?._id]) {
                        updatedValuation[item?._id].priorValuation =
                          content.target.value;
                        setClientValuation(updatedValuation);
                      }
                    }}
                    width="w-100"
                    textareaStyles={{ minHeight: "230px" }}
                    value={clientValuation[item?._id]?.priorValuation || ""}
                  />
                </div>

                <div className="mt-4">
                  {/* <label htmlFor="" className="valueStyle">
                    Selected methodology, Assumptions :{" "}
                  </label>
                  <QuillEditor
                    placeholderText="Selected methodology, Assumptions :"
                    onChange={(content) => {
                      let updatedValuation = { ...clientValuation };
                      if (updatedValuation[item?._id]) {
                        updatedValuation[item?._id].selectMethodology = content;
                        setClientValuation(updatedValuation);
                      }
                    }}
                    value={clientValuation[item?._id]?.selectMethodology || ""}
                    type="faq"
                    height="230px"
                    hp="80%"
                    classes="fixed-position-styles"
                    mode="Edit"
                  /> */}
                  <CustomTextArea
                    customholder="Selected methodology, Assumptions"
                    onchange={(content) => {
                      let updatedValuation = { ...clientValuation };
                      if (updatedValuation[item?._id]) {
                        updatedValuation[item?._id].selectMethodology =
                          content.target.value;
                        setClientValuation(updatedValuation);
                      }
                    }}
                    width="w-100"
                    textareaStyles={{ minHeight: "230px" }}
                    value={clientValuation[item?._id]?.selectMethodology || ""}
                  />
                </div>

                <div className="mt-4">
                  {/* <label htmlFor="" className="valueStyle">
                    Comment, Discussion and Notable Developments :{" "}
                  </label>
                  <QuillEditor
                    placeholderText="Comment, Discussion and Notable Developments :"
                    onChange={(content) => {
                      let updatedValuation = { ...clientValuation };
                      if (updatedValuation[item?._id]) {
                        updatedValuation[item?._id].commentDiscussion = content;
                        setClientValuation(updatedValuation);
                      }
                    }}
                    value={clientValuation[item?._id]?.commentDiscussion || ""}
                    type="faq"
                    height="230px"
                    hp="80%"
                    classes="fixed-position-styles"
                    mode="Edit"
                  /> */}
                  <CustomTextArea
                    customholder="Comment, Discussion and Notable Developments"
                    onchange={(content) => {
                      let updatedValuation = { ...clientValuation };
                      if (updatedValuation[item?._id]) {
                        updatedValuation[item?._id].commentDiscussion =
                          content.target.value;
                        setClientValuation(updatedValuation);
                      }
                    }}
                    width="w-100"
                    textareaStyles={{ minHeight: "230px" }}
                    value={clientValuation[item?._id]?.commentDiscussion || ""}
                  />
                </div>

                <div>
                  <label htmlFor="" className="valueStyle">
                    Next steps and conclusions :{" "}
                  </label>
                  <QuillEditor
                    placeholderText="Next steps and conclusions:"
                    onChange={(content) => {
                      let updatedValuation = { ...clientValuation };
                      if (updatedValuation[item?._id]) {
                        updatedValuation[item?._id].nextSteps = content;
                        setClientValuation(updatedValuation);
                      }
                    }}
                    value={clientValuation[item?._id]?.nextSteps || ""}
                    // data={clientValuation[item?._id]?.nextSteps || ""}
                    type="faq"
                    height="230px"
                    hp="80%"
                    classes="fixed-position-styles"
                    mode="Edit"
                  />
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default Valuation;
