import React, { useState, useEffect, memo } from "react";
import { useCookies } from "react-cookie";
import { getData } from "../../../../../../../services";
import CustomDatagrid from "../../../../../../molecules/CustomDatagrid";
import NoContentCard from "../../../../../../atoms/NoContentCard";
import Download from "../../../../../../icons/Downlaod";
import moment from "moment";

const fileFormattedData = {
  financialAnalysis: "Financial Analysis",
  capIQRiskGauge: "CapIQ Risk Gauge",
  bankStatement: "Bank Statement Analysis",
  investmentMemo: "Investment Memorandum",
  summaryInvestmentMemo: "Summary Investment Memorandum",
};

const Table = ({ id }) => {
  const [cookies] = useCookies(["t"]);
  const [data, setData] = useState(null);

  const handleDownload = (link) => {
    // Create a temporary `a` element
    const anchor = document.createElement("a");
    anchor.href = link;

    // Set the `download` attribute to suggest the file name
    const fileName = link.split("/").pop().split("?")[0];
    anchor.download = decodeURIComponent(fileName); // Decode any encoded parts like %20 to space

    // Append to the body and trigger the click
    document.body.appendChild(anchor);
    anchor.click();

    // Clean up
    document.body.removeChild(anchor);
  };

  const removeTimestamp = (name) => {
    return name?.replace(/^\d+-/, ""); // Regex to remove numbers followed by '-'
  };

  const columns = [
    {
      field: "selected_date",
      headerName: "Date",
      cellRenderer: (params) => {
        console.log("params", params?.data);
        return (
          <div>{moment(params?.data?.selected_date).format("YYYY-MM-DD")}</div>
        );
      },
      flex: 1,
    },
    {
      field: "documentType",
      headerName: "Document Type",
      cellRenderer: (params) => {
        return <div>{fileFormattedData?.[params?.data?.documentType]}</div>;
      },
      flex: 1,
    },
    {
      field: "originalname",
      headerName: "File Name",
      flex: 2,
      cellRenderer: (params) => {
        return (
          <div title={removeTimestamp(params?.data?.originalname)}>
            {params?.data?.originalname
              ? removeTimestamp(params?.data?.originalname)
              : ""}
          </div>
        );
      },
    },

    {
      field: "actions",
      headerName: "File Download",
      flex: 0.4,
      cellRenderer: (params, index) => {
        return (
          <>
            <div title="Download File">
              <span
                type="button"
                // onClick={(event) => handleStatusMenu(event, params?.data)}
                onClick={() => handleDownload(params?.data?.s3UploadedLink)}
              >
                <Download />
              </span>
            </div>
          </>
        );
      },
    },
  ];

  const getClientFinancialData = async () => {
    try {
      const res = await getData({
        endpoint: "analysis/getAllClientFinancialData",
        token: cookies.t,
        params: {
          id: id,
        },
      });

      if (res?.data) {
        setData(res?.data);
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  console.log("data", data);

  useEffect(() => {
    if (id) {
      getClientFinancialData();
    }
  }, []);

  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        <NoContentCard
          title={"No Analysis Data"}
          // subtitle={content[type].subtitle}
          type="Pricing"
          classes="d-flex align-items-center"
        />
      </>
    );
  });
  return (
    <div>
      <CustomDatagrid
        rows={data}
        getRowId={(row) => Math.random()}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        CustomNoRowsOverlay={CustomNoRowsOverlay}
        // checkRequired
        // setSelectedRowIds={setSelectedRowIds}
      />
    </div>
  );
};

export default Table;
