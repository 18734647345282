import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import CustomButton from "../../../atoms/CustomButton";
import { Heading } from "../../../atoms/Heading";
import Search from "../../../atoms/Search";
import style from "./index.module.scss";
import { useMemo, useState } from "react";
import TabStrip from "../../../atoms/tabstrip";
import { Box } from "@mui/material";
import Table from "./Table";
import { useCookies } from "react-cookie";
import { getData } from "../../../../services";
import Modal from "../../../atoms/modals/Modal";
import AdvReqModal from "./viewFunding/advReqModal/index.jsx";
import SubmittedModal from "./viewFunding/submittedModal/index.jsx";
import ApprovedModal from "./viewFunding/approved/index.jsx";
import FilterComponent from "../../../molecules/filters/FilterComponent.jsx";
import { CurrencyData, TransactionType } from "../../../../utils/staticData.js";
import AllocatedModal from "./viewFunding/allocatedModal/index.jsx";
import { useSelector } from "react-redux";

const FundingManagement = () => {
  const [cookies] = useCookies(["t", "roleGroup"]);
  const navigate = useNavigate();
  const accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.Funding;
  const location = useLocation();
  const [searchData, setSearchData] = useState();
  const [activeTab, setActiveTab] = useState("Draft");
  const [statusData, setStatusData] = useState();
  const [modalStateAdvReq, setModalStateAdvReq] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false,
  });
  const [modalStateSubmitted, setModalStateSubmitted] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false,
  });
  const [modalStateApproved, setModalStateApproved] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false,
  });
  const [modalStateAllocated, setModalStateAllocated] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false, // Set initial state to false
  });
  const [filters, setFilters] = useState({});
  const [clientListData, setClientListData] = useState([]);
  const [obligorListData, setObligorListData] = useState([]);
  const [investorData, setInvestorData] = useState([]);

  useEffect(() => {
    if (location?.state?.customState) {
      setActiveTab(location?.state?.customState);
    }
  }, [location?.state?.customState]);

  const handleOnClose = () => {
    setModalStateAdvReq(() => ({
      isModalOpen: false,
    }));
  };

  const handleOnCloseSubmitted = () => {
    setModalStateSubmitted(() => ({
      isModalOpen: false,
    }));
  };
  const handleOnCloseApproved = () => {
    setModalStateApproved(() => ({
      isModalOpen: false,
    }));
  };
  const handleOnCloseAllocated = () => {
    setModalStateAllocated(() => ({
      isModalOpen: false,
    }));
  };

  const getAllFundingStatus = async () => {
    const res = await getData({
      endpoint: "funding/getAllFundingStatus",
      token: cookies.t,
      params: searchData
        ? {
            search: searchData,
            group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
          }
        : {
            group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
          },
    });
    if (res) {
      setStatusData(res.data);
    }
  };

  useEffect(() => {
    getAllFundingStatus();
  }, []);

  const data = useMemo(
    roleData?.tabs
      ? () => [
          {
            id: 1,
            label: "Draft",
            view: (
              <Table
                type="Draft"
                getAllFundingStatus={getAllFundingStatus}
                roleData={roleData}
                searchData={searchData}
                filters={filters}
              />
            ),
            badge: statusData?.Draft || 0,
          },
          {
            id: 2,
            label: "Advance Request",
            view: (
              <Table
                type="Advance Request"
                setModalStateAdvReq={setModalStateAdvReq}
                getAllFundingStatus={getAllFundingStatus}
                roleData={roleData}
                modalStateAdvReq={modalStateAdvReq}
                searchData={searchData}
                filters={filters}
              />
            ),
            badge: statusData?.["Advance Request"] || 0,
          },
          {
            id: 3,
            label: "Submitted",
            view: (
              <Table
                type="Submitted"
                setModalStateSubmitted={setModalStateSubmitted}
                getAllFundingStatus={getAllFundingStatus}
                roleData={roleData}
                modalStateSubmitted={modalStateSubmitted}
                searchData={searchData}
                filters={filters}
              />
            ),
            badge: statusData?.Submitted || 0,
          },
          {
            id: 4,
            label: "Approved",
            view: (
              <Table
                type="Approved"
                setModalStateApproved={setModalStateApproved}
                getAllFundingStatus={getAllFundingStatus}
                roleData={roleData}
                modalStateApproved={modalStateApproved}
                searchData={searchData}
                filters={filters}
              />
            ),
            badge: statusData?.Approved || 0,
          },
          {
            id: 5,
            label: "Allocated",
            view: (
              <Table
                type="Allocated"
                getAllFundingStatus={getAllFundingStatus}
                setModalStateAllocated={setModalStateAllocated}
                roleData={roleData}
                modalStateAllocated={modalStateAllocated}
                searchData={searchData}
                filters={filters}
              />
            ),
            badge: statusData?.Allocated || 0,
          },
          {
            id: 6,
            label: "Funded",
            view: (
              <Table
                type="Funded"
                getAllFundingStatus={getAllFundingStatus}
                roleData={roleData}
                searchData={searchData}
                filters={filters}
              />
            ),
            badge: statusData?.Funded || 0,
          },
          {
            id: 7,
            label: "Closed",
            view: (
              <Table
                type="Closed"
                getAllFundingStatus={getAllFundingStatus}
                roleData={roleData}
                searchData={searchData}
                filters={filters}
              />
            ),
            badge: statusData?.Closed || 0,
          },
          {
            id: 8,
            label: "Rejected",
            view: (
              <Table
                type="Archive"
                getAllFundingStatus={getAllFundingStatus}
                roleData={roleData}
                searchData={searchData}
                filters={filters}
              />
            ),
            badge: statusData?.Archive || 0,
          },
        ]
      : () => [
          {
            id: 1,
            label: "Draft",
            view: (
              <Table
                type="Draft"
                getAllFundingStatus={getAllFundingStatus}
                roleData={roleData}
                searchData={searchData}
                filters={filters}
              />
            ),
            badge: statusData?.Draft || 0,
          },
        ],
    [statusData, searchData, filters, roleData],
  );

  const _getComponent = (tabs) => {
    const item = data.find((item) => item.label === tabs);
    return item ? item.view : null;
  };

  const onTabChange = (event, value) => {
    setActiveTab(value);
    setFilters(null);
    // Update route state with active tab
    navigate(location.pathname, { state: { activeTab: value } });
  };
  const getClientList = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getDataWithCounterType",
        token: cookies.t,
        params: {
          conterparty_type: "Client",
        },
      });
      setClientListData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  const getObligorList = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getDataWithCounterType",
        token: cookies.t,
        params: {
          conterparty_type: "Obligor",
        },
      });
      setObligorListData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  const getInvestorList = async () => {
    try {
      const res = await getData({
        endpoint: "investor/getInvestorList",
        token: cookies.t,
      });
      const investorData = res?.data?.map((item) => {
        return {
          label: item?.investorTag,
          value: item?.value,
        };
      });
      setInvestorData(investorData);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    getClientList();
    getObligorList();
    getInvestorList();
  }, []);

  const filterData = {
    // status: fundingStatusData,
    "Transaction Type": TransactionType,
    Currency: CurrencyData,
    Client: clientListData,
    Obligor: obligorListData,
    Funder: investorData,
  };
  return (
    <>
      <div className="fixed-part">
        <div className={style.headingSearchWrap}>
          <div>
            <Heading className="HeadingStyle">Funding</Heading>
          </div>
          <div className={style.searchButton}>
            <Search
              onSearchEmit={(data) => {
                console.log("data", data);
                setSearchData(data);
              }}
            />
            <FilterComponent
              filterData={filterData}
              onFilterChange={(filter) => {
                setFilters(filter);
              }}
              dateRangeNames={["Created On"]}
              isDateRange
              key={activeTab}
            />
            {roleData && roleData?.["add/edit"] && (
              <CustomButton
                text="Create funding"
                type="btn-primary"
                handleClick={() => {
                  navigate("/dashboard/funding/add");
                }}
              />
            )}
          </div>
        </div>
        <TabStrip activeTab={activeTab} onTabChange={onTabChange} data={data} />
      </div>

      <Box sx={{ width: "100%" }}>
        <Box className="tab-table">{_getComponent(activeTab)}</Box>
      </Box>
      <Modal
        isActive={modalStateAdvReq.isModalOpen}
        title={modalStateAdvReq.modalTitle}
        onClose={handleOnClose}
        width="850px"
      >
        <AdvReqModal
          modalStateAdvReq={modalStateAdvReq}
          setModalStateAdvReq={setModalStateAdvReq}
          getAllFundingStatus={getAllFundingStatus}
        />
      </Modal>
      <Modal
        isActive={modalStateSubmitted.isModalOpen}
        title={modalStateSubmitted.modalTitle}
        onClose={handleOnCloseSubmitted}
        width="850px"
        bodyStyles={{ paddingTop: 0 }}
      >
        <SubmittedModal
          modalStateSubmitted={modalStateSubmitted}
          setModalStateSubmitted={setModalStateSubmitted}
          getAllFundingStatus={getAllFundingStatus}
        />
      </Modal>
      <Modal
        isActive={modalStateApproved.isModalOpen}
        title={modalStateApproved.modalTitle}
        onClose={handleOnCloseApproved}
        width="850px"
        overflow="visible"
        bodyStyles={{ paddingTop: 0, overflow: "scroll" }}
      >
        <ApprovedModal
          modalStateApproved={modalStateApproved}
          setModalStateApproved={setModalStateApproved}
          getAllFundingStatus={getAllFundingStatus}
        />
      </Modal>
      <Modal
        isActive={modalStateAllocated.isModalOpen}
        title={modalStateAllocated.modalTitle}
        onClose={handleOnCloseAllocated}
        width="850px"
      >
        <AllocatedModal
          modalStateAllocated={modalStateAllocated}
          setModalStateAllocated={setModalStateAllocated}
          getAllFundingStatus={getAllFundingStatus}
        />
      </Modal>
    </>
  );
};

export default FundingManagement;
