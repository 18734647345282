import React, { useState } from "react";
import { useEffect } from "react";
import { Formik } from "formik";
import { Heading } from "../../../../../atoms/Heading";
import style from "./index.module.scss";
import { useCookies } from "react-cookie";
import { DateSelector } from "../../../../../atoms/MonthYearSelector";
import MyInput from "../../../../../atoms/MyInput";
import MyDropdown from "../../../../../atoms/MyDropdown";
import Breadcrumb from "../../../../../molecules/Breadcrumb";
import { getData, patchData } from "../../../../../../services";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../../assets/styles/components/toast";
import CustomButton from "../../../../../atoms/CustomButton";
import { useNavigate } from "react-router-dom";

import * as Yup from "yup";
import moment from "moment";

const formatDate = (dateStr) => dateStr.split("T")[0];

const addValueAndLabel = (data) => {
  const nameCounts = data.reduce((acc, item) => {
    acc[item.rate_name] = (acc[item.rate_name] || 0) + 1;
    return acc;
  }, {});

  

  return data.map((item) => {
    const formattedDate = moment(item.date).format('YYYY-MM-DD');
    const formattedEndDate = item.end_date ? moment(item.end_date).format('YYYY-MM-DD') : null;
    const baseName = item.rate_name;
  
    // Construct the label based on the presence of end_date and occurrence count
    const label = nameCounts[baseName] > 1 
      ? `${baseName} (${formattedDate}${formattedEndDate ? ` to ${formattedEndDate}` : ''})`
      : baseName;
  
    return {
      ...item,
      value: `${baseName} ${formattedDate}`,
      label: label,
    };
  });
};

const UpdateRate = () => {
  const [cookies] = useCookies(["t"]);
  const [rateData, setRateData] = useState([]);
  const navigate = useNavigate();

  const getAllRates = async () => {
    try {
      const res = await getData({
        endpoint: "/rates/GetRateListWithAllData",
        token: cookies.t,
      });

      if (res) {
        const tempData = res?.data?.[0]?.data;
        const finalData = addValueAndLabel(tempData);
        console.log("res", finalData);
        setRateData(finalData);
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    getAllRates();
  }, []);

  const initialValues = {
    rate_value: "",
    rate_name: "",
    date: "",
    base_rate: "",
    end_date : ''
  };
  const validationSchema = Yup.object().shape({
    rate_name: Yup.string().required("Rate Name is required"),
    date: Yup.string().required("Date is required"),
    end_date: Yup.string().required("End Date is required"),
    base_rate: Yup.string().required("Base Rate is required"),
  });

  const handleSubmit = async (values) => {
    const res = await patchData({
      endpoint: "rates/update",
      token: cookies.t,
      params: {
        id: values.rate_id,
        date: values.date,
        base_rate: values.base_rate,
        end_date : values.end_date
      },
    });
    if (res) {
      toast.success("Rate Details Updated Successfully", {
        duration: 1000,
        style: confimationStyles,
      });
      navigate("/dashboard/setting/rate-management");
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        key={Math.random}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          isValid,
          dirty,
          setFieldValue,
          touched,
          errors,
          values,
          handleBlur,
          handleSubmit,
          resetForm,
          isSubmitting,
          setFieldTouched,
        }) => (
          <>
            <Breadcrumb labels={["Rates", "Update Rate"]} stepsToRemove={0} />
            <Heading className="addHeaderStyle"> Update Rate</Heading>
            {console.log("errors", errors)}
            <div
              className="d-flex flex-wrap w-100"
              style={{ columnGap: "1.36%", rowGap: "16px" }}
            >
              <MyDropdown
                placeholder="Rate Name"
                data={rateData || []}
                onChange={(option) => {
                  console.log("op", option);
                  setFieldValue("rate_id", option._id);
                  setFieldValue("rate_name", option?.rate_name);
                  setFieldValue("rate_value", option?.value);
                  setFieldValue("date", option?.date);
                  setFieldValue("end_date", option?.end_date);
                  setFieldValue("base_rate", option?.base_rate);
                }}
                handleBlur={(e) => {
                  setFieldTouched("rate_name", true);
                  handleBlur(e);
                  //   checkRate(values.rate_name);
                }}
                selectedValue={values?.rate_value}
                menuOpen={true}
                name="rate_name"
                touched={touched.rate_name}
                errors={errors.rate_name}
                width="23.9%"
                format={"all"}
                // isDisabled
                required
              />
              <div style={{ position: "relative", width: "23.9%" }}>
                <DateSelector
                  placeholder="Start Date"
                  onChange={async (option) => {
                    setFieldValue("date", option);
                  }}
                  handleBlur={(e) => {
                    setFieldTouched("date", true);
                    handleBlur(e);
                  }}
                  selectedDate={values.date}
                  errors={errors.date}
                  touched={touched.date}
                  required
                />
              </div>

              <div style={{ position: "relative", width: "23.9%" }}>
                <DateSelector
                  placeholder="End Date"
                  onChange={async (option) => {
                    setFieldValue("end_date", option);
                  }}
                  handleBlur={(e) => {
                    setFieldTouched("end_date", true);
                    handleBlur(e);
                  }}
                  selectedDate={values.end_date}
                  errors={errors.end_date}
                  touched={touched.end_date}
                  required
                />
              </div>

              <MyInput
                type="number"
                name="base_rate"
                customholder="Base Rate"
                onBlur={(e) => {
                  handleBlur(e);
                }}
                InputHeight="small"
                width="23.9%"
                required
              />
            </div>
            <div className={style.buttonWrap}>
              <div>
                <CustomButton
                  text="Back"
                  size="btn-lg"
                  type="btn-outline-dark"
                  handleClick={() => {
                    navigate(-1);
                  }}
                />
              </div>
              <div className={style.resetAdd}>
                <CustomButton
                  text="Reset"
                  type="btn-outline-primary"
                  size="btn-lg"
                  handleClick={() => resetForm()}
                />
                <button
                  className="btn-primary btn-lg"
                  type="submit"
                  disabled={!isValid || !dirty || isSubmitting}
                  style={{ width: "100%" }}
                  onClick={() => handleSubmit(values)}
                >
                  Update
                  {isSubmitting && (
                    <span
                      className="spinner-border spinner-border-sm ms-2 text-light"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
              </div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

export default UpdateRate;
