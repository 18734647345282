import { useEffect, useState } from "react";
import CounterPartyBasicInfo, {
  ClientReportingInformation,
} from "../counterpartyBasicInfo";
import style from "./index.module.scss";
import { getData } from "../../../../../../services";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import moment from "moment";
import { removeTimestamp } from "../../../../../../helpers/removeTimestamp";

const MonitoringReview = ({ creditInfo }) => {
  const [cookies] = useCookies(["t"]);
  const { id } = useParams();
  const [clientData, setClientData] = useState({});
  const [reportingDocuments, setReportingDocuments] = useState([]);

  const getCounterDataById = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getCounterPartyById",
        token: cookies.t,
        params: {
          id: id,
        },
      });
      setClientData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  const getCounterReportDataById = async () => {
    try {
      const res = await getData({
        endpoint: "counterreport/getCounterReportByClientId",
        params: {
          client_id: id,
        },
        token: cookies.t,
      });
      setReportingDocuments(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (id) {
      getCounterDataById();
      getCounterReportDataById();
    }
  }, [id]);
  return (
    <>
      <div className={style.duesWrap}>
        <div className={style.clientBasicInfo}>
          <CounterPartyBasicInfo
            clientData={clientData}
            creditInfo={creditInfo}
          />
        </div>
        <div className={style.tableInfoWrap}>
          <table class="table">
            <thead>
              <ClientReportingInformation clientData={clientData} />
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  marginTop: "10px",
                }}
              >
                <thead>
                  <tr>
                    <th style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                      Date
                    </th>
                    <th style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                      Description
                    </th>
                    <th style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                      File Name
                    </th>
                    <th style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                      File Download
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {reportingDocuments?.length > 0 ? (
                    <>
                      {/* {Object.entries(counterReportData?.[0]).map(
                        ([category, files]) =>
                          files.map((file, index) => (
                            <tr key={index}>
                              {index === 0 && (
                                <td
                                  style={{
                                    border: "1px solid #DEE3E7",
                                    padding: "8px",
                                    verticalAlign: "top",
                                    fontWeight: "bold",
                                  }}
                                  rowSpan={files.length}
                                >
                                  {moment(
                                    reportingDocuments?.[0]?.selected_date,
                                  ).format("Do MMM, YYYY")}
                                </td>
                              )}
                              <td
                                style={{
                                  border: "1px solid #DEE3E7",
                                  padding: "8px",
                                }}
                              >
                                {category}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #DEE3E7",
                                  padding: "8px",
                                }}
                              >
                                {file.originalname}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #DEE3E7",
                                  padding: "8px",
                                }}
                              >
                                <a
                                  href={file.s3UploadedLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    color: "blue",
                                    textDecoration: "underline",
                                  }}
                                >
                                  View/Download
                                </a>
                              </td>
                            </tr>
                          )),
                      )} */}
                      {reportingDocuments.map((entry, entryIndex) => {
                        const documents = [
                          ...(
                            entry.filesData?.unauditedfinancialstatements || []
                          ).map((doc) => ({
                            ...doc,
                            category: "Unaudited Financial Statements",
                          })),
                          ...(
                            entry.filesData?.auditedfinancialstatements || []
                          ).map((doc) => ({
                            ...doc,
                            category: "Audited Financial Statements",
                          })),
                          ...(
                            entry.filesData?.aragingdetailsandsummary || []
                          ).map((doc) => ({
                            ...doc,
                            category: "AR Aging (details and summary)",
                          })),
                          ...(
                            entry.filesData?.apagingdetailsandsummary || []
                          ).map((doc) => ({
                            ...doc,
                            category: "AP Aging (details and summary)",
                          })),
                          ...(entry.filesData?.bankstatements || []).map(
                            (doc) => ({
                              ...doc,
                              category: "Bank Statements",
                            }),
                          ),
                          ...(
                            entry.filesData
                              ?.personalfinancialstatementofguarantors || []
                          ).map((doc) => ({
                            ...doc,
                            category:
                              "Personal financial Statement Of Guarantors",
                          })),
                          ...(
                            entry.filesData?.directorsandofficerslists || []
                          ).map((doc) => ({
                            ...doc,
                            category: "Directors and Officers Lists",
                          })),
                          ...(
                            entry.filesData
                              ?.personalfinancialstatementofguarantors || []
                          ).map((doc) => ({
                            ...doc,
                            category:
                              "Personal Financial Statement of Guarantors",
                          })),
                          ...(
                            entry.filesData?.taxreturnsortaxextension || []
                          ).map((doc) => ({
                            ...doc,
                            category: "Tax returns or Tax Extension",
                          })),
                          ...(entry.filesData?.compliancecertificate || []).map(
                            (doc) => ({
                              ...doc,
                              category: "Compliance Certificate",
                            }),
                          ),
                          ...(
                            entry.filesData?.inventoryactivityreport || []
                          ).map((doc) => ({
                            ...doc,
                            category: "Inventory Activity Report",
                          })),
                          ...(entry.filesData?.inventoryreport || []).map(
                            (doc) => ({
                              ...doc,
                              category: "Inventory Report",
                            }),
                          ),
                          ...(entry.filesData?.borrowingbase || []).map(
                            (doc) => ({
                              ...doc,
                              category: "Borrowing Base",
                            }),
                          ),
                          ...(entry.filesData?.hmrcstatementbalances || []).map(
                            (doc) => ({
                              ...doc,
                              category: "HMRC Statement Balances",
                            }),
                          ),
                          ...(entry.filesData?.projectionmodel || []).map(
                            (doc) => ({
                              ...doc,
                              category: "Projection model",
                            }),
                          ),
                          ...(entry.filesData?.arcollectionreport || []).map(
                            (doc) => ({
                              ...doc,
                              category: "AR Collection Report",
                            }),
                          ),
                          ...(
                            entry.filesData
                              ?.notificationregardingownershiporequitycapitalization ||
                            []
                          ).map((doc) => ({
                            ...doc,
                            category:
                              "Notification Regarding Ownership or Equity Capitalization",
                          })),
                          ...(entry.filesData?.openpurchaseorders || []).map(
                            (doc) => ({
                              ...doc,
                              category: "Open Purchase Orders",
                            }),
                          ),
                          ...(entry.filesData?.debtschedule || []).map(
                            (doc) => ({
                              ...doc,
                              category: "Debt Schedule",
                            }),
                          ),
                          ...(entry.filesData?.wipreport || []).map((doc) => ({
                            ...doc,
                            category: "WIP Report",
                          })),
                          ...(
                            entry.filesData
                              ?.annualauditedfinancialstatementsandsignedconsolidatedfinancialstatementsofbuyer ||
                            []
                          ).map((doc) => ({
                            ...doc,
                            category:
                              "Annual Audited Financial Statements and Signed consolidated financial statements of buyer",
                          })),
                          ...(
                            entry.filesData?.listofinventorymanagers || []
                          ).map((doc) => ({
                            ...doc,
                            category: "List of Inventory Managers",
                          })),
                        ];

                        return documents.map((doc, docIndex) => (
                          <tr key={`${entry._id}-${docIndex}`}>
                            {docIndex === 0 && (
                              <>
                                {/* First row for each date */}
                                <td
                                  rowSpan={documents.length}
                                  style={{
                                    border: "1px solid #DEE3E7",
                                    padding: "8px",
                                    verticalAlign: "top",
                                    fontWeight: "bold",
                                    minWidth: "auto",
                                    width: "10%",
                                  }}
                                >
                                  {moment
                                    .utc(entry.selected_date)
                                    .format("Do MMM, YYYY")}
                                </td>
                                {/* <td
                                  rowSpan={documents.length}
                                  style={{
                                    border: "1px solid #DEE3E7",
                                    padding: "8px",
                                    verticalAlign: "top",
                                  }}
                                >
                                  {entry.client_name}
                                </td> */}
                              </>
                            )}
                            <td
                              style={{
                                border: "1px solid #DEE3E7",
                                padding: "8px",
                              }}
                            >
                              {doc.category}
                            </td>
                            <td
                              style={{
                                border: "1px solid #DEE3E7",
                                padding: "8px",
                              }}
                            >
                              {removeTimestamp(doc.originalname)}
                            </td>
                            <td
                              style={{
                                border: "1px solid #DEE3E7",
                                padding: "8px",
                              }}
                            >
                              <a
                                href={doc.s3UploadedLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  color: "blue",
                                  textDecoration: "underline",
                                }}
                              >
                                View/Download
                              </a>
                            </td>
                          </tr>
                        ));
                      })}
                    </>
                  ) : (
                    <tr>
                      <td
                        colSpan={7} // Adjust to the total number of columns
                        style={{
                          border: "1px solid #DEE3E7",
                          padding: "8px",
                          textAlign: "center",
                        }}
                      >
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </thead>
          </table>
        </div>
      </div>
    </>
  );
};
export default MonitoringReview;
