import { memo, useEffect, useState, useCallback } from "react";
import { useCookies } from "react-cookie";
import { getData } from "../../../../../services";
import NoContentCard from "../../../../atoms/NoContentCard";
import CustomDatagrid from "../../../../molecules/CustomDatagrid";
import { useNavigate, useParams } from "react-router-dom";
import CounterPartyBasicInfo from "./counterpartyBasicInfo";
import style from "./index.module.scss";
import moment from "moment";
import { formatNumberInternational } from "../../../../../helpers/capatalizeLetters";
import Pagination from "../../../../atoms/Pagination";

const CounterPartyDue = ({ clientData, creditInfo }) => {
  const [cookies] = useCookies(["t"]);
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [duesData, setDuesData] = useState([]);
  const navigate = useNavigate();

  const getDueData = async (fundingData) => {
    try {
      const res = await getData({
        endpoint: "funding/getFundingClientIdWithPagination",
        params: {
          id: fundingData._id,
          status: "Funded",
          trans_type: fundingData?.counter_type,
          page: currentPage,
          limit: itemsPerPage,
        },
        token: cookies.t,
      });
      setDuesData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  const getCounterDataById = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getCounterPartyById",
        token: cookies.t,
        params: {
          id: id,
        },
      });
      return res?.data;
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (clientData) {
      getDueData(clientData);
    }
  }, [clientData, currentPage, itemsPerPage]);

  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        <NoContentCard
          title="No Content Found"
          subtitle=""
          type="See Dues"
          classes="d-flex align-items-center"
          fontSize={"14px"}
          height={"6rem"}
        />
      </>
    );
  });
  const columns = [
    {
      field: "client_name",
      headerName: "Client",
      flex: 0.8,
      sortable: true,
      cellRenderer: (params) => {
        return (
          <div title={params?.data?.client_name} className="line-truncate">
            {params?.data?.client_name}
          </div>
        );
      },
    },
    {
      field: "obligor_name",
      headerName: "Obligor",
      flex: 0.8,
      cellRenderer: (params) => {
        return (
          <div title={params?.data?.obligor_name} className="line-truncate">
            {params?.data?.obligor_name
              ? params?.data?.obligor_name
              : params?.data?.client_name}
          </div>
        );
      },
    },
    {
      field: "supplier_name",
      headerName: "Supplier",
      flex: 0.8,
      cellRenderer: (params) => {
        return (
          <div title={params?.data?.supplier_name} className="line-truncate">
            {params?.data?.supplier_name
              ? params?.data?.supplier_name
              : params?.data?.client_name}
          </div>
        );
      },
    },
    {
      field: "due_date",
      headerName: "Due Date",
      flex: 0.8,
      cellRenderer: (params) => {
        return (
          <div title={moment.utc(params?.data?.due_date).format("YYYY-MM-DD")}>
            {moment.utc(params?.data?.due_date).format("YYYY-MM-DD")}
          </div>
        );
      },
    },
    {
      field: "invoice_number",
      headerName: "Client Ref",
      flex: 0.8,
      cellRenderer: (params) => {
        return (
          <div title={params?.data?.invoice_number}>
            {params?.data?.invoice_number}
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.8,
      valueGetter: (params) =>
        params?.data?.status === "Funded" ? "Open" : "",
      cellRenderer: (params) => {
        return <div>{params?.data?.status === "Funded" ? "Open" : ""}</div>;
      },
    },
    {
      field: "cost_value",
      headerName: "Cost Value",
      type: "number",
      sortable: true,
      flex: 1,
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => Number(params?.data?.cost_value) || 0,
      cellRenderer: (params) => {
        return (
          <div>
            {formatNumberInternational(parseFloat(params?.data?.cost_value))}
          </div>
        );
      },
    },
    {
      field: "face_value",
      headerName: "Face Value",
      flex: 0.8,
      type: "number",
      sortable: true,
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => Number(params?.data?.face_value) || 0,

      cellRenderer: (params) => {
        return (
          <div>
            {formatNumberInternational(parseFloat(params?.data?.face_value))}
          </div>
        );
      },
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 0.8,
      cellRenderer: (params) => {
        return <div>{params?.data?.currency}</div>;
      },
    },
    {
      field: "transaction_type",
      headerName: "Transaction Type",
      flex: 0.8,
      cellRenderer: (params) => {
        return (
          <div title={params?.data?.transaction_type} className="line-truncate">
            {params?.data?.transaction_type}
          </div>
        );
      },
    },
  ];

  // Handle page change
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  return (
    <>
      <div className={style.duesWrap}>
        <div className={style.clientBasicInfo}>
          <CounterPartyBasicInfo
            clientData={clientData}
            creditInfo={creditInfo}
          />
        </div>
        <div className={style.tableInfoWrap}>
          <CustomDatagrid
            rows={duesData?.fundedData}
            getRowId={(row) => row?._id}
            columns={columns}
            height={() => 50}
            hoverColor="ffffff"
            isRowsVisible
            CustomNoRowsOverlay={CustomNoRowsOverlay}
            onCellClick={(data) => {
              navigate(`/dashboard/funding/view/${data?.data?._id}`);
            }}
          />
        </div>
      </div>
      {duesData?.pageDetails?.count > 10 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={duesData?.pageDetails?.count}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}
    </>
  );
};

export default CounterPartyDue;
