import React from "react";
import { useLocation } from "react-router-dom";
import style from "../index.module.scss";
import moment from "moment";

const BasicInfo = () => {
  const location = useLocation();
  const { data } = location.state || {};

  return (
    <>
      <div className={style.basic_info}>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Funder Information</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={style.label}>Name</td>
              <td className={style.value}>{data?.investor_name}</td>
            </tr>
            {/* <tr>
              <td className={style.label}>Code</td>
              <td className={style.value}>{data.investor_code}</td>
            </tr> */}
            <tr>
              <td className={style.label}>Address</td>
              <td className={style.value}>{data.address}</td>
            </tr>
            <tr>
              <td className={style.label}>Master Agreement Date</td>
              <td className={style.value}>
                {data?.master_agreement_date &&
                  moment.utc(data?.master_agreement_date).format("YYYY-MM-DD")}
              </td>
            </tr>
            {/* <tr>
              <td className={style.label}>NAV</td>
              <td className={style.value}>{data?.nav}</td>
            </tr>      <tr>
              <td className={style.label}>NAV Date</td>
              <td className={style.value}>{moment(data?.nave_date).format('YYYY-MM-DD')}</td>
            </tr>
             */}
            <tr>
              <td className={style.label}>Interested Party Emails</td>
              <td className={style.value}>
                {data?.interest_party_email?.join(", ")}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BasicInfo;
