import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import { getData, postData } from "../../../../../services";
import { LabelDisplay } from "../../counterparty/view";
import moment from "moment";
import { Heading } from "../../../../atoms/Heading";
import style from "./index.module.scss";
import ClientInternalMonitoring from "../../notes/Forms/ClientInternalMonitoring";
import CustomButton from "../../../../atoms/CustomButton";
import QuillEditor from "../../../../atoms/QuillEditor/QuillEditor";
import { confimationStyles } from "../../../../../assets/styles/components/toast";
import toast from "react-hot-toast";
import Modal from "../../../../atoms/modals/Modal";
import { minWidth } from "@mui/system";
import TagInput from "../../../../atoms/TagInput";
import { removeTimestamp } from "../../../../../helpers/removeTimestamp";
import { formatNumberInternational } from "../../../../../helpers/capatalizeLetters";
import { convertAndAddCurrencies } from "../../dashboard/helper/currency";
import { getCurrencyRate } from "../../../../../services/currencyConvertor";

const DashboardMonitor = ({ counterData, setCounterData, id }) => {
  // const { id } = useParams();
  const navigate = useNavigate();
  // const [counterData, setCounterData] = useState([]);
  const [notesData, setNotesData] = useState([]);
  const [covenantData, setCovenantData] = useState([]);
  const [requirements, setRequirements] = useState([]);
  const [isLoadingSave, setIsLoadingSave] = useState({
    save: false,
    saveNdShare: false,
  });
  // const [viewMore, setViewMore] = useState(false);
  const [monitorValues, setMonitorValues] = useState({
    observations: "",
    next_steps: "",
  });
  const [cookies] = useCookies(["t", "un"]);
  // const [viewMoreStates, setViewMoreStates] = useState(null);
  const [viewData, setViewData] = useState({
    isActive: false,
    data: "",
    title: "",
  });
  const [loader, setLoader] = useState(false);
  const [analysisData, setAnalysisData] = useState();
  const [creditInfo, setCreditInfo] = useState();

  const [currencyConversion, setCurrencyConversion] = useState(null);

  useEffect(() => {
    const fetchCurrencyRate = async () => {
      try {
        const res = await getCurrencyRate({ date: new Date() });
        setCurrencyConversion(res);
      } catch (error) {
        console.error("Error fetching currency rate:", error);
      }
    };

    fetchCurrencyRate(); // Call the async function
  }, []);

  // const toggleViewMore = (id) => {
  //   setViewMoreStates((prevStates) => ({
  //     ...prevStates,
  //     [id]: !prevStates[id], // Toggle the state for the specific item
  //   }));
  // };
  const toggleViewMore = (id, data, title) => {
    // If the same item is clicked again, close it, otherwise open the clicked one
    // setViewMoreStates(viewMoreStates === id ? null : id);
    setViewData({
      isActive: true,
      data: data,
      title: title,
    });
  };

  const getClientCreditLimit = async (id, type) => {
    const res = await getData({
      endpoint: "couterParty/clientCreditLeft",
      token: cookies.t,
      params: {
        client_id: id,
        trans_type: type,
      },
    });

    console.log("resss", res?.data);

    setCreditInfo(res?.data);
  };

  const getCounterDataById = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getCounterPartyById",
        token: cookies.t,
        params: {
          id: id,
        },
      });
      setCounterData(res?.data);

      if (res?.data) {
        console.log("counter-data", res?.data);
        getClientCreditLimit(res?.data?._id, res?.data?.counter_type);
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  const getCovenantDataById = async () => {
    try {
      const res = await getData({
        endpoint: "covenant/getCovenantClients",
        token: cookies.t,
        params: {
          client_id: id,
        },
      });
      setCovenantData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  const getNotesData = async () => {
    try {
      const res = await getData({
        endpoint: "notes/getAllNotesByClient",
        token: cookies.t,
        params: {
          client_id: id,
        },
      });
      setNotesData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  const getCovenantData = async () => {
    const res = await getData({
      endpoint: "covenant/getCovenatDetailsByClientId",
      params: { client_id: id },
      token: cookies.t,
    });
    if (res?.data) {
      console.log("res-cov", res?.data);
      setCovenantData(res?.data?.covenant_details);
    } else {
      setCovenantData([]);
    }
  };

  const getReportingRequirementData = async () => {
    const res = await getData({
      endpoint:
        "reporting-requirements/getPeportingRequiremetsDetailsByClientId",
      params: { client_id: id },
      token: cookies.t,
    });
    if (res?.data) {
      setRequirements(res?.data?.reporting_requirement_details);
    } else {
      setRequirements([]);
    }
  };
  const getAnalysisByClientID = async () => {
    try {
      const res = await getData({
        endpoint: "analysis/getClientFinancialDataByClientID",
        params: {
          id: id,
        },
        token: cookies.t,
      });
      setAnalysisData(res?.data?.length > 0 ? res?.data : "");
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (id) {
      getCounterDataById();
      getCovenantDataById();
      getNotesData();
      getCovenantData();
      getReportingRequirementData();
      getAnalysisByClientID();
    }
  }, [id]);
  console.log("analysisData", analysisData);

  //to check empty data
  const isEmptyOrOnlyHTMLTags = (str) => {
    const strippedText = str.replace(/<[^>]*>/g, "").trim(); // Remove all HTML tags and trim whitespace
    return strippedText === "";
  };

  // Check if the button should be disabled
  const isButtonDisabled =
    isEmptyOrOnlyHTMLTags(monitorValues.observations) &&
    isEmptyOrOnlyHTMLTags(monitorValues.next_steps);

  const getKeyValues = {
    "clientQ&A": {
      QA: "Questions and Answers",
    },
    "prospectiveQ&A": {
      QA: "Questions and Answers",
    },
    general: {
      general: "General",
    },
    clientInternalMonitoring: {
      observations: "Observations",
      next_steps: "Next Steps",
    },
    clientMonitoring: {
      BMR: "Business Model-Recap",
      BMC: "Business Model - Changes",
      FI: "Financial - Income",
      FBS: "Financial - Balance Sheet",
      FCF: "Financial - Cash Flow",
      OSC: "Operational - Suppliers changes",
      OSI: "Operational - Suppliers Issues",
      OI: "Operational - Inventory",
      OBC: "Operational - Buyers Changes",
      OBI: "Operational - Buyers Issues",
      CO: "Corporate - Ownership",
      CP: "Corporate - Personal",
      CASACM: "Corporate - Authorised signatories and cash movement",
      CPP: "Corporate - Policies and procedure",
      CRT: "Corporate - Regulatory and Tax",
      CL: "Corporate - Lawsuits",
      OTHERS: "Others",
    },
    brokerUpdate: {
      DEALS: "Deals",
      MC: "Market Color",
      ORIGINATION: "Origination",
      IAD: "Instructions and Directions",
    },
    platformUpdate: {
      CP: "Current Positions",
      PIPELINE: "Pipeline",
      OTHERS: "Other",
    },
    prospectiveOnboarding: {
      CO: "Corporate Overview",
      CMT: "Corporate Management Team",
      CBV: "Corporate Business Model and Value Drivers",
      CSOD: "Corporate Structure, Ownership and Domicile",
      CFR: "Corporate Functional Roles",
      FIS: "Financial Income Statement",
      FBS: "Financial Balance Sheet",
      FWC: "Financial Working Capital",
      OS: "Operational - Suppliers Issues",
      OI: "Operational Inventory",
      OB: "Operational Buyers",
    },
  };
  const getHeadingKey = {
    "clientQ&A": "Existing Client Monitoring",
    "prospectiveQ&A": "Prospective Client Q&A",
    general: "General",
    clientInternalMonitoring: "Internal Client Monitoring",
    clientMonitoring: "Existing Client Monitoring",
    brokerUpdate: "Broker Update",
    platformUpdate: "Platform Update",
    prospectiveOnboarding: "Prospective Client Onboarding",
  };

  const handleSubmit = async (status) => {
    const dataTOSend = {
      type: "clientInternalMonitoring",
      client_name:
        counterData.counter_type === "Client"
          ? counterData.counter_name
          : counterData.client_name,
      client_id: id,
      note_date: moment().utc().startOf("day").toISOString(),
      note_taker: atob(cookies.un),
      details: monitorValues,
      prior_call: "",
      status: status,
    };
    setLoader(true);
    try {
      if (status === "Active") {
        setIsLoadingSave({ save: false, saveNdShare: true });
      } else {
        setIsLoadingSave({ save: true, saveNdShare: false });
      }
      const res = await postData({
        endpoint: "notes/create",
        data: dataTOSend,
        token: cookies.t,
      });
      if (res) {
        setIsLoadingSave({ save: false, saveNdShare: false });
        toast.success("Notes Added Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        setMonitorValues({
          observations: "",
          next_steps: "",
        });
        await getNotesData();
      }
    } catch (err) {
      console.log("error", err);
    } finally {
      setIsLoadingSave(false);
      setLoader(false);
    }
  };

  return (
    <>
      <div>
        {" "}
        <tr>
          <th scope="col" style={{ paddingRight: "5px" }}>
            Selected Client:
          </th>
          <th scope="col">
            {counterData.counter_type === "Client"
              ? counterData.counter_name
              : counterData.client_name}
          </th>
        </tr>
      </div>
      <div className={style.bothsidewrap}>
        <div className={style.fundedViewleftWrap}>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">
                  <div style={{ minWidth: "max-content" }}>
                    Counterparty Information
                  </div>
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={style.label}>Name</td>
                <td className={style.value}>{counterData?.counter_name}</td>
              </tr>
              <tr>
                <td className={style.label}>Address</td>
                <td className={style.value}>
                  {counterData?.invoice_number || counterData?.address}
                </td>
              </tr>
              <tr>
                <td className={style.label}>TaxID</td>
                <td className={style.value}>{counterData?.tax_id}</td>
              </tr>
              <tr>
                <td className={style.label}>Type</td>
                <td className={style.value}>{counterData?.counter_type}</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th scope="col" colSpan={2}>
                  Credit Information
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={style.label}>Counterparty Type</td>
                <td className={style.value}>{counterData?.counter_type}</td>
              </tr>
              <tr>
                <td className={style.label}>Internal Credit Limit</td>
                <td className={style.value}>{counterData?.credit_limit}</td>
              </tr>
              <tr>
                <td className={style.label}>Credit Used</td>
                <td className={style.value}>
                  {counterData?.credit_limit
                    ? formatNumberInternational(
                        parseFloat(
                          convertAndAddCurrencies(
                            creditInfo?.amountFunded,
                            currencyConversion,
                            "number",
                          ),
                        ),
                      )
                    : null}
                </td>
              </tr>
              <tr>
                <td className={style.label}>Credit Remaining</td>
                <td className={style.value}>
                  {counterData?.credit_limit && creditInfo?.amountFunded
                    ? formatNumberInternational(
                        parseFloat(
                          counterData.credit_limit -
                            convertAndAddCurrencies(
                              creditInfo?.amountFunded,
                              currencyConversion,
                              "number",
                            ),
                        ),
                      )
                    : counterData?.credit_limit}
                </td>
              </tr>
              <tr>
                <td className={style.label}>Sector</td>
                <td className={style.value}>{counterData?.gics_sector}</td>
              </tr>
              <tr>
                <td className={style.label}>Industry</td>
                <td className={style.value}>{counterData?.gics_industry}</td>
              </tr>
              <tr>
                <td className={style.label}>Geography</td>
                <td className={style.value}>{counterData?.geography}</td>
              </tr>
              <tr>
                <td className={style.label}>Currency</td>
                <td className={style.value}>{counterData?.primary_currency}</td>
              </tr>
              <tr>
                <td className={style.label}>Last Payment Date/Amount</td>
                <td className={style.value}>{}</td>
              </tr>
              <tr>
                <td className={style.label}>Memos</td>
                <td className={style.value}>
                  {analysisData?.length
                    ? analysisData?.map((item) => {
                        const memos = Object.entries(item?.filesData || {})
                          .filter(
                            ([key]) =>
                              key === "summaryInvestmentMemo" ||
                              key === "investmentMemo",
                          )
                          .map(([key, value]) => ({
                            key, // Keep the key as it is
                            name: value?.[0]?.originalname || "", // Add the original name
                            value: value?.[0]?.s3UploadedLink || "", // Add the s3 link
                          }));

                        return (
                          <>
                            {memos.map((memo) => (
                              <div
                                key={memo.key}
                                style={{ marginBottom: "10px" }}
                              >
                                <a
                                  href={memo.value}
                                  download={memo.name} // Adds download attribute
                                  style={{
                                    marginLeft: "10px",
                                    color: "blue",
                                    textDecoration: "underline",
                                  }}
                                >
                                  {removeTimestamp(memo.name)}
                                </a>
                              </div>
                            ))}
                          </>
                        );
                      })
                    : "--"}
                </td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan={2}>Insurance Limit Information</th>
                {/* <th scope="col"></th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={style.label}>Limit</td>
                <td className={style.value}>
                  {counterData?.insuranceDetails?.[0]?.limit}
                </td>
              </tr>
              <tr>
                <td className={style.label}>End Date</td>
                <td className={style.value}>
                  {counterData?.insuranceDetails?.[0]?.end_date &&
                    moment(counterData?.insuranceDetails?.[0]?.end_date).format(
                      "YYYY-MM-DD",
                    )}
                </td>
              </tr>
              <tr>
                <td className={style.label}>Type</td>
                <td className={style.value}>
                  {counterData?.insuranceDetails?.[0]?.limit_type}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ paddingTop: "20px" }}>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                  Covenant
                </th>
                <th style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                  Covenant (Detail)
                </th>
                <th style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                  Frequency
                </th>
                <th style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                  Shift
                </th>
              </tr>
            </thead>
            <tbody>
              {covenantData.length > 0 ? (
                covenantData.map((item, index) => (
                  <tr key={index}>
                    <td style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                      {item.name}
                    </td>
                    <td style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                      {item.long_description}
                    </td>
                    <td style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                      {item.frequency}
                    </td>
                    <td style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                      {item.monthly_shift}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={4}
                    style={{
                      border: "1px solid #DEE3E7",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              marginTop: "10px",
            }}
          >
            <thead>
              <tr>
                <th style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                  Requirement
                </th>
                <th style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                  Requirement (Detail)
                </th>
                <th style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                  Frequency
                </th>
                <th style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                  Shift
                </th>
                <th style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                  Reporting Days
                </th>
                <th style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                  Next Report As of Date
                </th>
                <th style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                  Next Due Date
                </th>
              </tr>
            </thead>
            <tbody>
              {requirements.length > 0 ? (
                requirements.map((item, index) => (
                  <tr key={index}>
                    <td style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                      {item.name}
                    </td>
                    <td style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                      {item.long_description}
                    </td>
                    <td style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                      {item.frequency}
                    </td>
                    <td style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                      {item.monthly_shift}
                    </td>
                    <td style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                      {item.reporting_days}
                    </td>
                    <td style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                      {moment(item.next_reporting_date).format("Do MMM, YYYY")}
                    </td>
                    <td style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                      {moment(item.next_due_date).format("Do MMM, YYYY")}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={7} // Adjust to the total number of columns
                    style={{
                      border: "1px solid #DEE3E7",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div
        className="d-flex gap-2"
        style={{ overflow: "hidden", marginTop: "10px" }}
      >
        {notesData && notesData.length > 0 && (
          <div className={style.notes_container}>
            {notesData.map((item) => {
              return (
                <div key={item._id} style={{ marginBottom: "20px" }}>
                  {/* Main heading */}
                  <Heading className="h5 mb-3 mt-3">{`${
                    getHeadingKey[item?.type]
                  } - ${moment(item?.note_date).format(
                    "YYYY-MM-DD",
                  )}`}</Heading>

                  {/* Subheadings and their values */}
                  <div>
                    {Object.keys(item.details).map(
                      (key) =>
                        item.details[key].length > 0 &&
                        !isEmptyOrOnlyHTMLTags(item.details[key]) && (
                          <div key={key} style={{ marginBottom: "10px" }}>
                            {/* Subheading */}
                            <h3 className={style.label_quill}>
                              {getKeyValues[item.type][key] || key}
                            </h3>

                            {/* Value with viewMore functionality */}
                            <p
                              dangerouslySetInnerHTML={{
                                __html:
                                  item.details[key].split(" ").length > 50
                                    ? item.details[key]
                                        .split(" ")
                                        .slice(0, 50)
                                        .join(" ") + "..."
                                    : item.details[key],
                              }}
                            />

                            {item.details[key].split(" ").length > 50 && (
                              <div
                                onClick={() =>
                                  toggleViewMore(
                                    `${item._id}+${key}`,
                                    item.details[key],
                                    `${getHeadingKey[item?.type]} - ${
                                      getKeyValues[item.type][key] || key
                                    }`,
                                  )
                                }
                                style={{ cursor: "pointer", color: "blue" }}
                              >
                                View More
                              </div>
                            )}
                          </div>
                        ),
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <div
          className={notesData && notesData.length > 0 ? "w-50" : "w-100"}
          style={{
            borderLeft:
              notesData && notesData.length > 0 ? "1px solid #DEE3E7" : "",
            paddingLeft: notesData && notesData.length > 0 ? "10px" : "0",
          }}
        >
          <Heading className="h5 mb-3 mt-3">
            Add New Internal Client Monitoring
          </Heading>
          <div className="mt-2">
            <QuillEditor
              label="Observations"
              placeholderTextcription="Observations"
              onChange={(content) =>
                setMonitorValues({
                  ...monitorValues,
                  observations: content,
                })
              }
              value={monitorValues.observations}
              type="faq"
              height="230px"
              hp="80%"
              classes="fixed-position-styles"
              placeholderText={"Performance trends, changes, rationale"}
            />
          </div>
          <div>
            <QuillEditor
              label="Next Steps"
              placeholderTextcription="Next Steps"
              onChange={(content) =>
                setMonitorValues({
                  ...monitorValues,
                  next_steps: content,
                })
              }
              value={monitorValues.next_steps}
              type="faq"
              height="230px"
              hp="80%"
              classes="fixed-position-styles"
              placeholderText={"Action items, follow up, next call"}
              // data={priorData?.details?.next_steps}
              // mode={mode}
              // dataHeading={`${priorData.client_name}-${moment(
              //   priorData.note_date,
              // ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
            />
          </div>
          <div className={style.buttonWrap}>
            <div className="d-flex gap-2">
              {/* <CustomButton
                text="Save"
                type="btn-outline-primary"
                size="btn-lg"
                handleClick={() => handleSubmit("Draft")}
                // disabled={isSubmitting}
              />
              <CustomButton
                text={"Save & Share"}
                type="btn-primary"
                size="btn-lg"
                handleClick={() => handleSubmit("Active")}
                // disabled={isSubmitting}
              /> */}
              <button
                className="btn-outline-primary"
                type="submit"
                style={{ width: "100%" }}
                onClick={() => handleSubmit("Draft")}
                disabled={isButtonDisabled || isLoadingSave?.save}
              >
                Save
                {isLoadingSave?.save && (
                  <span
                    className="spinner-border spinner-border-sm ms-2 text-primary"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </button>
              <button
                className="btn-primary btn-lg"
                type="submit"
                style={{ width: "100%" }}
                onClick={() => handleSubmit("Active")}
                disabled={isButtonDisabled || isLoadingSave?.saveNdShare}
              >
                Save & Share
                {isLoadingSave?.saveNdShare && (
                  <span
                    className="spinner-border spinner-border-sm ms-2 text-light"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {console.log("monitor", monitorValues)}
      <Modal
        isActive={viewData.isActive}
        width="70vw"
        onClose={() =>
          setViewData({
            isActive: false,
            data: "",
          })
        }
        title={viewData.title}
      >
        <div dangerouslySetInnerHTML={{ __html: viewData.data }}></div>
      </Modal>
    </>
  );
};
export default DashboardMonitor;
