import React, { useEffect, useState } from "react";
import { Heading } from "../../../../../atoms/Heading";
import CustomDatagrid from "../../../../../molecules/CustomDatagrid";
import { getData } from "../../../../../../services";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import moment from "moment";
import Pagination from "../../../../../atoms/Pagination";

const names = {
  Supplier: "supplier_name",
  Client: "client_name",
  Obligor: "obligor_name",
  "Supplier Credit-1": "supplier_name",
  Revolver: "client_name",
};

const formatNumberWithCommas = (number) => {
  return new Intl.NumberFormat().format(number);
};

const Dues = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);

  const columns = [
    {
      field: "client_name",
      headerName: "Client Name",
      flex: 1,
      sortable: true,
      cellRenderer: (params) => {
        return <div className="line-truncate">{params?.data?.client_name}</div>;
      },
    },
    {
      field: "obligor_name",
      headerName: "Obligor Name",
      flex: 1,
      sortable: true,
      cellRenderer: (params) => {
        return (
          <div className="line-truncate">
            {params?.data?.client_id === params?.data?.obligor_id
              ? params?.data?.client_name
              : params?.data?.obligor_name
              ? params?.data?.obligor_name
              : params?.data?.client_name
              ? params?.data?.client_name
              : ""}
          </div>
        );
      },
    },
    {
      field: "supplier_name",
      headerName: "Supplier Name",
      flex: 1,
      sortable: true,
      cellRenderer: (params) => {
        return (
          <div className="line-truncate">
            {params?.data?.client_id === params?.data?.supplier_id
              ? params?.data?.client_name
              : params?.data?.supplier_name
              ? params?.data?.supplier_name
              : params?.data?.client_name
              ? params?.data?.client_name
              : ""}
          </div>
        );
      },
    },
    {
      field: "due_date",
      headerName: "Due Date",
      flex: 0.8,
      sortable: true,
      cellRenderer: (params) => {
        const { row } = params;
        const transactionType = row?.transaction_type;
        const createdDate = row?.createdAt; // Assuming createdDate is a valid date string
        const paymentTerms = row?.payment_terms; // Assuming paymentTerms is in a valid format (e.g., days)

        if (
          transactionType === "Supplier" ||
          transactionType === "Supplier Credit-1"
        ) {
          if (createdDate && paymentTerms) {
            const dueDate = moment.utc(createdDate).add(paymentTerms, "days");
            const formattedDueDate = dueDate.format("YYYY-MM-DD");
            return <div className={"valueStyle"}>{formattedDueDate}</div>;
          }
        }

        return (
          <div className={"valueStyle"}>
            {moment.utc(row?.due_date).format("YYYY-MM-DD") || "N/A"}
          </div>
        );
      },
    },

    {
      field: "referance",
      headerName: "Client Ref",
      flex: 0.8,
      sortable: true,
    },
    {
      field: "funder",
      headerName: "Participant",
      flex: 0.8,
      sortable: true,
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 0.8,
      sortable: true,
    },

    {
      field: "face_value",
      headerName: "Face Value",
      type: "number",
      sortable: true,
      flex: 1,
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => Number(params?.data?.face_value) || 0,
      cellRenderer: (params) => {
        return (
          <div className={"valueStyle"}>
            {formatNumberWithCommas(params?.data?.face_value)}
          </div>
        );
      },
    },
    {
      field: "cost_value",
      headerName: "Cost",
      type: "number",
      sortable: true,
      flex: 1,
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => Number(params?.data?.cost_value) || 0,
      cellRenderer: (params) => {
        return (
          <div className={"valueStyle"}>
            {formatNumberWithCommas(params?.data?.cost_value)}
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.8,
      valueGetter: (params) => {
        return params.data?.status === "Funded" ? "Open" : params.data?.status;
      },
      cellRenderer: (params) => {
        return (
          <div className={"valueStyle"}>
            {params?.data?.status === "Funded" ? "Open" : params?.data?.status}
          </div>
        );
      },
    },
  ];
  const [cookies] = useCookies(["t"]);
  const [data, setData] = useState([]);

  const { id } = useParams();

  const getDues = async () => {
    try {
      const res = await getData({
        endpoint: "/funding/funderDues",
        token: cookies.t,
        params: {
          investor_id: id,
          page: currentPage,
          limit: itemsPerPage,
        },
      });

      console.log("res", res);

      setData(res?.data?.[0]);
    } catch (error) {}
  };

  useEffect(() => {
    getDues();
  }, [itemsPerPage, currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (itemsPerPage) => {
    setItemsPerPage(itemsPerPage);
  };

  return (
    <>
      {/* <div className="headingSearchWrap">
          <Heading className="HeadingSubStyle mb-0">Obligor Assets Due</Heading>
      </div> */}

      <CustomDatagrid
        rows={data?.fundedData}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
      />
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={data?.pageDetails?.count}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
    </>
  );
};

export default Dues;
