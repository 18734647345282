import React, { memo, useEffect, useState, useCallback } from "react";
import CustomDatagrid from "../../../../molecules/CustomDatagrid";
import NoContentCard from "../../../../atoms/NoContentCard";
import { useCookies } from "react-cookie";
import { getData } from "../../../../../services";
import { convertAndAddCurrencies } from "../helper/currency";
import Pagination from "../../../../atoms/Pagination";
import { formatNumberInternational } from "../../../../../helpers/capatalizeLetters";

const ClientSummary = ({ conversionRate, date }) => {
  const [loader, setLoader] = useState(true);
  const [cookies] = useCookies(["t"]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [data, setData] = useState([]);

  const getClientData = async () => {
    setLoader(true);

    try {
      const res = await getData({
        endpoint: "dashboard/getClientSummary",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
          start_date: date?.start_date?.replace(".000", ""),
          end_date: date?.end_date?.replace(".000", ""),
        },
      });

      setData(res?.data);
    } catch (err) {
    } finally {
      setLoader(false);
    }
  };
  console.log("data", data);

  useEffect(() => {
    setLoader(false);
    getClientData();
  }, [itemsPerPage, currentPage, date]);

  const columns = [
    {
      field: "client_name",
      headerName: "Client",
      flex: 0.8,
      sortable: true,
      cellRenderer: (params) => {
        return <div className="line-truncate">{params?.data?.client_name}</div>;
      },
    },
    {
      field: "currency",
      headerName: "Currency",
      // sortable : true,
      flex: 0.8,
      cellRenderer: (params) => {
        const cost = params?.data?.costSummary;
        return (
          <>
            <span>{Object.keys(cost).join(",")}</span>
          </>
        );
      },
    },
    {
      field: "client_internal_limit",
      headerName: "Internal Credit Limit",
      flex: 0.8,
      sortable: true,
      cellRenderer: (params) => {
        return (
          <>
            <span>
              {params?.data?.client_internal_limit &&
                formatNumberInternational(params?.data?.client_internal_limit)}
            </span>
          </>
        );
      },
    },
    {
      field: "Credit Insured Amount",
      headerName: "Credit Insured Amount",
      flex: 0.8,
    },
    {
      field: "totalFaceValue",
      headerName: "Credit Used",
      flex: 0.8,
      cellRenderer: (params) => {
        const cost = params?.data?.totalFaceValue;
        return (
          <>
            <span>
              {/* {convertAndAddCurrencies(cost, conversionRate, "number")} */}
              {formatNumberInternational(cost?.toFixed(2))}
            </span>
          </>
        );
      },
    },
    {
      field: "Credit Remaining",
      headerName: "Credit Remaining",
      sortable: true,
      flex: 0.8,
      valueGetter: (params) => {
        const cost = parseFloat(params?.data?.costSummary?.USD) || 0;
        const internalLimit =
          parseFloat(params?.data?.client_internal_limit) || 0;
        return internalLimit - cost;
      },
      cellRenderer: (params) => {
        const cost = params?.data?.costSummary?.USD;
        return (
          <>
            <span>
              {parseFloat(params?.data?.client_internal_limit) -
                parseFloat(cost)}
            </span>
          </>
        );
      },
    },
  ];

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        <NoContentCard
          title={"No Data"}
          // subtitle={content[type].subtitle}
          type="Pricing"
          classes="d-flex align-items-center"
          height={"7rem"}
          fontSize={"20px"}
        />
      </>
    );
  });
  return (
    <div>
      <CustomDatagrid
        columns={columns}
        rows={data?.data || []}
        getRowId={(row) => row?._id}
        CustomNoRowsOverlay={CustomNoRowsOverlay}
        tHeight="340px"
        smallTable
      />
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={data?.totalCount}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
        options={["5", "10", "15"]}
      />
    </div>
  );
};

export default ClientSummary;
