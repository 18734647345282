import React from "react";
import { useNavigate } from "react-router-dom";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import { Heading } from "../../../atoms/Heading";
import Search from "../../../atoms/Search";
import style from "./index.module.scss";
import { memo, useCallback, useEffect, useState } from "react";
import NoContentCard from "../../../atoms/NoContentCard";
import { useCookies } from "react-cookie";
import Pagination from "../../../atoms/Pagination";
import CustomButton from "../../../atoms/CustomButton";
import moment from "moment/moment";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/components/toast";
import FilterComponent from "../../../molecules/filters/FilterComponent";
import {
  counterPartyType,
  CurrencyData,
  GICSectorData,
  statusData,
  statusTypes,
  transactionType,
  transactionTypeReports,
} from "../../../../utils/staticData";
import Upload from "../../../icons/Upload";
import { downloadReport } from "../../../../services/downloadService";
import { useSelector } from "react-redux";
import { getData } from "../../../../services";
import { formatNumberInternational } from "../../../../helpers/capatalizeLetters";

const ReportsRates = () => {
  const [cookies] = useCookies(["t", "roleGroup"]);
  const navigate = useNavigate();
  const accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.Counterparties;
  const [searchData, setSearchData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [counterpartyData, setCounterPartData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loader, setLoader] = useState(true);
  const handleConfirmSelection = async () => {
    setLoader(true);
    try {
      const res = await getData({
        endpoint: "funding/getAllFundingsByRates",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          status: filters?.["Status"]
            ? filters?.["Status"]
            : ["Funded", "Draft", "Closed", "Reject"],
          currency: filters?.["Currency"],
          transactionType: filters?.["Transaction Type"],
          rate_type: filters?.["Rate Type"],
          // dueStartDate: filters?.startDate0,
          // dueEndDate: filters?.endDate0,
          // fundingStartDate: filters?.startDate1,
          // fundingEndDate: filters?.endDate1,
          group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
        },
      });
      setCounterPartData(res?.data);
    } catch (err) {
      console.log("errr", err);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    setLoader(false);
    handleConfirmSelection();
  }, [itemsPerPage, currentPage, searchData, filters]);

  const columns = [
    {
      field: "client_name",
      headerName: "Client Name",
      flex: 1,
      sortable: true,
      cellRenderer: (params) => {
        return <div className="line-truncate">{params?.data?.client_name}</div>;
      },
    },
    {
      field: "obligor_name",
      headerName: "Obligor Name",
      flex: 1,
      sortable: true,
      cellRenderer: (params) => {
        return (
          <div className="line-truncate">
            {params?.data?.client_id === params?.data?.obligor_id
              ? params?.data?.client_name
              : params?.data?.obligor_name
              ? params?.data?.obligor_name
              : params?.data?.client_name
              ? params?.data?.client_name
              : ""}
          </div>
        );
      },
    },
    {
      field: "supplier_name",
      headerName: "Supplier Name",
      flex: 1,
      sortable: true,
      cellRenderer: (params) => {
        return (
          <div className="line-truncate">
            {params?.data?.client_id === params?.data?.supplier_id
              ? params?.data?.client_name
              : params?.data?.supplier_name
              ? params?.data?.supplier_name
              : params?.data?.client_name
              ? params?.data?.client_name
              : ""}
          </div>
        );
      },
    },
    {
      field: "funder",
      headerName: "Funder",
      flex: 1,
      sortable: true,
    },
    {
      field: "Currency",
      headerName: "Currency",
      flex: 1,
      sortable: true,
      valueGetter: (params) => params?.data?.currency || "",
      valueFormatter: (params) => params.value || "",
      cellRenderer: (params) => (
        <div className={style.valueStyle}>{params.value}</div>
      ),
    },
    {
      field: "cost_value",
      headerName: "Cost Value",
      type: "number",
      sortable: true,
      flex: 1,
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => Number(params?.data?.cost_value) || 0,
      valueFormatter: (params) =>
        params.value ? formatNumberInternational(params.value) : "0",
      cellRenderer: (params) => (
        <div className={style.valueStyle}>{params.value}</div>
      ),
    },
    {
      field: "Fund Date",
      headerName: "Funding Date",
      flex: 1.2,
      sortable: true,
      valueGetter: (params) => params?.data?.funded_date || null,
      valueFormatter: (params) =>
        params.value ? moment.utc(params.value).format("YYYY-MM-DD") : "",
      cellRenderer: (params) => (
        <div className={style.valueStyle}>
          {params.value ? moment.utc(params.value).format("YYYY-MM-DD") : ""}
        </div>
      ),
    },
    {
      field: "transaction_type",
      headerName: "Transaction Type",
      flex: 1,
      sortable: true,
      cellRenderer: (params) => {
        return (
          <div
            title={
              params.data?.transaction_type === "Obligor"
                ? "Factoring/AR"
                : params.data?.transaction_type
            }
          >
            {params.data?.transaction_type === "Obligor"
              ? "Factoring/AR"
              : params.data?.transaction_type}
          </div>
        );
      },
    },
    {
      field: "Status",
      headerName: "Status",
      flex: 1,
      sortable: true,
      valueGetter: (params) => {
        return params.data?.status === "Funded" ? "Open" : params.data?.status;
      },
      cellRenderer: (params) => {
        return <span>{params.value}</span>;
      },
    },
    {
      field: "Rate Type",
      headerName: "Rate Type",
      flex: 1,
      sortable: true,
      valueGetter: (params) => {
        return params?.data?.priceDetails?.[0]?.rate_type || "";
      },
      cellRenderer: (params) => {
        return <span>{params?.data?.priceDetails?.[0]?.rate_type || ""}</span>;
      },
    },

    {
      field: "Top Rate",
      headerName: "Top Rate",
      flex: 1,
      sortable: true,
      type: "number",
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => {
        return params?.data?.priceDetails?.[0]?.top_rate || null;
      },
      cellRenderer: (params) => {
        return (
          <span>{params?.data?.priceDetails?.[0]?.top_rate || "N/A"}</span>
        );
      },
    },
    {
      field: "Base Rate",
      headerName: "Base Rate",
      flex: 1,
      sortable: true,
      type: "number",
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => {
        return params?.data?.latestRate?.[0]?.base_rate || null;
      },
      cellRenderer: (params) => {
        return <span>{params?.data?.latestRate?.[0]?.base_rate || ""}</span>;
      },
    },

    {
      field: "Total Rate",
      headerName: "Total Rate",
      flex: 1,
      sortable: true,
      type: "number",
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => {
        return params?.data?.priceDetails?.[0]?.top_rate &&
          params?.data?.latestRate?.[0]?.base_rate
          ? params?.data?.priceDetails?.[0]?.top_rate +
              params?.data?.latestRate?.[0]?.base_rate
          : params?.data?.priceDetails?.[0]?.top_rate || null;
      },
      cellRenderer: (params) => {
        return (
          <>
            {params?.data?.priceDetails && (
              <span>
                {params?.data?.latestRate?.[0]?.base_rate
                  ? params?.data?.priceDetails?.[0]?.top_rate +
                    params?.data?.latestRate?.[0]?.base_rate
                  : params?.data?.priceDetails?.[0]?.top_rate}
              </span>
            )}
          </>
        );
      },
    },

    // {
    //   field: "Base Rate ID",
    //   headerName: "Base Rate ID",
    //   flex: 1,
    //   sortable: true,
    //   cellRenderer: (params) => {
    //     return (
    //       <>
    //         <span>{}</span>
    //       </>
    //     );
    //   },
    // },
    {
      field: "Advance Rate",
      headerName: "Advance Rate",
      flex: 1,
      sortable: true,
      type: "number",
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => {
        return params?.data?.priceDetails?.[0]?.advance_rate || null;
      },
      cellRenderer: (params) => {
        return (
          <>
            {params?.data?.priceDetails && (
              <span>{params?.data?.priceDetails?.[0]?.advance_rate}</span>
            )}
          </>
        );
      },
    },
  ];

  const CustomNoRowsOverlay = memo(() => {
    return (
      !loader && (
        <>
          <NoContentCard
            title="No Rates created yet!"
            tag="Add Counterparties"
            type="Counterparty"
            classes="d-flex align-items-center"
          />
        </>
      )
    );
  });

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const filtersData = {
    "Transaction Type": transactionTypeReports,
    Currency: CurrencyData,
    Status: statusTypes,
    "Rate Type": [
      { label: "Floating", value: "Floating" },
      { label: "Flat", value: "Flat" },
    ],
  };

  const handleDownload = async () => {
    const res = await downloadReport({
      endpoint: "funding/exportRates",
      token: cookies.t,
      fileName: "Rates",
      params: {
        search: searchData,
        status: filters?.["Status"]
          ? filters?.["Status"]
          : ["Funded", "Draft", "Closed", "Reject"],
        currency: filters?.["Currency"],
        transactionType: filters?.["Transaction Type"],
        rate_type: filters?.["Rate Type"],
        // dueStartDate: filters?.startDate0,
        // dueEndDate: filters?.endDate0,
        // fundingStartDate: filters?.startDate1,
        // fundingEndDate: filters?.endDate1,
        group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
      },
    });

    if (res) {
      toast.success("Rates Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  return (
    <>
      <div className={style.headingSearchWrap}>
        <div>
          <Heading className="HeadingStyle">Rates</Heading>
        </div>
        <div className={style.searchButton}>
          <Search
            placeholder="Search by name"
            onSearchEmit={(data) => {
              setSearchData(data);
            }}
          />

          {roleData && roleData?.export && (
            <CustomButton
              text="Export"
              handleClick={() => handleDownload()}
              classes={"filter-contain-btn"}
              iconRequired
              icon={<Upload />}
            />
          )}
          <FilterComponent
            filterData={filtersData}
            onFilterChange={(filters) => {
              setFilters(filters);
            }}
          />
        </div>
      </div>

      <CustomDatagrid
        rows={counterpartyData?.[0]?.fundingData || []}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        loader={loader}
        isRowsVisible
        isValueVisible
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={counterpartyData?.[0]?.pageDetails?.count}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
    </>
  );
};

export default ReportsRates;
