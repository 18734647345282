// import GuestGuard from './GuestGuard';
// import AuthLayout from '../components/layout/AuthLayout';
// import Login from '../components/pages/Auth/Login';
// import Verification from '../components/pages/Auth/verifyOTP';
// import { Navigate, Routes, Route } from 'react-router-dom';
// import { Suspense } from 'react';
// import DashboardLayout from '../components/layout/DashboardLayout';
// import PriceManagement from '../components/pages/dashboard/setting/price';
// import AddPrice from '../components/pages/dashboard/setting/price/addPrice';
// import ForgotPassword from '../components/pages/Auth/forgotPassword';
// import ResetOrCreatePass from '../components/pages/Auth/createOrResetPassword';
// import AuthGuard from './AuthGuard';
// import BrokerManagement from '../components/pages/dashboard/setting/broker';
// import AddBroker from '../components/pages/dashboard/setting/broker/addBroker';
// import SignerManagement from '../components/pages/dashboard/setting/singer';
// import InsuranceManagement from '../components/pages/dashboard/setting/insurance';
// import AddSigner from '../components/pages/dashboard/setting/singer/addSigner';
// import AddInsurance from '../components/pages/dashboard/setting/insurance/addInsurance';
// import CounterParty from '../components/pages/dashboard/counterparty';
// import AddCounterparty from '../components/pages/dashboard/counterparty/add';
// import InvestorManagement from '../components/pages/dashboard/setting/investor';
// import AddInvestor from '../components/pages/dashboard/setting/investor/addInvestor';
// import PaymentManagement from '../components/pages/dashboard/payments';
// import AddPayment from '../components/pages/dashboard/payments/addPayment';
// import FundingManagement from '../components/pages/dashboard/funding';
// import AddFunding from '../components/pages/dashboard/funding/addFunding';
// import RoleMangement from '../components/pages/dashboard/setting/RoleManagement';
// import AddAccess from '../components/pages/dashboard/setting/RoleManagement/Add/index';

// import AddCovenant from '../components/pages/dashboard/setting/covenant/addCovenant';
// import Covenant from '../components/pages/dashboard/setting/covenant';
// import ReportingRequirement from '../components/pages/dashboard/setting/covenant/reportingRequirement';
// import UserManagement from '../components/pages/dashboard/setting/UserManagement';

// const routes = [
//   {
//     path: '/',
//     element: <Navigate to="/auth" />,
//   },
//   {
//     path: 'auth',
//     element: (
//       <GuestGuard>
//         <AuthLayout />
//       </GuestGuard>
//     ),
//     children: [
//       {
//         index: true,
//         element: <Login />,
//       },
//       {
//         path: 'login',
//         element: <Login />,
//       },
//       {
//         path: 'verify',
//         element: <Verification />,
//       },
//       {
//         path: 'forgot-password',
//         element: <ForgotPassword />,
//       },
//       {
//         path: 'reset-password',
//         element: <ResetOrCreatePass type="reset" />,
//       },
//       {
//         path: 'create-password',
//         element: <ResetOrCreatePass type="create" />,
//       },
//     ],
//   },
//   {
//     path: 'dashboard/*',
//     element: (
//       <AuthGuard>
//         <DashboardLayout />
//       </AuthGuard>
//     ),
//   },
// ];
// export const dashboardRoute = (
//   <Suspense>
//     <Routes>
//       <Route element={<></>}></Route>
//       <Route
//         path="counterparty"
//         element={
//           <AuthGuard>
//             <CounterParty />
//           </AuthGuard>
//         }
//       />

//       <Route
//         path="counterparty/add/:id?"
//         element={
//           <AuthGuard>
//             <AddCounterparty />
//           </AuthGuard>
//         }
//       />
//       <Route
//         path="counterparty/add/:id?"
//         element={
//           <AuthGuard>
//             <AddCounterparty />
//           </AuthGuard>
//         }
//       ></Route>
//       <Route
//         path="funding"
//         element={
//           <AuthGuard>
//             <FundingManagement />
//           </AuthGuard>
//         }
//       />
//       <Route
//         path="funding/add"
//         element={
//           <AuthGuard>
//             <AddFunding />
//           </AuthGuard>
//         }
//       />
//       <Route
//         path="setting/price"
//         element={
//           <AuthGuard>
//             <PriceManagement />
//           </AuthGuard>
//         }
//       ></Route>
//       <Route
//         path="setting/price/add"
//         element={
//           <AuthGuard>
//             <AddPrice />
//           </AuthGuard>
//         }
//       ></Route>
//       <Route
//         path="setting/price/edit/:id?"
//         element={
//           <AuthGuard>
//             <AddPrice type="edit" />
//           </AuthGuard>
//         }
//       ></Route>
//       <Route
//         path="setting/covenant"
//         element={
//           <AuthGuard>
//             <Covenant />
//           </AuthGuard>
//         }
//       ></Route>
//       <Route
//         path="setting/covenant/add/"
//         element={
//           <AuthGuard>
//             <AddCovenant type="add" />
//           </AuthGuard>
//         }
//       ></Route>
//       <Route
//         path="setting/covenant/edit/:id?"
//         element={
//           <AuthGuard>
//             <AddCovenant type="edit" />
//           </AuthGuard>
//         }
//       ></Route>
//       <Route
//         path="setting/covenant/reporting-requirement/:id?"
//         element={
//           <AuthGuard>
//             <ReportingRequirement />
//           </AuthGuard>
//         }
//       />
//       <Route
//         path="/broker"
//         element={
//           <AuthGuard>
//             <BrokerManagement />
//           </AuthGuard>
//         }
//       ></Route>
//       <Route
//         path="/broker/add"
//         element={
//           <AuthGuard>
//             <AddBroker type='add'/>
//           </AuthGuard>
//         }
//       ></Route>
//           <Route
//         path="/broker/edit/:id?"
//         element={
//           <AuthGuard>
//             <AddBroker type='edit'/>
//           </AuthGuard>
//         }
//       ></Route>
//       <Route
//         path="/signer"
//         element={
//           <AuthGuard>
//             <SignerManagement />
//           </AuthGuard>
//         }
//       ></Route>
//       <Route
//         path="/signer/add"
//         element={
//           <AuthGuard>
//             <AddSigner type='add'/>
//           </AuthGuard>
//         }
//       ></Route>
//        <Route
//         path="/signer/edit/:id?"
//         element={
//           <AuthGuard>
//             <AddSigner type='edit'/>
//           </AuthGuard>
//         }
//       />
//       <Route
//         path="/insurance"
//         element={
//           <AuthGuard>
//             <InsuranceManagement />
//           </AuthGuard>
//         }
//       ></Route>
//       <Route
//         path="/insurance/add"
//         element={
//           <AuthGuard>
//             <AddInsurance type='add'/>
//           </AuthGuard>
//         }
//       ></Route>
//             <Route
//         path="/insurance/edit/:id?"
//         element={
//           <AuthGuard>
//             <AddInsurance type='edit'/>
//           </AuthGuard>
//         }
//       ></Route>
//       <Route
//         path="/investor"
//         element={
//           <AuthGuard>
//             <InvestorManagement />
//           </AuthGuard>
//         }
//       />
//       <Route
//         path="/investor/add"
//         element={
//           <AuthGuard>
//             <AddInvestor type='add'/>
//           </AuthGuard>
//         }
//       />
//           <Route
//         path="/investor/edit/:id?"
//         element={
//           <AuthGuard>
//             <AddInvestor type='edit'/>
//           </AuthGuard>
//         }
//       />
//       <Route
//         path="/payments"
//         element={
//           <AuthGuard>
//             <PaymentManagement />
//           </AuthGuard>
//         }
//       />
//       <Route
//         path="/payments/add/:id?"
//         element={
//           <AuthGuard>
//             <AddPayment />
//           </AuthGuard>
//         }
//       />
//       <Route
//         path="setting/role-management"
//         element={
//           <AuthGuard>
//             <RoleMangement />
//           </AuthGuard>
//         }
//       />

//       <Route
//         path="setting/role-management/add"
//         element={
//           <AuthGuard>
//             <AddAccess />
//           </AuthGuard>
//         }
//       />

//       <Route
//         path="setting/role-management/edit/:id"
//         element={
//           <AuthGuard>
//             <AddAccess type="edit" />
//           </AuthGuard>
//         }
//       />

//       <Route
//         path="setting/user-management"
//         element={
//           <AuthGuard>
//             <UserManagement />
//           </AuthGuard>
//         }
//       />
//     </Routes>
//   </Suspense>
// );
// export default routes;

import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import GuestGuard from "./GuestGuard";
import AuthLayout from "../components/layout/AuthLayout";
import Login from "../components/pages/Auth/Login";
import Verification from "../components/pages/Auth/verifyOTP";
import ForgotPassword from "../components/pages/Auth/forgotPassword";
import ResetOrCreatePass from "../components/pages/Auth/createOrResetPassword";
import AuthGuard from "./AuthGuard";
import DashboardLayout from "../components/layout/DashboardLayout";
import PriceManagement from "../components/pages/dashboard/setting/price";
import AddPrice from "../components/pages/dashboard/setting/price/addPrice";
import BrokerManagement from "../components/pages/dashboard/setting/broker";
import AddBroker from "../components/pages/dashboard/setting/broker/addBroker";
import SignerManagement from "../components/pages/dashboard/setting/singer";
import InsuranceManagement from "../components/pages/dashboard/setting/insurance";
import AddSigner from "../components/pages/dashboard/setting/singer/addSigner";
import AddInsurance from "../components/pages/dashboard/setting/insurance/addInsurance";
import CounterParty from "../components/pages/dashboard/counterparty";
import AddCounterparty from "../components/pages/dashboard/counterparty/add";
import InvestorManagement from "../components/pages/dashboard/setting/investor";
import AddInvestor from "../components/pages/dashboard/setting/investor/addInvestor";
import PaymentManagement from "../components/pages/dashboard/payments";
import AddPayment from "../components/pages/dashboard/payments/addPayment";
import FundingManagement from "../components/pages/dashboard/funding";
import AddFunding from "../components/pages/dashboard/funding/addFunding";
import RoleMangement from "../components/pages/dashboard/setting/RoleManagement";
import AddAccess from "../components/pages/dashboard/setting/RoleManagement/EAdd/index";
import AddCovenant from "../components/pages/dashboard/setting/covenant/addCovenant";
import Covenant from "../components/pages/dashboard/setting/covenant";

import Reporting from "../components/pages/dashboard/setting/reportingRequirement";
import AddReporting from "../components/pages/dashboard/setting/reportingRequirement/add";

import ReportingRequirement from "../components/pages/dashboard/setting/covenant/reportingRequirement";
import UserManagement from "../components/pages/dashboard/setting/UserManagement/tabs";
import Notes from "../components/pages/dashboard/notes/index";
import Tools from "../components/pages/dashboard/Tools";
import ClientQA from "../components/pages/dashboard/notes/Forms/ClientQA";
import ClientInternalMonitoring from "../components/pages/dashboard/notes/Forms/ClientInternalMonitoring";
import ProspectiveClientQA from "../components/pages/dashboard/notes/Forms/ProspectiveClientQA";
import ProspectiveClientOnboarding from "../components/pages/dashboard/notes/Forms/ProspectiveClientOnboarding";
import ClientMonitoring from "../components/pages/dashboard/notes/Forms/ClientMonitoring";
import PlatformUpdate from "../components/pages/dashboard/notes/Forms/PlatformUpdate";
import BrokerUpdate from "../components/pages/dashboard/notes/Forms/BrokerUpdate";
import ViewCounterparty from "../components/pages/dashboard/counterparty/view";
import ViewDraftFunding from "../components/pages/dashboard/funding/viewFunding/viewDraftFunding";
import EditProfile from "../components/pages/dashboard/edit-profile/index";
import ChangePassword from "../components/pages/dashboard/edit-profile/change-password";
import Documents from "../components/pages/dashboard/Tools/Documents";
import RateManagement from "../components/pages/dashboard/setting/RateManagement";
import AddRate from "../components/pages/dashboard/setting/RateManagement/AddRate";
import UpdateRate from "../components/pages/dashboard/setting/RateManagement/AddRate/update";
import General from "../components/pages/dashboard/notes/Forms/general";
import ToolsView from "../components/pages/dashboard/Tools/ToolsView";
import FundedView from "../components/pages/dashboard/funding/Funded";
// import ReviewView from "../components/pages/dashboard/payments/View/ReviewView";
import RoleGroupMangement from "../components/pages/dashboard/setting/RoleGroup";
import ViewCounterTabs from "../components/pages/dashboard/counterparty/view/tabs";
import ProfileMonitoring from "../components/pages/dashboard/profileMonitoring";
import NotFoundPage from "../components/pages/dashboard/NotFound";
import ReviewView from "../components/pages/dashboard/payments/View/ReviewView";
import DetailsMonitoring from "../components/pages/dashboard/profileMonitoring/Tab";

import Funds from "../components/pages/dashboard/funds";
import FundsView from "../components/pages/dashboard/funds/View";
import DashboardMonitor from "../components/pages/dashboard/profileMonitoring/Tab/dashboard";
import Valuation from "../components/pages/dashboard/profileMonitoring/Tab/Valuation";
import ValuationInfo from "../components/pages/dashboard/profileMonitoring/Tab/Valuation/Valuation";
import Analysis from "../components/pages/dashboard/profileMonitoring/Tab/Analysis";
import AnalysisFileUplaod from "../components/pages/dashboard/profileMonitoring/Tab/Analysis/FileUpload";
import ReportsPortfolio from "../components/pages/dashboard/Reports/portfolio";
import ReportsRates from "../components/pages/dashboard/Reports/rates";
import ReportsSector from "../components/pages/dashboard/Reports/sectorGeaopraphy";
import ReportsInsurance from "../components/pages/dashboard/Reports/insurance";
import ReportsDue from "../components/pages/dashboard/Reports/due";
import ReportsDay from "../components/pages/dashboard/Reports/accuralDay";
import ReportsICY from "../components/pages/dashboard/Reports/accuralIcy";
import ReportsExp from "../components/pages/dashboard/Reports/accuralExp";
import ReportsDaily from "../components/pages/dashboard/Reports/accuralDaily";
import ReportsBroker from "../components/pages/dashboard/Reports/brokers";
import ReportsReporting from "../components/pages/dashboard/Reports/reporting";
import ReportsCovenants from "../components/pages/dashboard/Reports/covenants";
import ReportsIR from "../components/pages/dashboard/Reports/Ir";
import FinancialAnalysis from "../components/pages/dashboard/profileMonitoring/Tab/Analysis/links";
import AnalysisView from "../components/pages/dashboard/profileMonitoring/Tab/Analysis/anlysisView";
import Dashboard from "../components/pages/dashboard/dashboard";

// Define your routes here
const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/auth" />,
  },
  {
    path: "auth",
    element: (
      <GuestGuard>
        <AuthLayout />
      </GuestGuard>
    ),
    children: [
      { index: true, element: <Login /> },
      { path: "login", element: <Login /> },
      { path: "verify", element: <Verification /> },
      { path: "forgot-password", element: <ForgotPassword /> },
      { path: "reset-password", element: <ResetOrCreatePass type="reset" /> },
      { path: "create-password", element: <ResetOrCreatePass type="create" /> },
    ],
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: "not-found",
        element: <NotFoundPage />,
      },
      {
        path: "counterparty",
        element: (
          <AuthGuard label={"Counterparties"} value={"hide"}>
            <CounterParty />
          </AuthGuard>
        ),
      },
      {
        path: "counterparty/add/:id?/:isDraft?",
        element: (
          <AuthGuard label={"Counterparties"} value={"add/edit"}>
            <AddCounterparty />
          </AuthGuard>
        ),
      },
      {
        path: "counterparty/view/:id?",
        element: (
          <AuthGuard label={"Counterparties"} value={"view"}>
            <ViewCounterTabs />
          </AuthGuard>
        ),
      },
      {
        path: "funding",
        element: (
          <AuthGuard label={"Funding"} value={"hide"}>
            <FundingManagement />
          </AuthGuard>
        ),
      },
      {
        path: "funding/add",
        element: (
          <AuthGuard label={"Funding"} value={"add/edit"}>
            <AddFunding />
          </AuthGuard>
        ),
      },
      {
        path: "funding/draft/view/:id?",
        element: (
          <AuthGuard label={"Funding"} value={"view"}>
            <ViewDraftFunding />
          </AuthGuard>
        ),
      },
      {
        path: "funding/view/:id?",
        element: (
          <AuthGuard label={"Funding"} value={"view"}>
            <FundedView />
          </AuthGuard>
        ),
      },
      {
        path: "funds",
        element: (
          <AuthGuard label={"Funds"} value={"view"}>
            <Funds />
          </AuthGuard>
        ),
      },
      {
        path: "investor/view/:id",
        element: (
          <AuthGuard label={"Funds"} value={"view"}>
            <FundsView />
          </AuthGuard>
        ),
      },
      {
        path: "setting/price",
        element: (
          <AuthGuard label={"Pricing"} value={"hide"}>
            <PriceManagement />
          </AuthGuard>
        ),
      },
      {
        path: "setting/price/add",
        element: (
          <AuthGuard label={"Pricing"} value={"add/edit"}>
            <AddPrice />
          </AuthGuard>
        ),
      },
      {
        path: "setting/price/edit/:id?",
        element: (
          <AuthGuard label={"Pricing"} value={"add/edit"}>
            <AddPrice type="edit" />
          </AuthGuard>
        ),
      },
      {
        path: "setting/covenant",
        element: (
          <AuthGuard label={"Covenants"} value={""}>
            <Covenant />
          </AuthGuard>
        ),
      },
      {
        path: "setting/covenant/add",
        element: (
          <AuthGuard label={"Covenants"} value={"add/edit"}>
            <AddCovenant type="add" />
          </AuthGuard>
        ),
      },
      {
        path: "setting/covenant/edit/:id?",
        element: (
          <AuthGuard label={"Covenants"} value={"add/edit"}>
            <AddCovenant type="edit" />
          </AuthGuard>
        ),
      },
      {
        path: "setting/covenant/reporting-requirement/:id?",
        element: (
          <AuthGuard label={"Covenants"} value={"add/edit"}>
            <ReportingRequirement />
          </AuthGuard>
        ),
      },
      {
        path: "setting/reporting-requirements",
        element: (
          <AuthGuard label={"Reporting Requirements"} value={"hide"}>
            <Reporting />
          </AuthGuard>
        ),
      },
      {
        path: "setting/reporting-requirements/add",
        element: (
          <AuthGuard label={"Reporting Requirements"} value={"add/edit"}>
            <AddReporting type="add" />
          </AuthGuard>
        ),
      },
      {
        path: "setting/reporting-requirements/edit/:id?",
        element: (
          <AuthGuard label={"Reporting Requirements"} value={"add/edit"}>
            <AddReporting type="edit" />
          </AuthGuard>
        ),
      },
      {
        path: "setting/reporting-requirements/reporting-requirement/:id?",
        element: (
          <AuthGuard label={"Reporting Requirements"} value={"add/edit"}>
            <ReportingRequirement />
          </AuthGuard>
        ),
      },
      {
        path: "broker",
        element: (
          <AuthGuard label={"Brokers"} value={"hide"}>
            <BrokerManagement />
          </AuthGuard>
        ),
      },
      {
        path: "broker/add",
        element: (
          <AuthGuard label={"Brokers"} value={"add/edit"}>
            <AddBroker type="add" />
          </AuthGuard>
        ),
      },
      {
        path: "broker/edit/:id?/:counter_id?",
        element: (
          <AuthGuard label={"Brokers"} value={"add/edit"}>
            <AddBroker type="edit" />
          </AuthGuard>
        ),
      },
      {
        path: "signer",
        element: (
          <AuthGuard label={"Signers"} value={"hide"}>
            <SignerManagement />
          </AuthGuard>
        ),
      },
      {
        path: "signer/add",
        element: (
          <AuthGuard label={"Signers"} value={"add/edit"}>
            <AddSigner type="add" />
          </AuthGuard>
        ),
      },
      {
        path: "signer/edit/:id?/:counter_id?",
        element: (
          <AuthGuard label={"Signers"} value={"add/edit"}>
            <AddSigner type="edit" />
          </AuthGuard>
        ),
      },
      {
        path: "insurance",
        element: (
          <AuthGuard label={"Insurance"} value={"hide"}>
            <InsuranceManagement />
          </AuthGuard>
        ),
      },
      {
        path: "insurance/add",
        element: (
          <AuthGuard label={"Insurance"} value={"add/edit"}>
            <AddInsurance type="add" />
          </AuthGuard>
        ),
      },
      {
        path: "insurance/edit/:id?/:counter_id/:client_id?",
        element: (
          <AuthGuard label={"Insurance"} value={"add/edit"}>
            <AddInsurance type="edit" />
          </AuthGuard>
        ),
      },
      {
        path: "investor",
        element: (
          <AuthGuard label={"Investors"} value={"hide"}>
            <InvestorManagement />
          </AuthGuard>
        ),
      },
      {
        path: "monitoring/dashboard",
        element: (
          <AuthGuard label={""} value={""}>
            <ProfileMonitoring />
          </AuthGuard>
        ),
      },
      {
        path: "monitoring/details/:id?",
        element: (
          <AuthGuard label={""} value={""}>
            <DashboardMonitor />
          </AuthGuard>
        ),
      },
      {
        path: "monitoring/valuation",
        element: (
          <AuthGuard label={""} value={""}>
            <Valuation />
          </AuthGuard>
        ),
      },
      {
        path: "monitoring/valuation/:date?",
        element: (
          <AuthGuard label={""} value={""}>
            <ValuationInfo />
          </AuthGuard>
        ),
      },
      {
        path: "monitoring/analysis",
        element: (
          <AuthGuard label={""} value={""}>
            <FinancialAnalysis />
          </AuthGuard>
        ),
      },
      {
        path: "monitoring/financial-analysis",
        element: (
          <AuthGuard label={""} value={""}>
            <Analysis />
          </AuthGuard>
        ),
      },
      {
        path: "monitoring/analysis/review",
        element: (
          <AuthGuard label={""} value={""}>
            <Analysis type="Review" />
          </AuthGuard>
        ),
      },
      {
        path: "monitoring/analysis/review/:id?/:date?",
        element: (
          <AuthGuard label={""} value={""}>
            <AnalysisView />
          </AuthGuard>
        ),
      },
      {
        path: "monitoring/analysis/details/:id?/:date?",
        element: (
          <AuthGuard label={""} value={""}>
            <AnalysisFileUplaod />
          </AuthGuard>
        ),
      },
      {
        path: "investor/add",
        element: (
          <AuthGuard label={"Investors"} value={"add/edit"}>
            <AddInvestor type="add" />
          </AuthGuard>
        ),
      },
      {
        path: "investor/edit/:id?",
        element: (
          <AuthGuard label={"Investors"} value={"add/edit"}>
            <AddInvestor type="edit" />
          </AuthGuard>
        ),
      },
      {
        path: "payments",
        element: (
          <AuthGuard label={"Payment"} value={"hide"}>
            <PaymentManagement />
          </AuthGuard>
        ),
      },
      {
        path: "payments/review/view/:id?",
        element: (
          <AuthGuard label={"Payment"} value={""}>
            <ReviewView />
          </AuthGuard>
        ),
      },
      {
        path: "payments/add/:id?",
        element: (
          <AuthGuard label={"Payment"} value={"add/edit"}>
            <AddPayment />
          </AuthGuard>
        ),
      },
      {
        path: "setting/role-management",
        element: (
          <AuthGuard label={"Role Management"} value={"hide"}>
            <RoleMangement />
          </AuthGuard>
        ),
      },
      {
        path: "setting/role-group-management",
        element: (
          <AuthGuard label={"Role Management"} value={"hide"}>
            <RoleGroupMangement />{" "}
          </AuthGuard>
        ),
      },
      {
        path: "setting/user-management/add",
        element: (
          <AuthGuard label={"User Management"} value={"add/edit"}>
            <AddAccess />
          </AuthGuard>
        ),
      },
      {
        path: "setting/user-management/edit/:id",
        element: (
          <AuthGuard label={"Role Management"} value={"add/edit"}>
            <AddAccess type="edit" />
          </AuthGuard>
        ),
      },
      {
        path: "setting/user-management",
        element: (
          <AuthGuard label={"User Management"} value={"hide"}>
            <UserManagement />
          </AuthGuard>
        ),
      },
      {
        path: "setting/rate-management",
        element: (
          <AuthGuard label={"Rates"} value={"hide"}>
            <RateManagement />
          </AuthGuard>
        ),
      },
      {
        path: "setting/rate-management/add",
        element: (
          <AuthGuard label={"Rates"} value={"add/edit"}>
            <AddRate />
          </AuthGuard>
        ),
      },
      {
        path: "setting/rate-management/edit/:id?/:date?",
        element: (
          <AuthGuard label={"Rates"} value={"add/edit"}>
            <AddRate type="edit" />
          </AuthGuard>
        ),
      },
      {
        path: "setting/rate-management/update",
        element: (
          <AuthGuard label={"Rates"} value={"add/edit"}>
            <UpdateRate/>
          </AuthGuard>
        ),
      },
      {
        path: "notes",
        element: (
          <AuthGuard label={"Notes"} value={"hide"}>
            <Notes />
          </AuthGuard>
        ),
      },
      {
        path: "notes/clientQ&A/:id?",
        element: (
          <AuthGuard label={"Notes"} value={"add/edit"}>
            <ClientQA />
          </AuthGuard>
        ),
      },
      {
        path: "notes/clientInternalMonitoring/:id?",
        element: (
          <AuthGuard label={"Notes"} value={"add/edit"}>
            <ClientInternalMonitoring />
          </AuthGuard>
        ),
      },
      {
        path: "notes/prospectiveQ&A/:id?",
        element: (
          <AuthGuard label={"Notes"} value={"add/edit"}>
            <ProspectiveClientQA />
          </AuthGuard>
        ),
      },
      {
        path: "notes/prospectiveOnboarding/:id?",
        element: (
          <AuthGuard label={"Notes"} value={"add/edit"}>
            <ProspectiveClientOnboarding />
          </AuthGuard>
        ),
      },
      {
        path: "notes/clientMonitoring/:id?",
        element: (
          <AuthGuard label={"Notes"} value={"add/edit"}>
            <ClientMonitoring />
          </AuthGuard>
        ),
      },
      {
        path: "notes/platformUpdate/:id?",
        element: (
          <AuthGuard label={"Notes"} value={"add/edit"}>
            <PlatformUpdate />
          </AuthGuard>
        ),
      },
      {
        path: "notes/brokerUpdate/:id?",
        element: (
          <AuthGuard label={"Notes"} value={"add/edit"}>
            <BrokerUpdate />
          </AuthGuard>
        ),
      },
      {
        path: "notes/general/:id?",
        element: (
          <AuthGuard label={"Notes"} value={"add/edit"}>
            <General />
          </AuthGuard>
        ),
      },

      {
        path: "notes/view/clientQ&A/:id?",
        element: (
          <AuthGuard label={"Notes"} value={"view"}>
            <ClientQA mode="View" />
          </AuthGuard>
        ),
      },
      {
        path: "notes/view/clientInternalMonitoring/:id?",
        element: (
          <AuthGuard label={"Notes"} value={"view"}>
            <ClientInternalMonitoring mode="View" />
          </AuthGuard>
        ),
      },
      {
        path: "notes/view/prospectiveQ&A/:id?",
        element: (
          <AuthGuard label={"Notes"} value={"view"}>
            <ProspectiveClientQA mode="View" />
          </AuthGuard>
        ),
      },
      {
        path: "notes/view/prospectiveOnboarding/:id?",
        element: (
          <AuthGuard label={"Notes"} value={"view"}>
            <ProspectiveClientOnboarding mode="View" />
          </AuthGuard>
        ),
      },
      {
        path: "notes/view/clientMonitoring/:id?",
        element: (
          <AuthGuard label={"Notes"} value={"view"}>
            <ClientMonitoring mode="View" />{" "}
          </AuthGuard>
        ),
      },
      {
        path: "notes/view/platformUpdate/:id?",
        element: (
          <AuthGuard label={"Notes"} value={"view"}>
            <PlatformUpdate mode="View" />
          </AuthGuard>
        ),
      },
      {
        path: "notes/view/brokerUpdate/:id?",
        element: (
          <AuthGuard label={"Notes"} value={"view"}>
            <BrokerUpdate mode="View" />
          </AuthGuard>
        ),
      },
      {
        path: "notes/view/general/:id?",
        element: (
          <AuthGuard label={"Notes"} value={"view"}>
            <General mode="View" />
          </AuthGuard>
        ),
      },
      {
        path: "tools",
        element: (
          <AuthGuard label={"Tools"} value={"hide"}>
            <Tools />
          </AuthGuard>
        ),
      },
      {
        path: "profile-monitoring",
        element: (
          <AuthGuard label={"Monitoring"} value={"hide"}>
            <ProfileMonitoring />
          </AuthGuard>
        ),
      },
      {
        path: "profile-monitoring",
        element: (
          <AuthGuard label={"Monitoring"} value={"hide"}>
            <DetailsMonitoring />
          </AuthGuard>
        ),
      },
      {
        path: "tools/view/:id?",
        element: (
          <AuthGuard label={"Tools"} value={"add/edit"}>
            <ToolsView />
          </AuthGuard>
        ),
      },
      {
        path: "tools/documents",
        element: (
          <AuthGuard label={"Tools"} value={"add/edit"}>
            <Documents />
          </AuthGuard>
        ),
      },
      {
        path: "edit-profile",
        element: (
          <AuthGuard label={""} value={""}>
            <EditProfile />
          </AuthGuard>
        ),
      },
      {
        path: "change-password",
        element: (
          <AuthGuard label={""} value={""}>
            <ChangePassword />
          </AuthGuard>
        ),
      },
      {
        path: "reports/portfolio",
        element: (
          <AuthGuard label={""} value={""}>
            <ReportsPortfolio />
          </AuthGuard>
        ),
      },
      {
        path: "reports/accural_day",
        element: (
          <AuthGuard label={""} value={""}>
            <ReportsDay />
          </AuthGuard>
        ),
      },
      {
        path: "reports/accural_daily",
        element: (
          <AuthGuard label={""} value={""}>
            <ReportsDaily />
          </AuthGuard>
        ),
      },
      {
        path: "reports/acuural_exp",
        element: (
          <AuthGuard label={""} value={""}>
            <ReportsExp />
          </AuthGuard>
        ),
      },
      {
        path: "reports/accural_icy",
        element: (
          <AuthGuard label={""} value={""}>
            <ReportsICY />
          </AuthGuard>
        ),
      },
      {
        path: "reports/sector_geography",
        element: (
          <AuthGuard label={""} value={""}>
            <ReportsSector />
          </AuthGuard>
        ),
      },
      {
        path: "reports/due",
        element: (
          <AuthGuard label={""} value={""}>
            <ReportsDue />
          </AuthGuard>
        ),
      },
      {
        path: "reports/insurance",
        element: (
          <AuthGuard label={""} value={""}>
            <ReportsInsurance />
          </AuthGuard>
        ),
      },
      {
        path: "reports/brokers",
        element: (
          <AuthGuard label={""} value={""}>
            <ReportsBroker />
          </AuthGuard>
        ),
      },
      {
        path: "reports/rates",
        element: (
          <AuthGuard label={""} value={""}>
            <ReportsRates />
          </AuthGuard>
        ),
      },
      {
        path: "reports/reporting",
        element: (
          <AuthGuard label={""} value={""}>
            <ReportsReporting />
          </AuthGuard>
        ),
      },
      {
        path: "reports/covenants",
        element: (
          <AuthGuard label={""} value={""}>
            <ReportsCovenants />
          </AuthGuard>
        ),
      },
      {
        path: "reports/ir",
        element: (
          <AuthGuard label={""} value={""}>
            <ReportsIR />
          </AuthGuard>
        ),
      },
    ],
  },
]);

export default router;
