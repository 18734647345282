import { useEffect, useState } from "react";
import CounterPartyBasicInfo, { ClientReportingInformation } from "../counterpartyBasicInfo";
import style from "./index.module.scss";
import { getData, postData } from "../../../../../../services";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import moment from "moment";
import CustomButton from "../../../../../atoms/CustomButton";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../../assets/styles/components/toast";

const MonitoringSend = ({ creditInfo }) => {
  const [cookies] = useCookies(["t"]);
  const { id } = useParams();
  const [clientData, setClientData] = useState({});
  const [reportingData, setReportingData] = useState();

  const getCounterDataById = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getCounterPartyById",
        token: cookies.t,
        params: {
          id: id,
        },
      });
      setClientData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  const getReportingRequirementById = async () => {
    try {
      const res = await getData({
        endpoint:
          "reporting-requirements/getPeportingRequiremetsDetailsByClientId",
        params: {
          client_id: id,
        },
        token: cookies.t,
      });
      setReportingData(res?.data?.reporting_requirement_details);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (id) {
      getCounterDataById();
      getReportingRequirementById();
    }
  }, [id]);

  const handleSendInformation = async ({ type }) => {
    try {
      const res = await getData({
        endpoint: "couterParty/sendNotification",
        token: cookies.t,
        params: {
          client_id: id,
          notificationType: type,
        },
      });
      if (res) {
        toast.success("Mail has been sent successfully", {
          duration: 2000,
          style: confimationStyles,
        });
      }
      console.log("res", res);
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <>
      <div className={style.duesWrap}>
        <div className={style.clientBasicInfo}>
          <CounterPartyBasicInfo
            clientData={clientData}
            creditInfo={creditInfo}
          />
        </div>
        <div className={style.tableInfoWrap}>
          <ClientReportingInformation clientData={clientData}/>
          <div className={style.ButtonWrap}>
            <CustomButton
              classes="mt-2 d-flex"
              type="btn-primary"
              text="Send Request ('Information Notice')"
              handleClick={() => {
                handleSendInformation({ type: "Information Notice" });
              }}
            />
            <CustomButton
              classes="mt-2 d-flex"
              type="btn-primary"
              text="Send Request ('Coming Due Notice')"
              handleClick={() => {
                handleSendInformation({ type: "Coming Due Notice" });
              }}
            />
            <CustomButton
              classes="mt-2 d-flex"
              type="btn-primary"
              text="Send Request ('Due Notice')"
              handleClick={() => {
                handleSendInformation({ type: "Due Notice" });
              }}
            />
            <CustomButton
              classes="mt-2 d-flex"
              type="btn-primary"
              text="Send Request ('1st Notice Past Due')"
              handleClick={() => {
                handleSendInformation({ type: "1st Notice Past Due" });
              }}
            />
            <CustomButton
              classes="mt-2 d-flex"
              type="btn-primary"
              text="Send Request ('2st Notice Past Due')"
              handleClick={() => {
                handleSendInformation({ type: "2st Notice Past Due" });
              }}
            />
            <CustomButton
              classes="mt-2 d-flex"
              type="btn-primary"
              text="Send Request ('Final Notice')"
              handleClick={() => {
                handleSendInformation({ type: "Final Notice" });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default MonitoringSend;
