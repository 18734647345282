export function extractFileName(url) {
    const filename = url.split("/").pop();
    
    let extractedName;
    if (filename.includes("-")) {
      extractedName = filename.split("-").slice(1).join("-").split(".")[0];
    } else {
      extractedName = filename.split(".")[0];
    }
  
    return extractedName;
  }