import ArrowDown from "../../icons/ArrowDown";
import ArrowLeft from "../../icons/ArrowLeft";
import ArrowRigth from "../../icons/ArrowRigth";
import "./pagination.scss";

const Pagination = ({
  totalItems,
  currentPage,
  itemsPerPage,
  onPageChange,
  onItemsPerPageChange,
  isShowResult = true,
  totalCountData,
  options,
}) => {
  const pageNumbers = [];
  const itemsPerPageOptions = options || [50, 100, 150, 300];
  const maxPageDisplay = 5; // Adjust this value based on your preference

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const getDisplayedPageNumbers = () => {
    const start = Math.max(1, currentPage - Math.floor(maxPageDisplay / 2));
    const end = Math.min(totalPages, start + maxPageDisplay - 1);

    return pageNumbers.slice(start - 1, end);
  };

  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem =
    currentPage === totalPages ? totalItems : currentPage * itemsPerPage;

  return (
    <>
      {totalItems ? (
        <div
          className={
            isShowResult ? "pagination-component" : "pagination-component-not"
          }
        >
          {isShowResult && (
            <div className="show-results">
              <span className="result">Show Result</span>

              <div className="dropdown">
                <div
                  className="dropdown-box"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {itemsPerPage}
                  <i className="ms-1 d-flex">
                    <ArrowDown />
                  </i>
                </div>
                <ul className="dropdown-menu">
                  {itemsPerPageOptions?.map((option) => (
                    <li key={option}>
                      <button
                        className="dropdown-item"
                        onClick={() => onItemsPerPageChange(option)}
                      >
                        <span>{option}</span>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          <div className="items-showing">
            <span className="result">
              Showing <span className="text-bold-dark">{startItem}</span> to{" "}
              <span className="text-bold-dark">{endItem}</span> of{" "}
              <span className="text-bold-dark">{totalItems}</span> entries
            </span>
          </div>

          <ul className="pagination">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link circular-button side"
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <i className="d-flex justify-content-center mt-0">
                  <ArrowLeft />
                </i>
              </button>
            </li>

            {isShowResult && (
              <>
                {getDisplayedPageNumbers()?.map((number) => (
                  <li
                    key={number}
                    className={`page-item ${
                      number === currentPage ? "active" : ""
                    }`}
                  >
                    <button
                      onClick={() => onPageChange(number)}
                      className="page-link circular-button"
                    >
                      {number}
                    </button>
                  </li>
                ))}
              </>
            )}
            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                className="page-link circular-button side"
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <i className="d-flex justify-content-center mt-0">
                  <ArrowRigth />
                </i>
              </button>
            </li>
          </ul>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Pagination;
