const moment = require("moment");

// Function to determine which base rates apply between createdAt and dueDate
export const getApplicableBaseRates = (rates, createdAt, dueDate) => {
  const createdAtMoment = moment(createdAt);
  const dueDateMoment = moment(dueDate);
  console.log("rates", rates);
  const applicableRates = [];

  for (let i = 0; i < rates.length; i++) {
    const currentRateDate = moment(rates[i].date);
    const nextRateDate =
      i < rates.length - 1
        ? moment(rates[i + 1].date)
        : dueDateMoment.clone().add(1, "days");

    const effectiveStart = currentRateDate;
    const effectiveEnd = nextRateDate;

    if (
      createdAtMoment.isBefore(effectiveEnd) &&
      dueDateMoment.isAfter(effectiveStart)
    ) {
      const startDate = createdAtMoment.isAfter(effectiveStart)
        ? createdAtMoment
        : effectiveStart;
      const endDate = dueDateMoment.isBefore(effectiveEnd)
        ? dueDateMoment
        : effectiveEnd;

      applicableRates.push({
        base_rate: parseFloat(rates[i].base_rate), // Convert to float for calculations
        range: {
          start: startDate.format("YYYY-MM-DD"),
          end: endDate.format("YYYY-MM-DD"),
        },
      });
    }
  }

  return applicableRates;
};

// Function to calculate the final base rate
export const calculateFinalBaseRate = (applicableRates) => {
  if (applicableRates.length === 0) return 0; // No rates applicable

  const totalBaseRate = applicableRates.reduce(
    (total, rate) => total + rate.base_rate,
    0,
  );
  return totalBaseRate; // You can also compute average if needed
};

export const findMatchingRate = (rates, fundingDate) => {
  // Sort rates by date in ascending order
  const sortedRates = rates.sort((a, b) => new Date(a.date) - new Date(b.date));
  console.log("sorted", sortedRates);
  // Find the first rate whose date is on or after the funding date
  for (let rate of sortedRates) {
    console.log("rate", rate);
    if (new Date(rate.date) >= fundingDate) {
      return rate;
    }
  }

  // If no match is found, return null or any default value
  return null;
};

export const findRateForMonth = (rates, fundingDate) => {
  // console.log("rates", rates);
  // Extract the month and year from the funding date
  const fundingMonth = fundingDate.getMonth();
  const fundingYear = fundingDate.getFullYear();

  // Filter rates to find those in the same month and year as the funding date
  const filteredRates = rates.filter((rate) => {
    const rateDate = new Date(rate.date);
    return (
      rateDate.getMonth() === fundingMonth &&
      rateDate.getFullYear() === fundingYear
    );
  });

  // If there are rates in the same month, return the closest one to the funding date
  if (filteredRates.length > 0) {
    return filteredRates.reduce((closest, current) => {
      return Math.abs(new Date(current.date) - fundingDate) <
        Math.abs(new Date(closest.date) - fundingDate)
        ? current
        : closest;
    });
  }

  // If no rates match the same month, return null or an appropriate fallback
  return null;
};
