import React, { useRef, useState, useEffect } from "react";
import "./styles.scss";

const ScrollableTable = ({ children }) => {
  const containerRef = useRef(null);
  const [isScrollableLeft, setIsScrollableLeft] = useState(false);
  const [isScrollableRight, setIsScrollableRight] = useState(false);

  const checkScroll = () => {
    const container = containerRef.current;
    if (container) {
      setIsScrollableLeft(container.scrollLeft > 0);
      setIsScrollableRight(
        container.scrollWidth > container.clientWidth + container.scrollLeft
      );
    }
  };

  const scrollLeft = () => {
    containerRef.current.scrollBy({ left: -300, behavior: "smooth" });
  };

  const scrollRight = () => {
    containerRef.current.scrollBy({ left: 300, behavior: "smooth" });
  };

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      checkScroll(); // Initial check
      container.addEventListener("scroll", checkScroll);
      window.addEventListener("resize", checkScroll);

      const resizeObserver = new ResizeObserver(() => {
        checkScroll(); // Recheck whenever content width changes
      });
      resizeObserver.observe(container);

      // Optional: Observe child size changes as well
      Array.from(container.children).forEach((child) =>
        resizeObserver.observe(child)
      );

      return () => {
        container.removeEventListener("scroll", checkScroll);
        window.removeEventListener("resize", checkScroll);
        resizeObserver.disconnect();
      };
    }
  }, [children]); // Rerun effect when children change

  return (
    <div className="scroll-wrapper">
      <div
        className={`scroll-arrow left ${isScrollableLeft ? "show" : ""}`}
        onClick={scrollLeft}
      >
        ◀
      </div>
      <div
        className={`scroll-arrow right ${isScrollableRight ? "show" : ""}`}
        onClick={scrollRight}
      >
        ▶
      </div>
      <div className="scroll-container" ref={containerRef}>
        {children}
      </div>
    </div>
  );
};

export default ScrollableTable;
