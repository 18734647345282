import { Outlet } from "react-router-dom";
import style from "./style.module.scss";
import AuthImageIcon from "../../icons/authImage.icon";
import LogoIcon from "../../icons/logo.icon";

const AuthLayout = ({ children }) => {
  return (
    <div className={style.auth_screen}>
      <div className={style.auth_image}>
        <div className={style.auth_image_logo_wrap}>
          <div>{<LogoIcon />}</div>
          <div className={style.auth_image_content}>
            Welcome to SupplyFi - The operating system for companies to fuel
            their growth by unlocking working capital.
          </div>
        </div>
        <div className={style.auth_image_icon}>
          <AuthImageIcon />
        </div>
      </div>
      <div className={style.auth_content}>
        <div className={style.auth_child}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
